
import AdminAttrValues from '../../../../../public/images/np_list_875440_000000.svg';
import GenericForm from './../../../components/CrudAdmin/forms/GenericForm';
import IconButton from '@material-ui/core/IconButton';
import Moment from 'react-moment';
import NameString from './../../../components/NameString';
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import UccBrandChannelGroupDataTable from './../../../components/PrimeReactComponent/UccBrandChannelGroupDataTable';
import UccCheckBox from './../../../components/PrimeReactComponent/UccCheckbox';
import UccInputText from './../../../components/PrimeReactComponent/UccInputText';
import UccSelect from './../../../components/PrimeReactComponent/UccSelect';
import UccMultiSelect from './../../../components/PrimeReactComponent/UccMultiSelect';
import UtaTargeting from './../../../components/CrudAdmin/forms/FormElements/UtaTargeting';
import UccObjectiveModifier from './../../../components/PrimeReactComponent/UccObjectiveModifier';

import { abbrev, adGroupModifierNs, ad_id_value, between, channelSpecificOptions, childModelFilter, dateField, dateFilter, domoFields, formFields, getInstanceUrl, getModelUrl, HeadedCamelCase, inputTextFilter, multiAbbrev, multiSelectFilter, namestringFields, objectiveModifierNs, parentAbbrev, plusSigns, postgresDate, property, showFields, statusField, validDate, dropDownFilter } from '../../StoreMetadata.js'

var moment = require('moment');

const abbrev_regex = 'regex:/^\\w+$/';
const extra_regex = 'regex:/^[a-zA-Z0-9_]+$/';

export const attr_values = {
  name: 'Attributes & Values',
  abbrev: 'AttrValues',
  codename: 'attrValues',
  endpoint: 'attributes-values',
  icon: AdminAttrValues,
  adminChannel: true,
  showViewIcon: false,
  showFilter: true,
  subtitle: 'Add, delete, or edit Attributes & Values',
  models: [
    {
      allFilterData: (apiStore) => apiStore['abTypesFilterOptions'],
      codename: 'AdminABTypes',
      controller: 'ab_type',
      disableBulkEdit: true,
      endpoint: 'ab-types',
      form: () => <GenericForm datePicker={2} numButtons="approve" />,
      filterOptionsCall: 'setAbTypeFilterOptions',
      genericModelPromiseEndpoints: ['ab-types', 'statuses'],
      modelFilter: (root) => { return {}; },
      modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
        apiStore.abTypesCrud.getFilterOptions({ brand_id: brand_id }, ''),
      ]),
      name: 'AB Types',
      permArchive: 40,
      permCreateAndEdit: 40,
      permDelete: 90,
      permListAndView: 20,
      permRequest: 20,
      route: 'ab-types',
      send2domo: false,
      showFormat: 'definitionList',
      singular: 'AB Type',
      sortField: 'name',
      tabIndex: 0,
      tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
      tabDisabled: () => false,
      noDataText: (root) => '',
      requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
      crud: (rootStore) => rootStore.abTypesCrud,
      columns: [
        {
          colSize: 6,
          form: 'id',
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_value: (row) => row.id,
          grid_order: 1,
          id: 'id',
          label: 'ID',
          name: 'id',
          permListAndView: 80,
          rules: 'integer',
          type: 'hidden',
        },
        {
          colSize: 15,
          form: 'name',
          formField: (rootStore) => (<UccInputText
            core="name"
            fieldName="name"
            form={rootStore.channel.model.crudStore.form}
            label="AB Type"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
          />),
          formOrder: 20,
          grid: true,
          grid_order: 20,
          grid_link: 'draft_edit',
          grid_value: (row) => row.name,
          id: 'name',
          label: 'AB Type',
          name: 'name',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: 'required|string',
          utaType: 'name',
          show: true,
          show_value: (row) => row.name,
          show_order: 20,
        },
        {
          colSize: 15,
          form: 'abbrev',
          formField: (rootStore) => (<UccInputText
            core="abbrev"
            fieldName="abbrev"
            form={rootStore.channel.model.crudStore.form}
            label="Abbreviation"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
            disabled={rootStore.channel.model.crudStore.initialAction != 'new'}
          />),
          formOrder: 30,
          grid: true,
          grid_order: 30,
          grid_value: (row) => row.abbrev,
          id: 'abbrev',
          label: 'Abbreviation',
          name: 'abbrev',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: ['required', abbrev_regex],
          utaType: 'string',
          show: true,
          show_value: (row) => row.abbrev,
          show_order: 30,
        },
        {
          colSize: 8,
          name: 'status_id',
          type: 'hidden',
          form: 'status_id',
          filter: (dt, col) => multiSelectFilter(dt, col),
          id: 'status_id',
          grid_value: (row) => property(row.status, 'name'),
          grid: true,
          grid_order: 40,
          label: 'Status',
          options: (apiStore) => apiStore.statuses,
          rules: 'required|integer',
          show: true,
          show_value: (row) => property(row.status, 'name'),
          show_order: 40,
          utaType: 'status',
        },
      ],
    },
    {
      allFilterData: (apiStore) => apiStore['adTypesFilterOptions'],
      codename: 'AdminAdTypes',
      controller: 'ad_type',
      endpoint: 'ad-types',
      genericModelPromiseEndpoints: ['ad-types', 'channels', 'statuses'],
      form: () => <GenericForm datePicker={2} numButtons="approve" />,
      modelFilter: (root) => { return {}; },
      modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
        apiStore.adTypesCrud.getFilterOptions({ brand_id: brand_id }, ''),
      ]),
      name: 'Ad Types',
      permArchive: 40,
      permCreateAndEdit: 60,
      permDelete: 90,
      permListAndView: 20,
      permRequest: 20,
      route: 'ad-types',
      send2domo: false,
      showFormat: 'definitionList',
      singular: 'Ad Type',
      sortField: 'name',
      tabIndex: 1,
      tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
      tabDisabled: () => false,
      noDataText: (root) => '',
      requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
      crud: (rootStore) => rootStore.adTypesCrud,
      columns: [
        {
          colSize: 6,
          form: 'id',
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_value: (row) => row.id,
          grid_order: 1,
          id: 'id',
          label: 'ID',
          name: 'id',
          permListAndView: 80,
          rules: 'integer',
          show: true,
          show_value: (row) => row.id,
          show_order: 10,
          type: 'hidden',
        },
        {
          colSize: 15,
          form: 'name',
          formField: (rootStore) => (<UccInputText
            core="name"
            fieldName="name"
            form={rootStore.channel.model.crudStore.form}
            label="Ad Type"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
          />),
          formOrder: 20,
          grid: true,
          grid_order: 20,
          grid_link: 'draft_edit',
          grid_value: (row) => row.name,
          id: 'name',
          label: 'Ad Type',
          name: 'name',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: 'required|string',
          utaType: 'name',
          show: true,
          show_value: (row) => row.name,
          show_order: 20,
        },
        {
          colSize: 15,
          form: 'abbrev',
          formField: (rootStore) => (<UccInputText
            core="abbrev"
            fieldName="abbrev"
            form={rootStore.channel.model.crudStore.form}
            label="Abbreviation"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
            disabled={rootStore.channel.model.crudStore.initialAction != 'new'}
          />),
          formOrder: 30,
          grid: true,
          grid_order: 30,
          grid_value: (row) => row.abbrev,
          id: 'abbrev',
          label: 'Abbreviation',
          name: 'abbrev',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: ['required', abbrev_regex],
          utaType: 'string',
          show: true,
          show_value: (row) => row.abbrev,
          show_order: 30,
        },
        {
          belongsTo: ['channels', 'channels'],
          form: 'channel_ids',
          copyEditable: true,
          formField: (rootStore) => (<UccMultiSelect
            core="channels"
            fieldName="channel_ids"
            form={rootStore.channel.model.crudStore.form}
            label="Channels"
            menuItems={channelSpecificOptions(rootStore, 'ad_type')}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('channel_ids', event)}
          />),
          formOrder: 40,
          label: 'Channel',
          multiple: [],
          rules: 'required|array',
          utaType: 'array',
        },
        {
          colSize: 15,
          grid: true,
          grid_order: 40,
          grid_value: (row) => row.channels.map(c => c.name).join(', '),
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.channels,
          id: 'channels',
          label: 'Channels',
          multiple: [],
          name: 'channels',
          rules: 'array',
          show: true,
          show_value: (row) => row.channels.map(c => c.name).join(', '),
          show_order: 50,
          utaType: 'array',
          sortable: false
        },
        {
          colSize: 8,
          name: 'status_id',
          type: 'hidden',
          filter: (dt, col) => multiSelectFilter(dt, col),
          form: 'status_id',
          id: 'status_id',
          grid_value: (row) => property(row.status, 'name'),
          grid: true,
          grid_order: 40,
          label: 'Status',
          options: (apiStore) => apiStore.statuses,
          rules: 'required|integer',
          show_value: (row) => property(row.status, 'name'),
          show_order: 40,
          utaType: 'status',

        },
      ],
    },
    {
      allFilterData: (apiStore) => apiStore['campaignTypesFilterOptions'],
      codename: 'AdminCampaignTypes',
      controller: 'campaign_type',
      endpoint: 'campaign-types',
      genericModelPromiseEndpoints: ['campaign-types', 'channels', 'statuses'],
      form: () => <GenericForm datePicker={2} numButtons="approve" />,
      modelFilter: (root) => { return {}; },
      modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
        apiStore.campaignTypesCrud.getFilterOptions({ brand_id: brand_id }, ''),
      ]),
      name: 'Campaign Types',
      permArchive: 60,
      permCreateAndEdit: 60,
      permDelete: 90,
      permListAndView: 20,
      permRequest: 20,
      route: 'campaign-types',
      send2domo: false,
      singular: 'Campaign Type',
      showFormat: 'definitionList',
      sortField: 'name',
      tabIndex: 2,
      tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
      tabDisabled: () => false,
      noDataText: (root) => '',
      requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
      crud: (rootStore) => rootStore.campaignTypesCrud,
      columns: [
        {
          colSize: 6,
          form: 'id',
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_value: (row) => row.id,
          grid_order: 1,
          id: 'id',
          label: 'ID',
          name: 'id',
          permListAndView: 80,
          rules: 'integer',
          type: 'hidden',
        },
        {
          colSize: 15,
          form: 'name',
          formField: (rootStore) => (<UccInputText
            core="name"
            fieldName="name"
            form={rootStore.channel.model.crudStore.form}
            label="Campaign Type"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
          />),
          formOrder: 20,
          grid: true,
          grid_order: 20,
          grid_link: 'draft_edit',
          grid_value: (row) => row.name,
          id: 'name',
          label: 'Campaign Type',
          name: 'name',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: 'required|string',
          utaType: 'name',
          show: true,
          show_value: (row) => row.name,
          show_order: 20,
        },
        {
          colSize: 15,
          form: 'abbrev',
          formField: (rootStore) => (<UccInputText
            core="abbrev"
            fieldName="abbrev"
            form={rootStore.channel.model.crudStore.form}
            label="Abbreviation"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
            disabled={rootStore.channel.model.crudStore.initialAction != 'new'}
          />),
          formOrder: 30,
          grid: true,
          grid_order: 30,
          grid_value: (row) => row.abbrev,
          id: 'abbrev',
          label: 'Abbreviation',
          name: 'abbrev',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: ['required', abbrev_regex],
          utaType: 'string',
          show: true,
          show_value: (row) => row.abbrev,
          show_order: 30,
        },
        {
          belongsTo: ['channels', 'channels'],
          copyEditable: true,
          form: 'channel_ids',
          formField: (rootStore) => (<UccMultiSelect
            core="channels"
            fieldName="channel_ids"
            form={rootStore.channel.model.crudStore.form}
            label="Channels"
            menuItems={channelSpecificOptions(rootStore, 'campaign_type')}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('channel_ids', event)}
          />),
          formOrder: 40,
          label: 'Channel',
          multiple: [],
          rules: 'required|array',
          utaType: 'array',
        },
        {
          colSize: 15,
          grid: true,
          grid_order: 40,
          grid_value: (row) => row.channels.map(c => c.name).join(', '),
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.channels,
          id: 'channels',
          label: 'Channels',
          multiple: [],
          name: 'channels',
          rules: 'array',
          show: true,
          show_value: (row) => row.channels.map(c => c.name).join(', '),
          show_order: 50,
          utaType: 'array',
          sortable: false
        },
        {
          colSize: 8,
          name: 'status_id',
          type: 'hidden',
          filter: (dt, col) => multiSelectFilter(dt, col),
          form: 'status_id',
          id: 'status_id',
          grid_value: (row) => property(row.status, 'name'),
          grid: true,
          grid_order: 40,
          label: 'Status',
          options: (apiStore) => apiStore.statuses,
          rules: 'required|integer',
          show_value: (row) => property(row.status, 'name'),
          show_order: 40,
          utaType: 'status',
        },
      ],
    },

    {
      allFilterData: (apiStore) => apiStore['channelFilterOptions'],
      codename: 'AdminChannels',
      controller: 'channel',
      disableBulkEdit: true,
      endpoint: 'channels',
      form: () => <AttrValuesForm />,
      // genericModelPromiseEndpoints: ['statuses'],
      modelFilter: (root) => { return {}; },
      name: 'Channels',
      permArchive: 70,
      permCreateAndEdit: 70,
      permDelete: 90,
      permListAndView: 70,
      permRequest: false,
      route: 'channels',
      send2domo: false,
      showFormat: 'definitionList',
      singular: 'Channel Type',
      sortField: 'name',
      tabIndex: 3,
      tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
      tabDisabled: () => false,
      noDataText: (root) => '',
      requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
      crud: (rootStore) => rootStore.channelsCrud,
      columns: [
        {
          colSize: 6,
          form: 'id',
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_value: (row) => row.id,
          grid_order: 1,
          id: 'id',
          label: 'ID',
          name: 'id',
          permListAndView: 80,
          rules: 'integer',
          type: 'hidden',
        },
        {
          colSize: 15,
          form: 'name',
          formField: (rootStore) => (<UccInputText
            core="name"
            fieldName="name"
            form={rootStore.channel.model.crudStore.form}
            label="Channel"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
          />),
          formOrder: 20,
          grid: true,
          grid_order: 20,
          grid_link: 'draft_edit',
          grid_value: (row) => row.name,
          id: 'name',
          label: 'Channel',
          name: 'name',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: 'required|string',
          utaType: 'name',
          show: true,
          show_value: (row) => row.name,
          show_order: 20,
        },
        {
          colSize: 15,
          form: 'codename',
          formField: (rootStore) => (<UccInputText
            core="codename"
            fieldName="codename"
            form={rootStore.channel.model.crudStore.form}
            label="codename"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('codename', event)}
          />),
          formOrder: 30,
          grid: true,
          grid_order: 30,
          grid_value: (row) => row.codename,
          id: 'codename',
          label: 'codename',
          name: 'codename',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: ['required'],
          utaType: 'string',
          show: true,
          show_value: (row) => row.codename,
          show_order: 30,
        },
        {
          colSize: 8,
          name: 'status_id',
          type: 'hidden',
          filter: (dt, col) => multiSelectFilter(dt, col),
          form: 'status_id',
          id: 'status_id',
          grid_value: (row) => property(row.status, 'name'),
          grid: true,
          grid_order: 40,
          label: 'Status',
          options: (apiStore) => apiStore.statuses,
          rules: 'required|integer',
          show_value: (row) => property(row.status, 'name'),
          show_order: 40,
          utaType: 'status',
        },
      ],
    },

    {
      allFilterData: (apiStore) => apiStore['creativeTypesFilterOptions'],
      codename: 'AdminCreativeTypes',
      controller: 'creative_type',
      disableBulkEdit: true,
      endpoint: 'creative-types',
      form: () => <GenericForm datePicker={2} numButtons="approve" />,
      genericModelPromiseEndpoints: ['creative-types', 'statuses'],
      modelFilter: (root) => { return {}; },
      modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
        apiStore.creativeTypesCrud.getFilterOptions({ brand_id: brand_id }, ''),
      ]),
      name: 'Creative Types',
      permArchive: 40,
      permCreateAndEdit: 40,
      permDelete: 90,
      permListAndView: 20,
      permRequest: 20,
      route: 'creative-types',
      send2domo: false,
      showFormat: 'definitionList',
      singular: 'Creative Type',
      sortField: 'name',
      tabIndex: 3,
      tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
      tabDisabled: () => false,
      noDataText: (root) => '',
      requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
      crud: (rootStore) => rootStore.creativeTypesCrud,
      columns: [
        {
          colSize: 6,
          form: 'id',
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_value: (row) => row.id,
          grid_order: 1,
          id: 'id',
          label: 'ID',
          name: 'id',
          permListAndView: 80,
          rules: 'integer',
          type: 'hidden',
        },
        {
          colSize: 15,
          form: 'name',
          formField: (rootStore) => (<UccInputText
            core="name"
            fieldName="name"
            form={rootStore.channel.model.crudStore.form}
            label="Creative Type"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
          />),
          formOrder: 20,
          grid: true,
          grid_order: 20,
          grid_link: 'draft_edit',
          grid_value: (row) => row.name,
          id: 'name',
          label: 'Creative Type',
          name: 'name',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: 'required|string',
          utaType: 'name',
          show: true,
          show_value: (row) => row.name,
          show_order: 20,
        },
        {
          colSize: 15,
          form: 'abbrev',
          formField: (rootStore) => (<UccInputText
            core="abbrev"
            fieldName="abbrev"
            form={rootStore.channel.model.crudStore.form}
            label="Abbreviation"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
            disabled={rootStore.channel.model.crudStore.initialAction != 'new'}

          />),
          formOrder: 30,
          grid: true,
          grid_order: 30,
          grid_value: (row) => row.abbrev,
          id: 'abbrev',
          label: 'Abbreviation',
          name: 'abbrev',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: ['required', abbrev_regex],
          utaType: 'string',
          show: true,
          show_value: (row) => row.abbrev,
          show_order: 30,
        },
        {
          colSize: 8,
          name: 'status_id',
          type: 'hidden',
          filter: (dt, col) => multiSelectFilter(dt, col),
          form: 'status_id',
          id: 'status_id',
          grid_value: (row) => property(row.status, 'name'),
          grid: true,
          grid_order: 40,
          label: 'Status',
          options: (apiStore) => apiStore.statuses,
          rules: 'required|integer',
          show_value: (row) => property(row.status, 'name'),
          show_order: 40,
          utaType: 'status',
        },
      ],
    },
    {
      allFilterData: (apiStore) => apiStore['devicesFilterOptions'],
      codename: 'AdminDevices',
      controller: 'device',
      disableBulkEdit: true,
      endpoint: 'devices',
      form: () => <GenericForm datePicker={2} numButtons="approve" />,
      genericModelPromiseEndpoints: ['devices', 'statuses'],
      modelFilter: (root) => { return {}; },
      modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
        apiStore.devicesCrud.getFilterOptions({ brand_id: brand_id }, ''),
      ]),
      name: 'Devices',
      permArchive: 40,
      permCreateAndEdit: 40,
      permDelete: 90,
      permListAndView: 20,
      permRequest: 20,
      route: 'devices',
      send2domo: false,
      showFormat: 'definitionList',
      singular: 'Device',
      sortField: 'name',
      tabIndex: 4,
      tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
      tabDisabled: () => false,
      noDataText: (root) => '',
      requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
      crud: (rootStore) => rootStore.devicesCrud,
      columns: [
        {
          colSize: 6,
          form: 'id',
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_value: (row) => row.id,
          grid_order: 1,
          id: 'id',
          label: 'ID',
          name: 'id',
          permListAndView: 80,
          rules: 'integer',
          type: 'hidden',
        },
        {
          colSize: 15,
          form: 'name',
          formField: (rootStore) => (<UccInputText
            core="name"
            fieldName="name"
            form={rootStore.channel.model.crudStore.form}
            label="Device"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
          />),
          formOrder: 20,
          grid: true,
          grid_order: 20,
          grid_link: 'draft_edit',
          grid_value: (row) => row.name,
          id: 'name',
          label: 'Device',
          name: 'name',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: 'required|string',
          utaType: 'name',
          show: true,
          show_value: (row) => row.name,
          show_order: 20,
        },
        {
          colSize: 15,
          form: 'abbrev',
          formField: (rootStore) => (<UccInputText
            core="abbrev"
            fieldName="abbrev"
            form={rootStore.channel.model.crudStore.form}
            label="Abbreviation"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
            disabled={rootStore.channel.model.crudStore.initialAction != 'new'}

          />),
          formOrder: 30,
          grid: true,
          grid_order: 30,
          grid_value: (row) => row.abbrev,
          id: 'abbrev',
          label: 'Abbreviation',
          name: 'abbrev',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: ['required', abbrev_regex],
          utaType: 'string',
          show: true,
          show_value: (row) => row.abbrev,
          show_order: 30,
        },
        {
          colSize: 8,
          name: 'status_id',
          type: 'hidden',
          filter: (dt, col) => multiSelectFilter(dt, col),
          form: 'status_id',
          id: 'status_id',
          grid_value: (row) => property(row.status, 'name'),
          grid: true,
          grid_order: 40,
          label: 'Status',
          options: (apiStore) => apiStore.statuses,
          rules: 'required|integer',
          show_value: (row) => property(row.status, 'name'),
          show_order: 40,
          utaType: 'status',
        },
      ],
    },
    {
      allFilterData: (apiStore) => apiStore['displayTypesFilterOptions'],
      codename: 'AdminDisplayTypes',
      controller: 'display_type',
      endpoint: 'display-types',
      genericModelPromiseEndpoints: ['display-types', 'channels', 'statuses'],
      form: () => <GenericForm datePicker={2} numButtons="approve" />,
      modelFilter: (root) => { return {}; },
      modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
        apiStore.displayTypesCrud.getFilterOptions({ brand_id: brand_id }, ''),
      ]),
      name: 'Display Types',
      permArchive: 40,
      permCreateAndEdit: 40,
      permDelete: 90,
      permListAndView: 20,
      permRequest: 20,
      route: 'display-types',
      send2domo: false,
      showFormat: 'definitionList',
      singular: 'Display type',
      sortField: 'name',
      tabIndex: 15,
      tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
      tabDisabled: () => false,
      noDataText: (root) => '',
      requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
      crud: (rootStore) => rootStore.displayTypesCrud,
      columns: [
        {
          colSize: 6,
          form: 'id',
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_value: (row) => row.id,
          grid_order: 1,
          id: 'id',
          label: 'ID',
          name: 'id',
          permListAndView: 80,
          rules: 'integer',
          type: 'hidden',
        },
        {
          colSize: 15,
          form: 'name',
          formField: (rootStore) => (<UccInputText
            core="name"
            fieldName="name"
            form={rootStore.channel.model.crudStore.form}
            label="Display Type"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
          />),
          formOrder: 20,
          grid: true,
          grid_order: 20,
          grid_link: 'draft_edit',
          grid_value: (row) => row.name,
          id: 'name',
          label: 'Display Type',
          name: 'name',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: 'required|string',
          utaType: 'name',
          show: true,
          show_value: (row) => row.name,
          show_order: 20,
        },
        {
          form: 'abbrev',
          formField: (rootStore) => (<UccInputText
            core="abbrev"
            fieldName="abbrev"
            form={rootStore.channel.model.crudStore.form}
            label="Abbreviation"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
            disabled={rootStore.channel.model.crudStore.initialAction != 'new'}

          />),
          formOrder: 30,
          grid: true,
          grid_order: 30,
          grid_value: (row) => row.abbrev,
          id: 'abbrev',
          label: 'Abbreviation',
          name: 'abbrev',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: ['required', abbrev_regex],
          utaType: 'string',
          show: true,
          show_value: (row) => row.abbrev,
          show_order: 30,
        },
        {
          copyEditable: true,
          belongsTo: ['channels', 'channels'],
          form: 'channel_ids',
          formField: (rootStore) => (<UccMultiSelect
            core="channels"
            fieldName="channel_ids"
            form={rootStore.channel.model.crudStore.form}
            label="Channels"
            menuItems={channelSpecificOptions(rootStore, 'display_types')}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('channel_ids', event)}
          />),
          formOrder: 40,
          label: 'Channel',
          multiple: [],
          rules: 'required|array',
          utaType: 'array',
        },
        {
          colSize: 15,
          grid: true,
          grid_order: 40,
          grid_value: (row) => row.channels.map(c => c.name).join(', '),
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.channels,
          id: 'channels',
          label: 'Channels',
          multiple: [],
          name: 'channels',
          rules: 'array',
          show: true,
          show_value: (row) => row.channels.map(c => c.name).join(', '),
          show_order: 50,
          utaType: 'array',
          sortable: false
        },
        {
          colSize: 8,
          name: 'status_id',
          type: 'hidden',
          filter: (dt, col) => multiSelectFilter(dt, col),
          form: 'status_id',
          id: 'status_id',
          grid_value: (row) => property(row.status, 'name'),
          grid: true,
          grid_order: 40,
          label: 'Status',
          options: (apiStore) => apiStore.statuses,
          rules: 'required|integer',
          show_value: (row) => property(row.status, 'name'),
          show_order: 40,
          utaType: 'status',
        },
      ],
    },
    {
      allFilterData: (apiStore) => apiStore['emailServicePlatformsFilterOptions'],
      codename: 'AdminEmailServicePlatforms',
      controller: 'email_service_platform',
      disableBulkEdit: true,
      endpoint: 'email-service-platforms',
      form: () => <GenericForm datePicker={2} numButtons="approve" />,
      genericModelPromiseEndpoints: ['email-service-platforms', 'statuses'],
      modelFilter: (root) => { return {}; },
      modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
        apiStore.emailServicePlatformsCrud.getFilterOptions({ brand_id: brand_id }, ''),
      ]),
      name: 'Email Service Platforms',
      permArchive: 40,
      permCreateAndEdit: 40,
      permDelete: 90,
      permListAndView: 20,
      permRequest: 20,
      route: 'email-service-platforms',
      send2domo: false,
      showFormat: 'definitionList',
      singular: 'Email Service Platform',
      sortField: 'name',
      tabIndex: 5,
      tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
      tabDisabled: () => false,
      noDataText: (root) => '',
      requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
      crud: (rootStore) => rootStore.emailServicePlatformsCrud,
      columns: [
        {
          colSize: 6,
          form: 'id',
          label: 'id',
          rules: 'integer',
          type: 'hidden',
        },
        {
          colSize: 15,
          form: 'name',
          formField: (rootStore) => (<UccInputText
            core="name"
            fieldName="name"
            form={rootStore.channel.model.crudStore.form}
            label="Email Service Platform"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
          />),
          formOrder: 20,
          grid: true,
          grid_order: 20,
          grid_link: 'draft_edit',
          grid_value: (row) => row.name,
          id: 'name',
          label: 'Email Service Platform',
          name: 'name',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: 'required|string',
          utaType: 'name',
          show: true,
          show_value: (row) => row.name,
          show_order: 20,
        },
        {
          colSize: 15,
          form: 'abbrev',
          formField: (rootStore) => (<UccInputText
            core="abbrev"
            fieldName="abbrev"
            form={rootStore.channel.model.crudStore.form}
            label="Abbreviation"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
            disabled={rootStore.channel.model.crudStore.initialAction != 'new'}
          />),
          formOrder: 30,
          grid: true,
          grid_order: 30,
          grid_value: (row) => row.abbrev,
          id: 'abbrev',
          label: 'Abbreviation',
          name: 'abbrev',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: ['required', abbrev_regex],
          utaType: 'string',
          show: true,
          show_value: (row) => row.abbrev,
          show_order: 30,
        },
        {
          colSize: 8,
          name: 'status_id',
          type: 'hidden',
          filter: (dt, col) => multiSelectFilter(dt, col),
          form: 'status_id',
          id: 'status_id',
          grid_value: (row) => property(row.status, 'name'),
          grid: true,
          grid_order: 40,
          label: 'Status',
          options: (apiStore) => apiStore.statuses,
          rules: 'required|integer',
          show_value: (row) => property(row.status, 'name'),
          show_order: 40,
          utaType: 'status',
        },
      ],
    },
    {
      allFilterData: (apiStore) => apiStore['extraFieldsFilterOptions'],
      codename: 'AdminExtraFields',
      controller: 'extra_field',
      endpoint: 'extra-fields',
      genericModelPromiseEndpoints: ['extra-fields', 'models', 'objective-modifiers', 'statuses'],
      hasStatus: false,
      form: () => <GenericForm datePicker={2} numButtons="approve" />,
      modelFilter: (root) => { return {}; },
      modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
        apiStore.extraFieldsCrud.getFilterOptions({ brand_id: brand_id }, ''),
      ]),
      name: 'Paid Search Extra Fields',
      route: 'extra-fields',
      permArchive: 60,
      permCreateAndEdit: 60,
      permDelete: 90,
      permListAndView: 20,
      permRequest: 20,
      send2domo: false,
      showFormat: 'definitionList',
      singular: 'ExtraField',
      sortField: 'name',
      tabIndex: 6,
      tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
      tabDisabled: () => false,
      noDataText: (root) => '',
      requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
      crud: (rootStore) => rootStore.extraFieldsCrud,
      columns: [
        {
          colSize: 6,
          form: 'id',
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_value: (row) => row.id,
          grid_order: 1,
          id: 'id',
          label: 'ID',
          name: 'id',
          permListAndView: 80,
          rules: 'integer',
          type: 'hidden',
        },
        {
          colSize: 15,
          form: 'name',
          formField: (rootStore) => (<UccInputText
            core="name"
            fieldName="name"
            form={rootStore.channel.model.crudStore.form}
            label="Paid Search Extra Field"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
          />),
          formOrder: 20,
          grid: true,
          grid_link: 'draft_edit',
          grid_order: 20,
          grid_value: (row) => row.name,
          id: 'name',
          label: 'Paid Search Extra Field',
          name: 'name',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: ['required', extra_regex],
          utaType: 'name',
          show: true,
          show_value: (row) => row.name,
          show_order: 20,
        },
        {
          colSize: 15,
          form: 'position',
          formField: (rootStore) => (<UccInputText
            core="position"
            fieldName="position"
            form={rootStore.channel.model.crudStore.form}
            label="Position"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('position', event)}
          />),
          formOrder: 30,
          grid: true,
          grid_order: 30,
          grid_value: (row) => row.position,
          id: 'position',
          label: 'Position',
          name: 'position',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: 'required|integer',
          utaType: 'integer',
          show: true,
          show_value: (row) => row.position,
          show_order: 30,
        },
        {
          belongsTo: ['model', 'models'],
          copyEditable: true,
          form: 'model_id',
          formField: (rootStore) => (<UccSelect
            core="model"
            fieldName="model_id"
            form={rootStore.channel.model.crudStore.form}
            label="Model"
            menuItems={rootStore.apiStore.models.filter(m => m.name.includes('Paid Search')).map(p => ({ label: p.name, value: p.id.toString(), }))}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('model_id', event)}
          />),
          formOrder: 40,
          label: 'Model',
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          colSize: 15,
          grid: true,
          grid_order: 40,
          grid_value: (row) => property(row.model, 'name'),
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.models,
          id: 'model',
          label: 'Model',
          name: 'model',
          show: true,
          show_value: (row) => property(row.model, "name"),
          show_order: 50,
          // utaType: 'array',
          sortable: false
        },
        {
          belongsTo: ['objective-modifiers', 'objectiveModifiers'],
          copyEditable: false, // not sure why this doesnt work as true. tbd.
          form: 'objective_modifier_ids',
          formField: (rootStore) => (<UccMultiSelect
            core="objective_modifiers"
            fieldName="objective_modifier_ids"
            form={rootStore.channel.model.crudStore.form}
            label="Objective Modifiers"
            menuItems={rootStore.apiStore.objectiveModifiers.map((p) => ({ label: p.name, value: p.id, }))}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('objective_modifier_ids', event)}
          />),
          formOrder: 40,
          label: 'Objective Modifiers',
          multiple: [],
          rules: 'required|array',
          utaType: 'array',
        },
        {
          colSize: 15,
          grid: true,
          grid_order: 40,
          grid_value: (row) => row.objective_modifiers.map(c => c.name).join(', '),
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.objective_modifiers,
          id: 'objective_modifiers',
          label: 'Objective Modifiers',
          multiple: [],
          name: 'objective_modifiers',
          rules: 'array',
          show: true,
          show_value: (row) => row.objective_modifiers.map(c => c.name).join(', '),
          show_order: 50,
          utaType: 'array',
          sortable: false
        },
        {
          colSize: 15,
          form: 'extra_field_type',
          formField: (rootStore) => (<UccSelect
            core="extra_field_type"
            fieldName="extra_field_type"
            form={rootStore.channel.model.crudStore.form}
            label="Extra Field Type"
            // menuItems={[{ label: 'Number', value: 'number' }, { label: 'Text', value: 'text' }, { label: 'Select', value: 'select' }]}
            menuItems={[{ label: 'Text', value: 'text' }]}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('extra_field_type', event)}
          />),
          formOrder: 60,
          grid: true,
          grid_order: 60,
          grid_value: (row) => row.extra_field_type,
          id: 'extra_field_type',
          label: 'Extra Field Type',
          name: 'Extra Field Type',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: 'string',
          utaType: 'string',
          show: true,
          show_value: (row) => row.extra_field_type,
          show_order: 60,
        },
        // {
        //   colSize: 15,
        //   form: 'campaign_required',
        //   formField: (rootStore) => (<UccCheckBox
        //     core="campaign_required"
        //     fieldName="campaign_required"
        //     form={rootStore.channel.model.crudStore.form}
        //     label="Required on Campaign"
        //     onChange={(event) => rootStore.channel.model.crudStore.storeData('campaign_required', event)}
        //   />),
        //   formOrder: 50,
        //   grid: true,
        //   grid_order: 50,
        //   grid_value: (row) => (row.campaign_required ? 'Yes' : 'No'),
        //   id: 'campaign_required',
        //   label: 'Required on Campaign',
        //   name: 'campaign_required',
        //   type: 'checkbox',
        //   utaType: 'boolean',
        //   rules: 'boolean',
        //   show: true,
        //   show_value: (row) => (row.campaign_required ? 'Yes' : 'No'),
        //   show_order: 50
        // },

        // {
        //   colSize: 15,
        //   form: 'ad_group_required',
        //   formField: (rootStore) => (<UccCheckBox
        //     core="ad_group_required"
        //     fieldName="ad_group_required"
        //     form={rootStore.channel.model.crudStore.form}
        //     label="Required on ad_group"
        //     onChange={(event) => rootStore.channel.model.crudStore.storeData('ad_group_required', event)}
        //   />),
        //   formOrder: 50,
        //   grid: true,
        //   grid_order: 50,
        //   grid_value: (row) => (row.ad_group_required ? 'Yes' : 'No'),
        //   id: 'ad_group_required',
        //   label: 'Required on Ad Group',
        //   name: 'ad_group_required',
        //   type: 'checkbox',
        //   utaType: 'boolean',
        //   rules: 'boolean',
        //   show: true,
        //   show_value: (row) => (row.ad_group_required ? 'Yes' : 'No'),
        //   show_order: 50
        // },

      ],
    },

    {
      allFilterData: (apiStore) => apiStore['gendersFilterOptions'],
      codename: 'AdminGenders',
      controller: 'gender',
      endpoint: 'genders',
      genericModelPromiseEndpoints: ['genders', 'channels', 'statuses'],
      form: () => <GenericForm datePicker={2} numButtons="approve" />,
      modelFilter: (root) => { return {}; },
      modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
        apiStore.gendersCrud.getFilterOptions({ brand_id: brand_id }, ''),
      ]),
      name: 'Genders',
      permArchive: 40,
      permCreateAndEdit: 40,
      permDelete: 90,
      permListAndView: 20,
      permRequest: 20,
      route: 'genders',
      send2domo: false,
      showFormat: 'definitionList',
      singular: 'Gender',
      sortField: 'name',
      tabIndex: 6,
      tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
      tabDisabled: () => false,
      noDataText: (root) => '',
      requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
      crud: (rootStore) => rootStore.gendersCrud,
      columns: [
        {
          colSize: 6,
          form: 'id',
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_value: (row) => row.id,
          grid_order: 1,
          id: 'id',
          label: 'ID',
          name: 'id',
          permListAndView: 80,
          rules: 'integer',
          type: 'hidden',
        },
        {
          colSize: 15,
          form: 'name',
          formField: (rootStore) => (<UccInputText
            core="name"
            fieldName="name"
            form={rootStore.channel.model.crudStore.form}
            label="Gender"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
          />),
          formOrder: 20,
          grid: true,
          grid_order: 20,
          grid_link: 'draft_edit',
          grid_value: (row) => row.name,
          id: 'name',
          label: 'Gender',
          name: 'name',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: 'required|string',
          utaType: 'name',
          show: true,
          show_value: (row) => row.name,
          show_order: 20,
        },
        {
          colSize: 15,
          form: 'abbrev',
          formField: (rootStore) => (<UccInputText
            core="abbrev"
            fieldName="abbrev"
            form={rootStore.channel.model.crudStore.form}
            label="Abbreviation"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
            disabled={rootStore.channel.model.crudStore.initialAction != 'new'}

          />),
          formOrder: 30,
          grid: true,
          grid_order: 30,
          grid_value: (row) => row.abbrev,
          id: 'abbrev',
          label: 'Abbreviation',
          name: 'abbrev',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: ['required', abbrev_regex],
          utaType: 'string',
          show: true,
          show_value: (row) => row.abbrev,
          show_order: 30,
        },
        {
          copyEditable: true,
          belongsTo: ['channels', 'channels'],
          form: 'channel_ids',
          formField: (rootStore) => (<UccMultiSelect
            core="channels"
            fieldName="channel_ids"
            form={rootStore.channel.model.crudStore.form}
            label="Channels"
            menuItems={channelSpecificOptions(rootStore, 'gender')}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('channel_ids', event)}
          />),
          formOrder: 40,
          label: 'Channel',
          multiple: [],
          rules: 'required|array',
          utaType: 'array',
        },
        {
          colSize: 15,
          grid: true,
          grid_order: 40,
          grid_value: (row) => row.channels.map(c => c.name).join(', '),
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.channels,
          id: 'channels',
          label: 'Channels',
          multiple: [],
          name: 'channels',
          rules: 'array',
          show: true,
          show_value: (row) => row.channels.map(c => c.name).join(', '),
          show_order: 50,
          utaType: 'array',
          sortable: false
        },
        {
          colSize: 8,
          name: 'status_id',
          type: 'hidden',
          filter: (dt, col) => multiSelectFilter(dt, col),
          form: 'status_id',
          id: 'status_id',
          grid_value: (row) => property(row.status, 'name'),
          grid: true,
          grid_order: 40,
          label: 'Status',
          options: (apiStore) => apiStore.statuses,
          rules: 'required|integer',
          show_value: (row) => property(row.status, 'name'),
          show_order: 40,
          utaType: 'status',
        },
      ],
    },
    {
      allFilterData: (apiStore) => apiStore['geosFilterOptions'],
      codename: 'AdminGeos',
      controller: 'geo',
      endpoint: 'geos',
      form: () => <GenericForm datePicker={2} numButtons="approve" />,
      genericModelPromiseEndpoints: ['geos', 'statuses'],
      modelFilter: (root) => { return {}; },
      modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
        apiStore.geosCrud.getFilterOptions({ brand_id: brand_id }, ''),
      ]),
      name: 'Geos',
      permArchive: 40,
      permCreateAndEdit: 40,
      permDelete: 90,
      permListAndView: 20,
      permRequest: 20,
      route: 'geos',
      send2domo: false,
      showFormat: 'definitionList',
      singular: 'Geo',
      sortField: 'position',
      tabIndex: 7,
      tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
      tabDisabled: () => false,
      noDataText: (root) => '',
      requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
      crud: (rootStore) => rootStore.geosCrud,
      columns: [
        {
          colSize: 6,
          form: 'id',
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_value: (row) => row.id,
          grid_order: 1,
          id: 'id',
          label: 'ID',
          name: 'id',
          permListAndView: 80,
          rules: 'integer',
          type: 'hidden',
        },
        {
          colSize: 15,
          form: 'name',
          formField: (rootStore) => (<UccInputText
            core="name"
            fieldName="name"
            form={rootStore.channel.model.crudStore.form}
            label="Geo"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
          />),
          formOrder: 20,
          grid: true,
          grid_order: 20,
          grid_link: 'draft_edit',
          grid_value: (row) => row.name,
          id: 'name',
          label: 'Geo',
          name: 'name',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: 'required|string',
          utaType: 'name',
          show: true,
          show_value: (row) => row.name,
          show_order: 20,
        },
        {
          colSize: 15,
          form: 'abbrev',
          formField: (rootStore) => (<UccInputText
            core="abbrev"
            fieldName="abbrev"
            form={rootStore.channel.model.crudStore.form}
            label="Abbreviation"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
            disabled={rootStore.channel.model.crudStore.initialAction != 'new'}

          />),
          formOrder: 30,
          grid: true,
          grid_order: 30,
          grid_value: (row) => row.abbrev,
          id: 'abbrev',
          label: 'Abbreviation',
          name: 'abbrev',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: ['required', abbrev_regex],
          utaType: 'string',
          show: true,
          show_value: (row) => row.abbrev,
          show_order: 30,
        },
        {
          colSize: 15,
          form: 'position',
          formField: (rootStore) => (<UccInputText
            core="position"
            fieldName="position"
            form={rootStore.channel.model.crudStore.form}
            label="Position"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('position', event)}
          />),
          formOrder: 40,
          grid: true,
          grid_order: 40,
          grid_value: (row) => row.position,
          id: 'position',
          label: 'Position',
          name: 'position',
          rules: 'required|integer',
          utaType: 'integer',
          show: true,
          show_value: (row) => row.position,
          show_order: 40,
        },
        {
          colSize: 8,
          name: 'status_id',
          type: 'hidden',
          filter: (dt, col) => multiSelectFilter(dt, col),
          form: 'status_id',
          id: 'status_id',
          grid_value: (row) => property(row.status, 'name'),
          grid: true,
          grid_order: 40,
          label: 'Status',
          options: (apiStore) => apiStore.statuses,
          rules: 'required|integer',
          show_value: (row) => property(row.status, 'name'),
          show_order: 40,
          utaType: 'status',
        },
      ],
    },
    {
      allFilterData: (apiStore) => apiStore['goalsFilterOptions'],
      codename: 'AdminGoals',
      controller: 'goal',
      disableBulkEdit: true,
      endpoint: 'goals',
      form: () => <GenericForm datePicker={2} numButtons="approve" />,
      genericModelPromiseEndpoints: ['goals', 'statuses'],
      modelFilter: (root) => { return {}; },
      modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
        apiStore.goalsCrud.getFilterOptions({ brand_id: brand_id }, ''),
      ]),
      name: 'Goals',
      permArchive: 40,
      permCreateAndEdit: 40,
      permDelete: 90,
      permListAndView: 20,
      permRequest: 20,
      route: 'goals',
      send2domo: false,
      showFormat: 'definitionList',
      singular: 'Goal',
      sortField: 'name',
      tabIndex: 8,
      tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
      tabDisabled: () => false,
      noDataText: (root) => '',
      requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
      crud: (rootStore) => rootStore.goalsCrud,
      columns: [
        {
          colSize: 6,
          form: 'id',
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_value: (row) => row.id,
          grid_order: 1,
          id: 'id',
          label: 'ID',
          name: 'id',
          permListAndView: 80,
          rules: 'integer',
          type: 'hidden',
        },
        {
          colSize: 15,
          form: 'name',
          formField: (rootStore) => (<UccInputText
            core="name"
            fieldName="name"
            form={rootStore.channel.model.crudStore.form}
            label="Goal"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
          />),
          formOrder: 20,
          grid: true,
          grid_order: 20,
          grid_link: 'draft_edit',
          grid_value: (row) => row.name,
          id: 'name',
          label: 'Goal',
          name: 'name',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: 'required|string',
          utaType: 'name',
          show: true,
          show_value: (row) => row.name,
          show_order: 20,
        },
        {
          colSize: 15,
          form: 'abbrev',
          formField: (rootStore) => (<UccInputText
            core="abbrev"
            fieldName="abbrev"
            form={rootStore.channel.model.crudStore.form}
            label="Abbreviation"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
            disabled={rootStore.channel.model.crudStore.initialAction != 'new'}

          />),
          formOrder: 30,
          grid: true,
          grid_order: 30,
          grid_value: (row) => row.abbrev,
          id: 'abbrev',
          label: 'Abbreviation',
          name: 'abbrev',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: ['required', abbrev_regex],
          utaType: 'string',
          show: true,
          show_value: (row) => row.abbrev,
          show_order: 30,
        },
        {
          colSize: 8,
          name: 'status_id',
          type: 'hidden',
          filter: (dt, col) => multiSelectFilter(dt, col),
          form: 'status_id',
          id: 'status_id',
          grid_value: (row) => property(row.status, 'name'),
          grid: true,
          grid_order: 40,
          label: 'Status',
          options: (apiStore) => apiStore.statuses,
          rules: 'required|integer',
          show_value: (row) => property(row.status, 'name'),
          show_order: 40,
          utaType: 'status',
        },
      ],
    },
    {
      allFilterData: (apiStore) => apiStore['languagesFilterOptions'],
      codename: 'AdminLanguages',
      controller: 'language',
      disableBulkEdit: true,
      endpoint: 'languages',
      form: () => <GenericForm datePicker={2} numButtons="approve" />,
      genericModelPromiseEndpoints: ['languages', 'statuses'],
      modelFilter: (root) => { return {}; },
      modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
        apiStore.languagesCrud.getFilterOptions({ brand_id: brand_id }, ''),
      ]),
      name: 'Languages',
      permArchive: 40,
      permCreateAndEdit: 40,
      permDelete: 90,
      permListAndView: 20,
      permRequest: 20,
      route: 'languages',
      send2domo: false,
      showFormat: 'definitionList',
      singular: 'Language',
      sortField: 'position',
      tabIndex: 9,
      tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
      tabDisabled: () => false,
      noDataText: (root) => '',
      requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
      crud: (rootStore) => rootStore.languagesCrud,
      columns: [
        {
          colSize: 6,
          form: 'id',
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_value: (row) => row.id,
          grid_order: 1,
          id: 'id',
          label: 'ID',
          name: 'id',
          permListAndView: 80,
          rules: 'integer',
          type: 'hidden',
        },
        {
          colSize: 15,
          form: 'name',
          formField: (rootStore) => (<UccInputText
            core="name"
            fieldName="name"
            form={rootStore.channel.model.crudStore.form}
            label="Language"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
          />),
          formOrder: 20,
          grid: true,
          grid_order: 20,
          grid_link: 'draft_edit',
          grid_value: (row) => row.name,
          id: 'name',
          label: 'Language',
          name: 'name',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: 'required|string',
          utaType: 'name',
          show: true,
          show_value: (row) => row.name,
          show_order: 20,
        },
        {
          colSize: 15,
          form: 'abbrev',
          formField: (rootStore) => (<UccInputText
            core="abbrev"
            fieldName="abbrev"
            form={rootStore.channel.model.crudStore.form}
            label="Abbreviation"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
            disabled={rootStore.channel.model.crudStore.initialAction != 'new'}

          />),
          formOrder: 30,
          grid: true,
          grid_order: 30,
          grid_value: (row) => row.abbrev,
          id: 'abbrev',
          label: 'Abbreviation',
          name: 'abbrev',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: ['required', abbrev_regex],
          utaType: 'string',
          show: true,
          show_value: (row) => row.abbrev,
          show_order: 30,
        },
        {
          colSize: 15,
          form: 'position',
          formField: (rootStore) => (<UccInputText
            core="position"
            fieldName="position"
            form={rootStore.channel.model.crudStore.form}
            label="Position"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('position', event)}
          />),
          formOrder: 40,
          grid: true,
          grid_order: 40,
          grid_value: (row) => row.position,
          id: 'position',
          label: 'Position',
          name: 'position',
          rules: 'required|integer',
          utaType: 'integer',
          show: true,
          show_value: (row) => row.position,
          show_order: 40,
        },
        {
          colSize: 8,
          name: 'status_id',
          type: 'hidden',
          filter: (dt, col) => multiSelectFilter(dt, col),
          form: 'status_id',
          id: 'status_id',
          grid_value: (row) => property(row.status, 'name'),
          grid: true,
          grid_order: 40,
          label: 'Status',
          options: (apiStore) => apiStore.statuses,
          rules: 'required|integer',
          show_value: (row) => property(row.status, 'name'),
          show_order: 40,
          utaType: 'status',
        },
      ],
    },
    {
      allFilterData: (apiStore) => apiStore['marketingCampaignsFilterOptions'],
      codename: 'AdminMarketingCampaigns',
      controller: 'marketing_campaign',
      endpoint: 'marketing-campaigns',
      genericModelPromiseEndpoints: ['marketing-campaigns', 'brands', 'statuses'],
      form: () => <GenericForm datePicker={2} numButtons="approve" />,
      modelFilter: (root) => { return {}; },
      modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
        apiStore.marketingCampaignsCrud.getFilterOptions({ brand_id: brand_id }, ''),
      ]),
      name: 'Marketing Campaigns',
      permArchive: 20,
      permCreateAndEdit: 20,
      permDelete: 90,
      permListAndView: 20,
      permRequest: 15,
      route: 'marketing-campaigns',
      send2domo: false,
      showFormat: 'definitionList',
      singular: 'Marketing Campaign',
      sortField: 'name',
      tabIndex: 10,
      tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
      tabDisabled: () => false,
      noDataText: (root) => '',
      requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
      crud: (rootStore) => rootStore.marketingCampaignsCrud,
      columns: [
        {
          colSize: 6,
          form: 'id',
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_value: (row) => row.id,
          grid_order: 1,
          id: 'id',
          label: 'ID',
          name: 'id',
          permListAndView: 80,
          rules: 'integer',
          type: 'hidden',
        },
        {
          form: 'name',
          formField: (rootStore) => (<UccInputText
            core="name"
            fieldName="name"
            form={rootStore.channel.model.crudStore.form}
            label="Marketing Campaign"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
          />),
          formOrder: 20,
          grid: true,
          grid_order: 20,
          grid_link: 'draft_edit',
          grid_value: (row) => row.name,
          id: 'name',
          label: 'Marketing Campaign',
          name: 'name',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: 'required|string',
          utaType: 'name',
          show: true,
          show_value: (row) => row.name,
          show_order: 20,
        },
        {
          form: 'abbrev',
          formField: (rootStore) => (<UccInputText
            core="abbrev"
            fieldName="abbrev"
            form={rootStore.channel.model.crudStore.form}
            label="Abbreviation"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
            disabled={rootStore.channel.model.crudStore.initialAction != 'new'}

          />),
          formOrder: 30,
          grid: true,
          grid_order: 30,
          grid_value: (row) => row.abbrev,
          id: 'abbrev',
          label: 'Abbreviation',
          name: 'abbrev',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: ['required', abbrev_regex],
          utaType: 'string',
          show: true,
          show_value: (row) => row.abbrev,
          show_order: 30,
        },
        {
          copyEditable: true,
          belongsTo: ['brands', 'brands'],
          form: 'brand_ids',
          formField: (rootStore) => (<UccMultiSelect
            core="brands"
            fieldName="brand_ids"
            form={rootStore.channel.model.crudStore.form}
            label="Brands"
            menuItems={rootStore.apiStore.brands.map((p) => ({
              label: p.name,
              value: p.id,
            }))}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('brand_ids', event)}
          />),
          formOrder: 40,
          label: 'brand',
          multiple: [],
          rules: 'array',
          utaType: 'array',
        },
        {
          grid: true,
          grid_order: 40,
          grid_value: (row) => row.brands.map(c => c.name).join(', '),
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.brands,
          id: 'brands',
          label: 'Brands',
          multiple: [],
          name: 'brands',
          rules: 'array',
          show: true,
          show_value: (row) => row.brands.map(c => c.name).join(', '),
          show_order: 50,
          utaType: 'array',
          sortable: false
        },
        {
          colSize: 8,
          name: 'status_id',
          type: 'hidden',
          filter: (dt, col) => multiSelectFilter(dt, col),
          form: 'status_id',
          id: 'status_id',
          grid_value: (row) => property(row.status, 'name'),
          grid: true,
          grid_order: 90,
          label: 'Status',
          options: (apiStore) => apiStore.statuses,
          rules: 'required|integer',
          show_value: (row) => property(row.status, 'name'),
          show_order: 90,
          utaType: 'status',

        },
        // {
        //   belongsTo: ['channels', 'channels'],
        //   form: 'channel_ids',
        //   formField: (rootStore) => (<UccMultiSelect
        //     core="channels"
        //     fieldName="channel_ids"
        //     form={rootStore.channel.model.crudStore.form}
        //     label="Channels"
        //     menuItems={rootStore.apiStore.channels.map((p) => ({
        //       label: p.name,
        //       value: p.id,
        //     }))}
        //     onChange={(event) => rootStore.channel.model.crudStore.storeData('channel_ids', event)}
        //   />),
        //   formOrder: 40,
        //   label: 'Channel',
        //   multiple: [],
        //   rules: 'required|array',
        //   utaType: 'array',
        // },
        // {
        //   grid: true,
        //   grid_order: 40,
        //   grid_value: (row) => row.channels.map(c => c.name).join(', '),
        //   filter: (dt, col) => multiSelectFilter(dt, col),
        //   options: (apiStore) => apiStore.channels,
        //   id: 'channels',
        //   label: 'Channels',
        //   name: 'channels',
        //   rules: 'array',
        //   show: true,
        //   show_value: (row) => row.channels.map(c => c.name).join(', '),
        //   show_order: 50,
        //   utaType: 'array',
        //   sortable: false
        // },
      ].concat(
        dateField(
          'start_date',
          'Start Date',
          'blank',
          false,
          100,
          130,
          'S.',
        ),
        dateField('end_date', 'End Date', 'blank', false, 110, 140, 'E.'),
      )
    },


    {
      allFilterData: (apiStore) => apiStore['modelsFilterOptions'],
      codename: 'AdminModels',
      controller: 'model',
      endpoint: 'models',
      genericModelPromiseEndpoints: ['models', 'channels', 'statuses'],
      form: () => <GenericForm datePicker={2} numButtons="approve" />,
      modelFilter: (root) => { return {}; },
      modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
        apiStore.modelsCrud.getFilterOptions({ brand_id: brand_id }, ''),
      ]),
      name: 'Models',
      permCreateAndEdit: 80,
      permDelete: 90,
      permListAndView: 80,
      route: 'models',
      send2domo: false,
      showFormat: 'definitionList',
      singular: 'Model',
      sortField: 'name',
      tabIndex: 6,
      tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
      tabDisabled: () => false,
      noDataText: (root) => '',
      requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
      crud: (rootStore) => rootStore.modelsCrud,
      columns: [
        {
          colSize: 6,
          form: 'id',
          grid: true,
          grid_order: 10,
          grid_value: (row) => row.id,
          id: 'id',
          label: 'id',
          rules: 'integer',
          sortable: true,
          type: 'hidden',
        },
        {
          colSize: 15,
          form: 'name',
          formField: (rootStore) => (<UccInputText
            core="name"
            fieldName="name"
            form={rootStore.channel.model.crudStore.form}
            label="Model"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
          />),
          formOrder: 20,
          grid: true,
          grid_order: 20,
          grid_link: 'draft_edit',
          grid_value: (row) => row.name,
          id: 'name',
          label: 'Model',
          name: 'name',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: 'required|string',
          utaType: 'name',
          show: true,
          show_value: (row) => row.name,
          show_order: 20,
        },
        {
          colSize: 15,
          form: 'codename',
          formField: (rootStore) => (<UccInputText
            core="codename"
            fieldName="codename"
            form={rootStore.channel.model.crudStore.form}
            label="Code Name"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('codename', event)}
          />),
          formOrder: 30,
          grid: true,
          grid_order: 30,
          grid_value: (row) => row.codename,
          id: 'codename',
          label: 'Code Name',
          name: 'codename',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: ['required', abbrev_regex],
          utaType: 'string',
          show: true,
          show_value: (row) => row.codename,
          show_order: 30,
        },
        {
          colSize: 15,
          form: 'position',
          formField: (rootStore) => (<UccInputText
            core="position"
            fieldName="position"
            form={rootStore.channel.model.crudStore.form}
            label="Position"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('position', event)}
          />),
          formOrder: 40,
          grid: true,
          grid_order: 40,
          grid_value: (row) => row.position,
          id: 'position',
          label: 'Position',
          name: 'position',
          rules: 'required|integer',
          utaType: 'integer',
          show: true,
          show_value: (row) => row.position,
          show_order: 40,
        },

        {
          belongsTo: ['channel', 'channels'],
          form: 'channel_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="channel"
            fieldName="channel_id"

            form={rootStore.channel.model.crudStore.form}
            label="Channel"
            menuItems={rootStore.uiStore.menuItems('channels', 'makePrimeRegularOption')}
            onChange={event => rootStore.channel.model.crudStore.storeData('channel_id', event)}
          />),
          formOrder: 60,
          label: 'channel',
          rules: 'integer',
          utaType: 'fk',
        },
        {
          colSize: 10.5,
          domo_field: 'channel',
          domo_value: (crudStore) => property(crudStore.storedData.channel, 'name'),
          grid: true,
          grid_order: 60,
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.channels,
          grid_value: (row) => property(row.channel, 'name'),
          id: 'channel',
          label: 'Channel',
          name: 'channel',
          ns: (crudStore) => abbrev(crudStore.storedData.channel),
          // ns_order: 15,
        },
      ],
    },
    {
      allFilterData: (apiStore) => apiStore['objectivesFilterOptions'],
      codename: 'AdminObjectives',
      controller: 'objective',
      endpoint: 'objectives',
      genericModelPromiseEndpoints: ['objectives', 'campaign-types', 'channels', 'statuses'],
      form: () => <GenericForm datePicker={2} numButtons="approve" />,
      modelFilter: (root) => { return {}; },
      modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
        apiStore.objectivesCrud.getFilterOptions({ brand_id: brand_id }, ''),
      ]),
      name: 'Objectives',
      permArchive: 60,
      permCreateAndEdit: 60,
      permDelete: 90,
      permListAndView: 20,
      permRequest: 20,
      route: 'objectives',
      send2domo: false,
      showFormat: 'definitionList',
      singular: 'Objective',
      sortField: 'name',
      tabIndex: 11,
      tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
      tabDisabled: () => false,
      noDataText: (root) => '',
      requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
      crud: (rootStore) => rootStore.objectivesCrud,
      columns: [
        {
          colSize: 6,
          form: 'id',
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_value: (row) => row.id,
          grid_order: 1,
          id: 'id',
          label: 'ID',
          name: 'id',
          permListAndView: 80,
          rules: 'integer',
          type: 'hidden',
        },
        {
          colSize: 15,
          form: 'name',
          formField: (rootStore) => (<UccInputText
            core="name"
            fieldName="name"
            form={rootStore.channel.model.crudStore.form}
            label="Objective"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
          />),
          formOrder: 20,
          grid: true,
          grid_order: 20,
          grid_link: 'draft_edit',
          grid_value: (row) => row.name,
          id: 'name',
          label: 'Objective',
          name: 'name',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: 'required|string',
          utaType: 'name',
          show: true,
          show_value: (row) => row.name,
          show_order: 20,
        },
        {
          colSize: 15,
          form: 'abbrev',
          formField: (rootStore) => (<UccInputText
            core="abbrev"
            fieldName="abbrev"
            form={rootStore.channel.model.crudStore.form}
            label="Abbreviation"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
            disabled={rootStore.channel.model.crudStore.initialAction != 'new'}

          />),
          formOrder: 30,
          grid: true,
          grid_order: 30,
          grid_value: (row) => row.abbrev,
          id: 'abbrev',
          label: 'Abbreviation',
          name: 'abbrev',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: ['required', abbrev_regex],
          utaType: 'string',
          show: true,
          show_value: (row) => row.abbrev,
          show_order: 30,
        },
        {
          colSize: 15,
          belongsTo: ['campaign-types', 'campaignTypes'],
          form: 'campaign_type_ids',
          formField: (rootStore) => (<UccMultiSelect
            core="campaign_types"
            fieldName="campaign_type_ids"
            form={rootStore.channel.model.crudStore.form}
            label="Campaign Types"
            menuItems={rootStore.apiStore.campaignTypes.map((p) => ({
              label: p.name,
              value: p.id,
            }))}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('campaign_type_ids', event)}
          />),
          formOrder: 40,
          label: 'Campaign Type',
          multiple: [],
          rules: 'required|integer',
          utaType: 'array',
        },
        {
          colSize: 15,
          grid: true,
          grid_order: 40,
          grid_value: (row) => row.campaign_types.map(c => c.name).join(', '),
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.campaignTypes,
          id: 'campaign_types',
          label: 'Campaign Types',
          name: 'campaign_types',
          rules: 'array',
          show: true,
          show_value: (row) => row.campaign_types.map(c => c.name).join(', '),
          show_order: 60,
          utaType: 'array',
          sortable: false
        },
        {
          belongsTo: ['channels', 'channels'],
          form: 'channel_ids',
          formField: (rootStore) => (<UccMultiSelect
            core="channels"
            fieldName="channel_ids"
            form={rootStore.channel.model.crudStore.form}
            label="Channels"
            menuItems={channelSpecificOptions(rootStore, 'objective')}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('channel_ids', event)}
          />),
          formOrder: 50,
          label: 'Channel',
          multiple: [],
          rules: 'required|array',
          utaType: 'array',
        },
        {
          colSize: 15,
          grid: true,
          grid_order: 50,
          grid_value: (row) => row.channels.map(c => c.name).join(', '),
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.channels,
          id: 'channels',
          label: 'Channels',
          multiple: [],
          name: 'channels',
          rules: 'array',
          show: true,
          show_value: (row) => row.channels.map(c => c.name).join(', '),
          show_order: 50,
          utaType: 'array',
          sortable: false
        },
        {
          colSize: 8,
          name: 'status_id',
          type: 'hidden',
          filter: (dt, col) => multiSelectFilter(dt, col),
          form: 'status_id',
          id: 'status_id',
          grid_value: (row) => property(row.status, 'name'),
          grid: true,
          grid_order: 60,
          label: 'Status',
          options: (apiStore) => apiStore.statuses,
          rules: 'required|integer',
          show_value: (row) => property(row.status, 'name'),
          show_order: 60,
          utaType: 'status',
        },
      ],
    },


    {
      allFilterData: (apiStore) => apiStore['objectiveModifiersFilterOptions'],
      codename: 'AdminObjectiveModifiers',
      controller: 'objective_modifier',
      endpoint: 'objective-modifiers',
      genericModelPromiseEndpoints: ['objectives', 'objective-modifiers', 'statuses'],
      form: () => <GenericForm datePicker={2} numButtons="approve" />,
      modelFilter: (root) => { return {}; },
      modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
        apiStore.objectiveModifiersCrud.getFilterOptions({ brand_id: brand_id }, ''),
      ]),
      name: 'Objective Modifiers',
      permArchive: 40,
      permCreateAndEdit: 60,
      permDelete: 90,
      permListAndView: 20,
      route: 'objective-modifiers',
      permRequest: false,
      send2domo: false,
      showFormat: 'definitionList',
      singular: 'Objective Modifier',
      sortField: 'name',
      tabIndex: 11,
      tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
      tabDisabled: () => false,
      noDataText: (root) => '',
      requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
      crud: (rootStore) => rootStore.objectiveModifiersCrud,
      columns: [
        {
          colSize: 6,
          form: 'id',
          label: 'id',
          rules: 'integer',
          type: 'hidden',
          grid: true,
          grid_order: 10,
          grid_value: (row) => row.id,
        },
        {
          colSize: 15,
          form: 'name',
          formField: (rootStore) => (<UccInputText
            core="name"
            fieldName="name"
            form={rootStore.channel.model.crudStore.form}
            label="Objective Modifier"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
          />),
          formOrder: 20,
          grid: true,
          grid_order: 20,
          grid_link: 'draft_edit',
          grid_value: (row) => row.name,
          id: 'name',
          label: 'Objective Modifier',
          name: 'name',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: 'required|string',
          utaType: 'name',
          show: true,
          show_value: (row) => row.name,
          show_order: 20,
        },
        {
          colSize: 15,
          form: 'abbrev',
          formField: (rootStore) => (<UccInputText
            core="abbrev"
            fieldName="abbrev"
            form={rootStore.channel.model.crudStore.form}
            label="Abbreviation"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
            disabled={rootStore.channel.model.crudStore.initialAction != 'new'}

          />),
          formOrder: 30,
          grid: true,
          grid_order: 30,
          grid_value: (row) => row.abbrev,
          id: 'abbrev',
          label: 'Abbreviation',
          name: 'abbrev',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: ['required', abbrev_regex],
          utaType: 'string',
          show: true,
          show_value: (row) => row.abbrev,
          show_order: 30,
        },

        {
          colSize: 15,
          belongsTo: ['objectives', 'objectives'],
          form: 'objective_ids',
          copyEditable: true,
          formField: (rootStore) => (<UccMultiSelect
            core="objectives"
            fieldName="objective_ids"
            form={rootStore.channel.model.crudStore.form}
            label="Objectives"
            menuItems={rootStore.apiStore.objectives.map((p) => ({
              label: p.name,
              value: p.id,
            }))}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('objective_ids', event)}
          />),
          formOrder: 80,
          label: 'Objective',
          multiple: [],
          rules: 'required|array',
          utaType: 'array',
        },
        {
          colSize: 15,
          grid: true,
          grid_order: 80,
          grid_value: (row) => row.objectives.map(c => c.name).join(', '),
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.objectives,
          id: 'objectives',
          label: 'Objectives',
          multiple: [],
          name: 'objectives',
          rules: 'array',
          show: true,
          show_value: (row) => row.objectives.map(c => c.name).join(', '),
          show_order: 80,
          utaType: 'array',
          sortable: false
        },
      ],
    },


    {
      allFilterData: (apiStore) => apiStore['optimizationGoalsFilterOptions'],
      codename: 'AdminOptimizationGoals',
      controller: 'optimization_goal',
      disableBulkEdit: true,
      endpoint: 'optimization-goals',
      filterOptionsCall: 'setOptimizationGoalFilterOptions',
      genericModelPromiseEndpoints: ['optimization-goals', 'statuses'],
      form: () => <GenericForm datePicker={2} numButtons="approve" />,
      modelFilter: (root) => { return {}; },
      modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([apiStore.optimizationGoalsCrud.getFilterOptions({ brand_id: brand_id }, '')]),
      name: 'Optimization Goal',
      permArchive: 40,
      permCreateAndEdit: 60,
      permDelete: 90,
      permListAndView: 20,
      permRequest: 20,
      route: 'optimization-goals',
      send2domo: false,
      showFormat: 'definitionList',
      singular: 'Optimization Goal',
      sortField: 'name',
      tabIndex: 12,
      tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
      tabDisabled: () => false,
      noDataText: (root) => '',
      requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
      crud: (rootStore) => rootStore.optimizationGoalsCrud,
      columns: [
        {
          colSize: 6,
          form: 'id',
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_value: (row) => row.id,
          grid_order: 1,
          id: 'id',
          label: 'ID',
          name: 'id',
          permListAndView: 80,
          rules: 'integer',
          type: 'hidden',
        },
        {
          colSize: 15,
          form: 'name',
          formField: (rootStore) => (<UccInputText
            core="name"
            fieldName="name"
            form={rootStore.channel.model.crudStore.form}
            label="Ad Modifier"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
          />),
          formOrder: 20,
          grid: true,
          grid_order: 20,
          grid_link: 'draft_edit',
          grid_value: (row) => row.name,
          id: 'name',
          label: 'Optimization Goal',
          name: 'name',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: 'required|string',
          utaType: 'name',
          show: true,
          show_value: (row) => row.name,
          show_order: 20,
        },
        {
          colSize: 15,
          form: 'abbrev',
          formField: (rootStore) => (<UccInputText
            core="abbrev"
            fieldName="abbrev"
            form={rootStore.channel.model.crudStore.form}
            label="Abbreviation"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
            disabled={rootStore.channel.model.crudStore.initialAction != 'new'}

          />),
          formOrder: 30,
          grid: true,
          grid_order: 30,
          grid_value: (row) => row.abbrev,
          id: 'abbrev',
          label: 'Abbreviation',
          name: 'abbrev',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: ['required', abbrev_regex],
          utaType: 'string',
          show: true,
          show_value: (row) => row.abbrev,
          show_order: 30,
        },
        {
          colSize: 8,
          name: 'status_id',
          type: 'hidden',
          filter: (dt, col) => multiSelectFilter(dt, col),
          form: 'status_id',
          id: 'status_id',
          grid_value: (row) => property(row.status, 'name'),
          grid: true,
          grid_order: 90,
          label: 'Status',
          options: (apiStore) => apiStore.statuses,
          rules: 'required|integer',
          show_value: (row) => property(row.status, 'name'),
          show_order: 90,
          utaType: 'status',
        },
      ],
    },
    {
      allFilterData: (apiStore) => apiStore['adModifiersFilterOptions'],
      codename: 'AdminAdModifiers',
      controller: 'ad_modifier',
      endpoint: 'ad-modifiers',
      filterOptionsCall: 'setAdModifierFilterOptions',
      genericModelPromiseEndpoints: ['ad-modifiers', 'objective-modifiers', 'statuses'],
      form: () => <GenericForm datePicker={2} numButtons="approve" />,
      modelFilter: (root) => { return {}; },
      modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
        apiStore.adModifiersCrud.getFilterOptions({ brand_id: brand_id }, ''),
      ]),
      name: 'Ad Modifier',
      permArchive: 40,
      permCreateAndEdit: 60,
      permDelete: 90,
      permListAndView: 20,
      permRequest: 20,
      route: 'ad-modifiers',
      send2domo: false,
      showFormat: 'definitionList',
      singular: 'Ad Modifier',
      sortField: 'name',
      tabIndex: 4,
      tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
      tabDisabled: () => false,
      noDataText: (root) => '',
      requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
      crud: (rootStore) => rootStore.objectiveModifiersCrud,
      columns: [
        {
          colSize: 6,
          form: 'id',
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_value: (row) => row.id,
          grid_order: 1,
          id: 'id',
          label: 'ID',
          name: 'id',
          permListAndView: 80,
          rules: 'integer',
          type: 'hidden',
        },
        {
          colSize: 15,
          form: 'name',
          formField: (rootStore) => (<UccInputText
            core="name"
            fieldName="name"
            form={rootStore.channel.model.crudStore.form}
            label="Ad Modifier"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
          />),
          formOrder: 20,
          grid: true,
          grid_order: 20,
          grid_link: 'draft_edit',
          grid_value: (row) => row.name,
          id: 'name',
          label: 'Ad Modifier',
          name: 'name',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: 'required|string',
          utaType: 'name',
          show: true,
          show_value: (row) => row.name,
          show_order: 20,
        },
        {
          colSize: 15,
          form: 'abbrev',
          formField: (rootStore) => (<UccInputText
            core="abbrev"
            fieldName="abbrev"
            form={rootStore.channel.model.crudStore.form}
            label="Abbreviation"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
            disabled={rootStore.channel.model.crudStore.initialAction != 'new'}

          />),
          formOrder: 30,
          grid: true,
          grid_order: 30,
          grid_value: (row) => row.abbrev,
          id: 'abbrev',
          label: 'Abbreviation',
          name: 'abbrev',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: ['required', abbrev_regex],
          utaType: 'string',
          show: true,
          show_value: (row) => row.abbrev,
          show_order: 30,
        },
        {
          colSize: 15,
          belongsTo: ['objective_modifiers', 'objectiveModifiers'],
          copyEditable: true,
          form: 'objective_modifier_ids',
          formField: (rootStore) => (<UccMultiSelect
            core="objective_modifiers"
            fieldName="objective_modifier_ids"
            form={rootStore.channel.model.crudStore.form}
            label="Objective Modifiers"
            menuItems={rootStore.apiStore.objectiveModifiers.map((p) => ({
              label: p.name,
              value: p.id,
            }))}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('objective_modifier_ids', event)}
          />),
          formOrder: 80,
          label: 'Objective Modifier',
          multiple: [],
          rules: 'required|array',
          utaType: 'array',
        },
        {
          colSize: 15,
          grid: true,
          grid_order: 80,
          grid_value: (row) => row.objective_modifiers.map(c => c.name).join(', '),
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.objectiveModifiers,
          id: 'objective_modifiers',
          label: "Objective Modifiers",
          multiple: [],
          name: 'objective_modifiers',
          multiple: [],
          rules: 'array',
          show: true,
          show_value: (row) => row.objective_modifiers.map(c => c.name).join(', '),
          show_order: 80,
          utaType: 'array',
          sortable: false
        },
        {
          colSize: 8,
          name: 'status_id',
          type: 'hidden',
          filter: (dt, col) => multiSelectFilter(dt, col),
          form: 'status_id',
          id: 'status_id',
          grid_value: (row) => property(row.status, 'name'),
          grid: true,
          grid_order: 90,
          label: 'Status',
          options: (apiStore) => apiStore.statuses,
          rules: 'required|integer',
          show_value: (row) => property(row.status, 'name'),
          show_order: 90,
          utaType: 'status',
        },
      ],
    },

    {
      allFilterData: (apiStore) => apiStore['platformsFilterOptions'],
      codename: 'AdminPlatforms',
      controller: 'platform',
      endpoint: 'platforms',
      genericModelPromiseEndpoints: ['platforms', 'channels', 'statuses', 'channel-platforms'],
      form: () => <GenericForm datePicker={2} numButtons="approve" />,
      modelFilter: (root) => { return {}; },
      modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
        apiStore.platformsCrud.getFilterOptions({ brand_id: brand_id }, ''),
      ]),
      name: 'Platforms',
      permArchive: 40,
      permCreateAndEdit: 40,
      permDelete: 90,
      permListAndView: 20,
      permRequest: 20,
      route: 'platforms',
      send2domo: false,
      showFormat: 'definitionList',
      singular: 'Platform',
      sortField: 'name',
      tabIndex: 13,
      tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
      tabDisabled: () => false,
      noDataText: (root) => '',
      requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
      crud: (rootStore) => rootStore.platformsCrud,
      columns: [
        {
          colSize: 6,
          form: 'id',
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_value: (row) => row.id,
          grid_order: 1,
          id: 'id',
          label: 'ID',
          name: 'id',
          permListAndView: 80,
          rules: 'integer',
          type: 'hidden',
        },
        {
          colSize: 15,
          form: 'name',
          formField: (rootStore) => (<UccInputText
            core="name"
            fieldName="name"
            form={rootStore.channel.model.crudStore.form}
            label="Platform"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
          />),
          formOrder: 20,
          grid: true,
          grid_link: 'draft_edit',
          grid_order: 20,
          grid_value: (row) => row.name,
          id: 'name',
          label: 'Platform',
          name: 'name',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: 'required|string',
          utaType: 'name',
          show: true,
          show_value: (row) => row.name,
          show_order: 20,
        },
        {
          colSize: 15,
          form: 'abbrev',
          formField: (rootStore) => (<UccInputText
            core="abbrev"
            fieldName="abbrev"
            form={rootStore.channel.model.crudStore.form}
            label="Abbreviation"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
            disabled={rootStore.channel.model.crudStore.initialAction != 'new'}

          />),
          formOrder: 30,
          grid: true,
          grid_order: 30,
          grid_value: (row) => row.abbrev,
          id: 'abbrev',
          label: 'Abbreviation',
          name: 'abbrev',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: ['required', abbrev_regex],
          utaType: 'string',
          show: true,
          show_value: (row) => row.abbrev,
          show_order: 30,
        },
        {
          belongsTo: ['channels', 'channels'],
          copyEditable: true,
          form: 'channel_ids',
          formField: (rootStore) => (<UccMultiSelect
            core="channels"
            fieldName="channel_ids"
            form={rootStore.channel.model.crudStore.form}
            label="Channels"
            menuItems={channelSpecificOptions(rootStore, 'platform')}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('channel_ids', event)}
          />),
          formOrder: 40,
          label: 'Channel',
          multiple: [],
          rules: 'required|array',
          utaType: 'array',
        },
        {
          colSize: 15,
          grid: true,
          grid_order: 40,
          grid_value: (row) => row.channels.map(c => c.name).join(', '),
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.channels,
          id: 'channels',
          label: 'Channels',
          multiple: [],
          name: 'channels',
          rules: 'array',
          show: true,
          show_value: (row) => row.channels.map(c => c.name).join(', '),
          show_order: 50,
          utaType: 'array',
          sortable: false
        },
        {
          colSize: 8,
          name: 'status_id',
          type: 'hidden',
          filter: (dt, col) => multiSelectFilter(dt, col),
          form: 'status_id',
          id: 'status_id',
          grid_value: (row) => property(row.status, 'name'),
          grid: true,
          grid_order: 40,
          label: 'Status',
          options: (apiStore) => apiStore.statuses,
          rules: 'required|integer',
          show_value: (row) => property(row.status, 'name'),
          show_order: 40,
          utaType: 'status',
        },
      ],
    },
    {
      allFilterData: (apiStore) => apiStore['publishersFilterOptions'],
      codename: 'AdminPublishers',
      controller: 'publisher',
      disableBulkEdit: true,
      endpoint: 'publishers',
      form: () => <GenericForm datePicker={2} numButtons="approve" />,
      genericModelPromiseEndpoints: ['publishers', 'statuses'],
      modelFilter: (root) => { return {}; },
      modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
        apiStore.publishersCrud.getFilterOptions({ brand_id: brand_id }, ''),
      ]),
      name: 'Publishers',
      permArchive: 40,
      permCreateAndEdit: 40,
      permDelete: 90,
      permListAndView: 20,
      permRequest: 20,
      route: 'publishers',
      send2domo: false,
      showFormat: 'definitionList',
      singular: 'Publisher',
      sortField: 'name',
      tabIndex: 14,
      tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
      tabDisabled: () => false,
      noDataText: (root) => '',
      requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
      crud: (rootStore) => rootStore.publishersCrud,
      columns: [
        {
          colSize: 6,
          form: 'id',
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_value: (row) => row.id,
          grid_order: 1,
          id: 'id',
          label: 'id',
          name: 'id',
          permListAndView: 80,
          rules: 'integer',
          type: 'hidden',
        },
        {
          colSize: 15,
          form: 'name',
          formField: (rootStore) => (<UccInputText
            core="name"
            fieldName="name"
            form={rootStore.channel.model.crudStore.form}
            label="Publisher"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
          />),
          formOrder: 20,
          grid: true,
          grid_order: 20,
          grid_link: 'draft_edit',
          grid_value: (row) => row.name,
          id: 'name',
          label: 'Publisher',
          name: 'name',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: 'required|string',
          utaType: 'name',
          show: true,
          show_value: (row) => row.name,
          show_order: 20,
        },
        {
          colSize: 15,
          form: 'abbrev',
          formField: (rootStore) => (<UccInputText
            core="abbrev"
            fieldName="abbrev"
            form={rootStore.channel.model.crudStore.form}
            label="Abbreviation"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
            disabled={rootStore.channel.model.crudStore.initialAction != 'new'}

          />),
          formOrder: 30,
          grid: true,
          grid_order: 30,
          grid_value: (row) => row.abbrev,
          id: 'abbrev',
          label: 'Abbreviation',
          name: 'abbrev',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: ['required', abbrev_regex],
          utaType: 'string',
          show: true,
          show_value: (row) => row.abbrev,
          show_order: 30,
        },
        {
          colSize: 8,
          name: 'status_id',
          type: 'hidden',
          filter: (dt, col) => multiSelectFilter(dt, col),
          form: 'status_id',
          id: 'status_id',
          grid_value: (row) => property(row.status, 'name'),
          grid: true,
          grid_order: 40,
          label: 'Status',
          options: (apiStore) => apiStore.statuses,
          rules: 'required|integer',
          show_value: (row) => property(row.status, 'name'),
          show_order: 40,
          utaType: 'status',
        },
      ],
    },
    {
      allFilterData: (apiStore) => apiStore['sizesFilterOptions'],
      codename: 'AdminSizes',
      controller: 'size',
      disableBulkEdit: true,
      endpoint: 'sizes',
      form: () => <GenericForm datePicker={2} numButtons="approve" />,
      genericModelPromiseEndpoints: ['sizes', 'statuses'],
      modelFilter: (root) => { return {}; },
      modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
        apiStore.sizesCrud.getFilterOptions({ brand_id: brand_id }, ''),
      ]),
      name: 'Sizes',
      permArchive: 40,
      permCreateAndEdit: 40,
      permDelete: 90,
      permListAndView: 20,
      permRequest: 20,
      route: 'sizes',
      send2domo: false,
      showFormat: 'definitionList',
      singular: 'Size',
      sortField: 'name',
      tabIndex: 15,
      tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
      tabDisabled: () => false,
      noDataText: (root) => '',
      requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
      crud: (rootStore) => rootStore.sizesCrud,
      columns: [
        {
          colSize: 6,
          form: 'id',
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_value: (row) => row.id,
          grid_order: 1,
          id: 'id',
          label: 'id',
          name: 'id',
          permListAndView: 80,
          rules: 'integer',
          type: 'hidden',
        },
        {
          colSize: 15,
          form: 'name',
          formField: (rootStore) => (<UccInputText
            core="name"
            fieldName="name"
            form={rootStore.channel.model.crudStore.form}
            label="Size"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
          />),
          formOrder: 20,
          grid: true,
          grid_order: 20,
          grid_link: 'draft_edit',
          grid_value: (row) => row.name,
          id: 'name',
          label: 'Size',
          name: 'name',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: 'required|string',
          utaType: 'name',
          show: true,
          show_value: (row) => row.name,
          show_order: 20,
        },
        {
          colSize: 15,
          form: 'abbrev',
          formField: (rootStore) => (<UccInputText
            core="abbrev"
            fieldName="abbrev"
            form={rootStore.channel.model.crudStore.form}
            label="Abbreviation"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
            disabled={rootStore.channel.model.crudStore.initialAction != 'new'}

          />),
          formOrder: 30,
          grid: true,
          grid_order: 30,
          grid_value: (row) => row.abbrev,
          id: 'abbrev',
          label: 'Abbreviation',
          name: 'abbrev',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: ['required', abbrev_regex],
          utaType: 'string',
          show: true,
          show_value: (row) => row.abbrev,
          show_order: 30,
        },
        {
          colSize: 8,
          name: 'status_id',
          type: 'hidden',
          filter: (dt, col) => multiSelectFilter(dt, col),
          form: 'status_id',
          id: 'status_id',
          grid_value: (row) => property(row.status, 'name'),
          grid: true,
          grid_order: 40,
          label: 'Status',
          options: (apiStore) => apiStore.statuses,
          rules: 'required|integer',
          show_value: (row) => property(row.status, 'name'),
          show_order: 40,
          utaType: 'status',
        },
      ],
    },
    {
      allFilterData: (apiStore) => apiStore['tacticsFilterOptions'],
      codename: 'AdminTactics',
      controller: 'tactic',
      endpoint: 'tactics',
      genericModelPromiseEndpoints: ['tactics', 'channels', 'statuses'],
      form: () => <GenericForm datePicker={2} numButtons="approve" />,
      modelFilter: (root) => { return {}; },
      modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
        apiStore.tacticsCrud.getFilterOptions({ brand_id: brand_id }, ''),
      ]),
      name: 'Tactics',
      permArchive: 80,
      permCreateAndEdit: 60,
      permDelete: 90,
      permListAndView: 20,
      permRequest: 20,
      route: 'tactics',
      send2domo: false,
      showFormat: 'definitionList',
      singular: 'Tactic',
      sortField: 'name',
      tabIndex: 16,
      tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
      tabDisabled: () => false,
      noDataText: (root) => '',
      requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
      crud: (rootStore) => rootStore.tacticsCrud,
      columns: [
        {
          colSize: 6,
          form: 'id',
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_value: (row) => row.id,
          grid_order: 1,
          id: 'id',
          label: 'id',
          name: 'id',
          permListAndView: 80,
          rules: 'integer',
          type: 'hidden',
        },
        {
          colSize: 15,
          form: 'name',
          formField: (rootStore) => (<UccInputText
            core="name"
            fieldName="name"
            form={rootStore.channel.model.crudStore.form}
            label="Tactic"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
          />),
          formOrder: 20,
          grid: true,
          grid_order: 20,
          grid_link: 'draft_edit',
          grid_value: (row) => row.name,
          id: 'name',
          label: 'Tactic',
          name: 'name',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: 'required|string',
          utaType: 'name',
          show: true,
          show_value: (row) => row.name,
          show_order: 20,
        },
        {
          form: 'abbrev',
          formField: (rootStore) => (<UccInputText
            core="abbrev"
            fieldName="abbrev"
            form={rootStore.channel.model.crudStore.form}
            label="Abbreviation"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
            disabled={rootStore.channel.model.crudStore.initialAction != 'new'}
          />),
          formOrder: 30,
          grid: true,
          grid_order: 30,
          grid_value: (row) => row.abbrev,
          id: 'abbrev',
          label: 'Abbreviation',
          name: 'abbrev',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: ['required', abbrev_regex],
          utaType: 'string',
          show: true,
          show_value: (row) => row.abbrev,
          show_order: 30,
        },
        {
          belongsTo: ['channels', 'channels'],
          copyEditable: true,
          form: 'channel_ids',
          formField: (rootStore) => (<UccMultiSelect
            core="channels"
            fieldName="channel_ids"
            form={rootStore.channel.model.crudStore.form}
            label="Channels"
            menuItems={channelSpecificOptions(rootStore, 'tactic')}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('channel_ids', event)}
          />),
          formOrder: 40,
          label: 'Channel',
          multiple: [],
          rules: 'required|array',
          utaType: 'array',
        },
        {
          colSize: 15,
          grid: true,
          grid_order: 40,
          grid_value: (row) => row.channels.map(c => c.name).join(', '),
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.channels,
          id: 'channels',
          label: 'Channels',
          multiple: [],
          name: 'channels',
          rules: 'array',
          show: true,
          show_value: (row) => row.channels.map(c => c.name).join(', '),
          show_order: 50,
          utaType: 'array',
          sortable: false
        },
        {
          colSize: 8,
          name: 'status_id',
          type: 'hidden',
          filter: (dt, col) => multiSelectFilter(dt, col),
          form: 'status_id',
          id: 'status_id',
          grid_value: (row) => property(row.status, 'name'),
          grid: true,
          grid_order: 40,
          label: 'Status',
          options: (apiStore) => apiStore.statuses,
          rules: 'required|integer',
          show_value: (row) => property(row.status, 'name'),
          show_order: 40,
          utaType: 'status',
        },
      ],
    },
    {
      allFilterData: (apiStore) => apiStore['targetingsFilterOptions'],
      codename: 'AdminTargetings',
      controller: 'targeting',
      endpoint: 'targetings',
      genericModelPromiseEndpoints: ['targetings', 'channels', 'tactics', 'statuses'],
      form: () => <GenericForm datePicker={2} numButtons="approve" />,
      modelFilter: (root) => { return {}; },
      modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
        apiStore.targetingsCrud.getFilterOptions({ brand_id: brand_id }, ''),
      ]),
      name: 'Targetings',
      permArchive: 70,
      permCreateAndEdit: 60,
      permDelete: 90,
      permListAndView: 20,
      permRequest: false,
      route: 'targetings',
      send2domo: false,
      showFormat: 'definitionList',
      singular: 'Targeting',
      sortField: 'position',
      tabIndex: 17,
      tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
      tabDisabled: () => false,
      noDataText: (root) => '',
      requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
      crud: (rootStore) => rootStore.targetingsCrud,
      sortField: 'position',
      columns: [
        {
          colSize: 6,
          form: 'id',
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_value: (row) => row.id,
          grid_order: 1,
          id: 'id',
          label: 'id',
          name: 'id',
          permListAndView: 80,
          rules: 'integer',
          show: true,
          show_value: (row) => row.id,
          show_order: 10,
          type: 'hidden',
        },
        {
          colSize: 15,
          form: 'name',
          formField: (rootStore) => (<UccInputText
            core="name"
            fieldName="name"
            form={rootStore.channel.model.crudStore.form}
            label="Targeting"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
          />),
          formOrder: 20,
          grid: true,
          grid_order: 20,
          grid_link: 'draft_edit',
          grid_value: (row) => row.name,
          id: 'name',
          label: 'Targeting Name',
          name: 'name',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: 'required|string',
          utaType: 'name',
          show: true,
          show_value: (row) => row.name,
          show_order: 20,
        },
        {
          colSize: 15,
          form: 'abbrev',
          formField: (rootStore) => (<UccInputText
            core="abbrev"
            fieldName="abbrev"
            form={rootStore.channel.model.crudStore.form}
            label="Abbreviation"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
            disabled={rootStore.channel.model.crudStore.initialAction != 'new'}
          />),
          formOrder: 30,
          grid: true,
          grid_order: 30,
          grid_value: (row) => row.abbrev,
          id: 'abbrev',
          label: 'Abbreviation',
          name: 'abbrev',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: ['required', abbrev_regex],
          utaType: 'string',
          show: true,
          show_value: (row) => row.abbrev,
          show_order: 30,
        },
        {
          colSize: 15,
          form: 'extra_field_type',
          formField: (rootStore) => (<UccSelect
            core="extra_field_type"
            fieldName="extra_field_type"
            form={rootStore.channel.model.crudStore.form}
            label="Extra Field Type"
            menuItems={[{ label: 'Number', value: 'number' }, { label: 'Text', value: 'text' }]}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('extra_field_type', event)}
          />),
          formOrder: 40,
          grid: true,
          grid_order: 40,
          grid_value: (row) => row.extra_field_type,
          id: 'extra_field_type',
          label: 'Extra Field Type',
          name: 'Extra Field Type',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: 'string',
          utaType: 'string',
          show: true,
          show_value: (row) => row.extra_field_type,
          show_order: 40,
        },
        {
          colSize: 15,
          form: 'extra_required',
          formField: (rootStore) => (<UccCheckBox
            core="extra_required"
            fieldName="extra_required"
            form={rootStore.channel.model.crudStore.form}
            label="Extra Required"
            onChange={(event) => rootStore.channel.model.crudStore.storeData('extra_required', event)}
          />),
          formOrder: 50,
          grid: true,
          grid_order: 50,
          grid_value: (row) => (row.extra_required ? 'Yes' : 'No'),
          id: 'extra_required',
          label: 'Extra Required',
          name: 'extra_required',
          type: 'checkbox',
          utaType: 'boolean',
          rules: 'boolean',
          show: true,
          show_value: (row) => (row.extra_required ? 'Yes' : 'No'),
          show_order: 50
        },
        {
          colSize: 15,
          form: 'position',
          formField: (rootStore) => (<UccInputText
            core="position"
            fieldName="position"
            form={rootStore.channel.model.crudStore.form}
            label="Position"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('position', event)}
          />),
          formOrder: 60,
          grid: true,
          grid_order: 60,
          grid_value: (row) => row.position,
          id: 'position',
          label: 'Position',
          name: 'position',
          rules: 'required|integer',
          utaType: 'integer',
          show: true,
          show_value: (row) => row.position,
          show_order: 60,
        },
        {
          copyEditable: true,
          belongsTo: ['channels', 'channels'],
          form: 'channel_ids',
          formField: (rootStore) => (<UccMultiSelect
            core="channels"
            fieldName="channel_ids"
            form={rootStore.channel.model.crudStore.form}
            label="Channels"
            menuItems={channelSpecificOptions(rootStore, 'targeting_ids')}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('channel_ids', event)}
          />),
          formOrder: 70,
          label: 'Channel',
          multiple: [],
          rules: 'required|array',
          utaType: 'array',
        },
        {
          colSize: 15,
          grid: true,
          grid_order: 70,
          grid_value: (row) => row.channels.map(c => c.name).join(', '),
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.channels,
          id: 'channels',
          label: 'Channels',
          multiple: [],
          name: 'channels',
          rules: 'array',
          show: true,
          show_value: (row) => row.channels.map(c => c.name).join(', '),
          show_order: 70,
          utaType: 'array',
          sortable: false
        },
        {
          copyEditable: true,
          colSize: 15,
          belongsTo: ['tactics', 'tactics'],
          form: 'tactic_ids',
          formField: (rootStore) => (<UccMultiSelect
            core="tactics"
            fieldName="tactic_ids"
            form={rootStore.channel.model.crudStore.form}
            label="Tactics"
            menuItems={rootStore.apiStore.tactics.map((p) => ({
              label: p.name,
              value: p.id,
            }))}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('tactic_ids', event)}
          />),
          formOrder: 80,
          label: 'Tactic',
          multiple: [],
          rules: 'required|array',
          utaType: 'array',
        },
        {
          colSize: 15,
          grid: true,
          grid_order: 80,
          grid_value: (row) => row.tactics.map(c => c.name).join(', '),
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.tactics,
          id: 'tactics',
          label: 'Tactics',
          multiple: [],
          name: 'tactics',
          rules: 'array',
          show: true,
          show_value: (row) => row.tactics.map(c => c.name).join(', '),
          show_order: 80,
          utaType: 'array',
          sortable: false
        },
        {
          colSize: 8,
          name: 'status_id',
          type: 'hidden',
          filter: (dt, col) => multiSelectFilter(dt, col),
          form: 'status_id',
          id: 'status_id',
          grid_value: (row) => property(row.status, 'name'),
          grid: true,
          grid_order: 90,
          label: 'Status',
          options: (apiStore) => apiStore.statuses,
          rules: 'required|integer',
          show_value: (row) => property(row.status, 'name'),
          show_order: 90,
          utaType: 'status',
        },
      ],
    },
  ]
};