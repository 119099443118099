
import API from './Api';
import Channel from './Channel';
import ContentCopyIcon from '../icons/ContentCopy';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import MobxReactForm from 'mobx-react-form';
import Moment from 'react-moment';
import NameString from './../components/NameString';
import Pluralize from 'pluralize';
import React from 'react';
import RootStore from '../store/RootStore';
import TargetingField from '../components/CrudAdmin/forms/TargetingField';
import Tooltip from '@material-ui/core/Tooltip';
import _ from 'lodash';
import ax from 'axios';
import dvr from 'mobx-react-form/lib/validators/DVR';
import jsp from '../lib/jsp';
import routes from '../components/Layout/Routes';
import storeMetadata, { domoFields, formColumns, formFields, formTypes, namestringFields, permRequest, showFields, } from './StoreMetadata';
import validatorjs from 'validatorjs';
import { observable, action, computed } from 'mobx';
import { captureException } from '../lib/sentry';
import { ContactSupportOutlined } from '@material-ui/icons';



var moment = require('moment');

const plugins = {
  dvr: dvr(validatorjs),
};

class CrudAdminStore {
  constructor(rootStore, channel, model, name, fake = false) {
    this.channel = channel;
    this.model = model;
    this.rootStore = rootStore;
    this.name = name;
    this.previously_stored_dependent_data = [];
    this.previous_dependent_form_values = [];
    this.fake = fake;
    this.setBelongsTo = this.setBelongsTo.bind(this);
  }

  @observable action = 'index';
  @observable bulkAttributeSelected = false;
  @observable multiselectDisabled = {};
  @observable bulkCrudStores = [];
  @observable bulkProcess = null;
  @observable bulkPhase = null;
  @observable campaigns = [];
  @observable channel = 'Social';
  @observable channels = ['Social'];
  @observable childModel = {};
  @observable columns = [];
  @observable copiedPredraftRecordIds = [];
  @observable copiedPredraftRecords = [];
  @observable copiedToClipboardText = 'Copied to Clipboard';
  @observable copyEditStatus = '';
  @observable copyEl;
  @observable copyState = { setOpen: false, message: '' }
  @observable currentFilterField = [];
  @observable currentPage = 1;
  @observable displayClearFilter = false;
  @observable displayedRow = {};
  @observable displayedRows = [];
  @observable displayError = false;
  @observable endDateValid = true;
  @observable exportEl;
  @observable facebookConnection = null;
  @observable facebookConnectionTestString = null;
  @observable facebookTokenOk = true;
  @observable fetchingDisplayedRows = false;
  @observable fetchingOne = false;
  @observable filter = {};
  @observable filterData = {};
  @observable filters = [];
  @observable first = 1;
  @observable firstPage = true;
  @observable formData = null;
  @observable hasPaidSocialExtraFields = false;
  @observable initialAction = null;
  @observable initialPredraft = false;
  @observable loading = false;
  @observable modalAcknowledgeAction = null;
  @observable modalAcknowledgeLabel = 'Submit';
  @observable modalAcknowledgeMessage = '';
  @observable modalAcknowledgeOpen = false;
  @observable model = 'SocialCampaign';
  @observable modelsForChannel = { Social: ['Campaigns', 'AdSets', 'Ads'], };
  @observable modelTabIndex = 0;
  @observable modelTabName = '';
  @observable namestring = null;
  @observable namestring_list = [];
  @observable number_of_copies = 1;
  @observable numberOfRowsPerPage = 10;
  @observable operationType = '';
  @observable open = false;
  @observable open_copy = false;
  @observable prefetchId = null;
  @observable radioDisabled = {};
  @observable prefetchIds = [];
  @observable selectedRowIds = [];
  @observable selectedRows = [];
  @observable sendDateValid = true;
  @observable short_utm_list = [];
  @observable short_utm_string = null;
  @observable showId = null;
  @observable sort = [];
  @observable sortField = 'id';
  @observable sortOrder = 'desc';
  @observable startDateValid = true;
  @observable storedData = {};
  @observable submitting = false;
  @observable toolbarAction = '';
  @observable totalRecords = 0;
  @observable utmstring_list = [];
  @observable utmstring_string = null;
  @observable validationErrors = [];
  @observable requiredFieldsForCopyEditInvalid = false;


  setValueOperation = (operation) => {
    this.operationType = operation;
  }

  @action.bound
  setDateValidationEdit() {
    //possible
    // this.startDateValid = this.storedData['isStartDateRequired'] && (this.storedData['start_date'] === undefined) ? false : true;
    // this.endDateValid = this.storedData['isEndDateRequired'] && (this.storedData['end_date'] === undefined) ? false : true;
    // this.sendDateValid = this.storedData['isSendDateRequired'] && (this.storedData['send_date'] === undefined) ? false : true;
  }

  @action.bound
  setCopyExportEl = (thing, call_from) => {
    switch (call_from) {
      case 'export':
        this.exportEl = thing;
        this.open = thing ? true : false;
        break;
      case 'copy_edit':
        this.copyEl = thing;
        this.open_copy = thing ? true : false;
        break;
      default:
        break;
    }
  }

  resetState(id) {
    this.copyState = {
      setOpen: id == 'namestring' ? true : false,
      setOpen2: id == 'utmstring' ? true : false,
      setOpen3: id == 'short_utm_string' ? true : false,
    };
  }

  async multipleFormSubmit() {
    this.setServerValidationErrors('');
    this.bulkCrudStores.forEach(function callback(cs, index) {
      cs.form.$('status_id').set(this.form.$('status_id').values());
      if (cs.form.fields.has('marketing_campaign_id')) cs.form.$('marketing_campaign_id').set(cs.storedData.marketing_campaign_id);
      cs.onUpdate(cs.form, this);
    }, this);
    // this.setAction('index');
    // this.bulkCrudStores = [];
    // this.getFilterOptionFields();
    // this.fetchDisplayedRows({ brand_id: this.rootStore.apiStore.currentBrandId });
    // this.fetchTotalRecords({ brand_id: this.rootStore.apiStore.currentBrandId });
  }


  suppressionLabel() {
    return this.storedData.suppression ? 'SupOn' : this.storedData.suppression === false ? 'SupOff' : '';
  }

  @action.bound
  setUtmString(value) {
    this.utmstring_string = value;
  }
  @action.bound
  setShortUtmString(value) {
    this.short_utm_string = value;
  }
  @action.bound
  setNamestring(value) {
    this.namestring = value;
  }

  @action.bound
  setCopyEditStatus(value) {
    this.copyEditStatus = value;
  }

  @action.bound
  setStatus(value) {
    this.status = value;
  }
  @action.bound
  setFirstPage(value) {
    this.firstPage = value;
  }

  @action.bound
  setToolbarAction(value) {
    this.toolbarAction = value;
  }
  @action.bound
  setInitialAction(value) {
    this.initialAction = value;
  }

  @action.bound
  setBulkProcess(value) {
    //possible
    this.bulkProcess = value;
  }
  @action.bound
  setBulkPhase(value) {
    //possible
    this.bulkPhase = value;
  }


  @action.bound
  setNamestringList(id, value) {
    if (id != undefined && value != undefined) {
      this.namestring_list.push({ [id]: value })
    }
  }


  @action.bound
  setUtmstringList(id, value) {
    if (id != undefined && value != undefined) {
      this.utmstring_list.push({ [id]: value });
    }
  }

  @action.bound
  clearStringList() {
    this.namestring_list = [];
    this.utmstring_list = [];
    this.short_utm_list = [];
  }

  @action.bound
  setShortUtmstringList(id, value) {
    if (id != undefined && value != undefined) {
      this.short_utm_list.push({ [id]: value });
    }
  }

  @action.bound
  setCopiedPredraftRecords(records = null) {
    if (this.bulkCrudStores.length == records.length) {
      records.forEach((rec, idx) => {
        this.bulkCrudStores[idx].storeData('copiedFrom', this.bulkCrudStores[idx].storedData['id']);
        this.bulkCrudStores[idx].storeData('namestring_id', rec.namestring_id);
        this.bulkCrudStores[idx].storeData('id', rec.id);
        this.bulkCrudStores[idx].storeData('start_date', rec.start_date);
        this.bulkCrudStores[idx].storeData('end_date', rec.end_date);
        this.bulkCrudStores[idx].storeData('brand', rec.brand);
      }, this)
    }
    this.copiedPredraftRecords = records;
  }

  setDatesForBulkRecords(records = null) {
    return;
    if (this.bulkCrudStores.length == records.length) {
      records.forEach((rec, idx) => {
        this.bulkCrudStores[idx].storeData('namestring_id', rec.namestring_id);
        this.bulkCrudStores[idx].storeData('id', rec.id);
        this.bulkCrudStores[idx].storeData('start_date', rec.start_date);
        this.bulkCrudStores[idx].storeData('end_date', rec.end_date);
        this.bulkCrudStores[idx].storeData('send_date', rec.send_date);
      }, this)
    }
    this.copiedPredraftRecords = records;
  }
  setCopiedPredraftRecordIds(records = null) {
    this.copiedPredraftRecordIds = records?.map(a => a.id);
  }


  @action.bound
  setSort(sort) {
    this.sortField = sort.sortField;
    this.sortOrder = sort.sortOrder == -1 ? 'desc' : 'asc';
  }

  @action.bound
  setDisplayError(value) {
    this.displayError = value;
  }

  @action.bound
  setCurrentPage(page) {
    this.currentPage = page;
  }

  @action.bound
  setDisplayClearFilter() {
    this.displayClearFilter = (!_.isEmpty(this.filterData)) ? true : false;
  }

  @action.bound
  onNew = async () => {
    this.setServerError('');
    this.setStoredData('all');
    this.setServerValidationErrors('');
    this.setAction('new');
    this.rootStore.uiStore.createAnchorEl = null;
    this.rootStore.apiStore.serverValidationErrors = [];
    await this.initForm('new');
  }


  @action.bound
  onBulkCreate = async () => {

    this.setServerError('');
    this.setStoredData('all');
    this.setServerValidationErrors('');
    this.setAction('bulk_create');
    this.setInitialAction('bulk_create');
    this.setFirstPage(true);
    this.rootStore.uiStore.createAnchorEl = null;
    this.rootStore.apiStore.serverValidationErrors = [];
    await this.initForm('bulk_create');
  }

  @action.bound
  onShow() {
    this.setAction('show');
    this.initialAction = 'show';
    this.rootStore.channel.model.setChildModel(this.rootStore.channel.model.children[0])
    this.setShowId(this.selectedRowId);
    this.initShow();
    this.setServerValidationErrors('');
  }


  @action.bound
  onEdit = async (form) => {
    this.setAction('edit');
    this.initialAction = 'edit';
    this.setStoredData('all');
    this.setServerValidationErrors('');
    this.rootStore.apiStore.serverValidationErrors = [];
    await this.initForm('edit');
  }

  renderDirectCopyForm = (e, DialogMessage = 'Copy') => {
    this.setInitialAction(DialogMessage)
    this.setFirstPage(true);
    return (
      this.confirmAction(
        this.onCopy,
        DialogMessage,
        'Confirm',
      )
    );
  };


  renderBulkForm = (e, DialogMessage = 'Copy') => {
    this.setInitialAction(DialogMessage)
    this.setFirstPage(true);
    this.modalAcknowledgeMessage = DialogMessage;
    this.modalAcknowledgeOpen = true;
    this.setNumberOfCopies(1);
  };


  onDirectCopy = () => {
    // console.log(this.number_of_copies);
    // console.log(this.selectedRowIds);
    [...Array(this.number_of_copies)].forEach((copy, copyIndex) => {
      this.setBulkCrudStores(this.selectedRows)
    }, this);
    this.onCopy();
  }

  @action.bound
  onCopyEditSubmit = async (status = 'Inactive') => {
    this.bulkCrudStores.map(bcs => {
      if (status != 'Inactive') {
        bcs.storedData['status'].name = status;
      }
      this.setNamestringList(bcs.getNsObj().nsid, bcs.getNsObj().namestring);
      this.setUtmstringList(bcs.getNsObj().nsid, bcs.getNsObj().utmstring);
      this.setShortUtmstringList(bcs.getNsObj().nsid, bcs.getNsObj().short_utm_string);
      if (!bcs.validateForDomo() || (this.toolbarAction != 'copy_edit' && this.toolbarAction != 'bulk_edit')) return;

      if (bcs.writeForDomo()) {
        bcs.writeNamestring()
      };
    }, this)
  }


  getFinalRowIds = (action, status) => {
    const rowsActions = ['copy', 'bulk_edit', 'copy_edit'];
    if (action == 'copy_edit' && status != 'Predraft') {
      return this.copiedPredraftRecordIds;
    }
    return rowsActions.includes(this.toolbarAction) ? this.selectedRowIds : this.copiedPredraftRecordIds;
  }


  onBulkEditContinue = async (action) => {
    const attribute_to_be_edited = this.storedData['attribute_to_be_edited'];
    const val = this.multipleCheck(attribute_to_be_edited) ?
      _.sortBy(this.bulkCrudStores, item => item.storedData['id']).map(bcs => bcs.storedData[attribute_to_be_edited]) :
      this.storedData[attribute_to_be_edited];
    this.finalBulkStatus = this.getFinalBulkStatus(action, status);

    const url = '/' + this.model.endpoint + '/copy-records.json';
    await ax
      .post(url, { bulkParams: this.bulkParams('bulk_edit', val), validateOnly: true })
      .then((response) => {
        this.setDatesForBulkRecords(response.data);
        this.setFirstPage(false);
        this.getFilterOptionFields();
        // this.actionsAfterContinue(response.data);
      })
      .catch((error) => {
        this.setServerError(error);
        this.setFirstPage(true);
        if (error.response) this.setServerValidationErrors(error.response.data);
      });
  }
  // onCopyEditContinue = () => {
  //   this.setFirstPage(false);
  // }


  @action.bound
  onBulkEditSubmit = async () => {
    if (this.rootStore.channel.adminChannel) return true;
    const ready = this.bulkCrudStores.map(bcs => {
      return bcs.validateForDomo()
    });

    if (ready.every(element => element === true)) {
      this.bulkCrudStores.forEach((bcs, index) => {
        bcs.writeNamestring();
        this.setNamestringList(bcs.getNsObj().nsid, bcs.getNsObj().namestring);
        this.setUtmstringList(bcs.getNsObj().nsid, bcs.getNsObj().utmstring);
        this.setShortUtmstringList(bcs.getNsObj().nsid, bcs.getNsObj().short_utm_string);

      });
      return true;
    } else {

      return false
    };
  }

  bulkParams(action, val) {
    const bulkParams = {
      channel_id: this.rootStore.channel.id,
      end_date: this.storedData['end_date'] == undefined ? null : this.storedData['end_date'],
      group_id: this.rootStore.apiStore.currentGroup.id,
      id: this.getFinalRowIds(action, this.finalBulkStatus),
      is_admin_channel: this.rootStore.channel.adminChannel,
      keep_original_dates: this.storedData['keep_original_dates'] == undefined ? (this.rootStore.channel.adminChannel ? true : false) : this.storedData['keep_original_dates'],
      name: this.storedData['attribute_to_be_edited'],
      namestring_list: this.namestring_list,
      num_of_copies: this.number_of_copies,
      operation_type: this.operationType,
      send_date: this.storedData['send_date'],
      short_utm: this.short_utm_list,
      start_date: this.storedData['start_date'] == undefined ? null : this.storedData['start_date'],
      status: this.finalBulkStatus,
      sub_type: this.rootStore.channel.model.columns.find(c => c.form == this.storedData.attribute_to_be_edited)?.subType,
      toolbarAction: action,
      utm_list: this.utmstring_list,
      value: val
    };
    return bulkParams;
  }

  @action.bound
  onCopy = async (action = 'copy', status = 'Inactive') => {
    const attribute_to_be_edited = this.storedData['attribute_to_be_edited'];
    const val = this.multipleCheck(attribute_to_be_edited) ?
      _.sortBy(this.bulkCrudStores, item => item.storedData['id']).map(bcs => bcs.storedData[attribute_to_be_edited]) :
      this.storedData[attribute_to_be_edited];
    this.finalBulkStatus = this.getFinalBulkStatus(action, status);
    if (attribute_to_be_edited == 'brand_id') {
      this.storedData['brand'] = this.rootStore.apiStore.brands.filter(b => b.id == this.storedData[attribute_to_be_edited]);
    }

    const url = '/' + this.model.endpoint + '/copy-records.json';
    await ax
      .post(url, { bulkParams: this.bulkParams(action, val) })
      .then((response) => {
        this.getFilterOptionFields();
        this.actionsAfterCopy(response.data);
      })
      .catch((error) => {
        this.setServerError(error);
        if (error.response) this.setServerValidationErrors(error.response.data);
      });
  }

  getFinalBulkStatus(action, status) {
    if (action == 'copy') {
      return 'Copy'
    }
    if (action == 'copy_edit' && status == 'Predraft') {
      return 'Predraft';
    }
    return status;
  }


  @action.bound
  actionsAfterCopy(response) {
    if (this.finalBulkStatus != 'Predraft') {
      this.clearServerValidationErrors('');
      this.rootStore.channel.model.requiredData(this.rootStore.apiStore);
      this.clearStringList();
      this.bulkCrudStores = [];
      this.setValueOperation('');
      this.radioDisabled['addRemove'] = false
      this.clearSelection();
      this.fetchDisplayedRows({ brand_id: this.rootStore.apiStore.currentBrandId, });
      this.fetchTotalRecords({ brand_id: this.rootStore.apiStore.currentBrandId, });
      this.displayedRows.map((row) => {
        row['attribute_to_be_edited'] = '';
      });
      this.setNumberOfCopies(1);
      this.setCopiedPredraftRecords([]);
      this.setCopiedPredraftRecordIds(null);
      this.bulkAttributeSelected = false;
    } else {
      this.setCopiedPredraftRecords(response);
      this.setCopiedPredraftRecordIds(response);
      this.bulkAttributeSelected = false;

    }
    this.setFirstPage(false);
  }


  @action.bound
  getFilterOptionFields = async () => {
    const url = '/' + this.model.endpoint + '/filter-options.json';
    // console.log(`ur fo: ${url}`)

    await ax
      .get(url, { params: this.rootStore.channel.model.modelFilter(this.rootStore) })
      .then((response) => {
        this.rootStore.apiStore[this.rootStore.channel.model.filterOptionsCall](response["data"]);
      })
      .catch((error) => {
        this.setServerError(error);
      });
  }

  @action.bound
  setNumberOfCopies(num) {
    this.number_of_copies = num;
  }

  @computed get numberofCopies() {
    return this.number_of_copies;
  }

  goodFormField(thing) {
    return this.form && this.form.fields.has(thing) && !thing.match(/\[\]/g);
  }

  @action.bound
  storeData(thing, selected) {
    const value = this.rootStore.chs.valueInit(selected);
    // console.log('This is StoreData')
    // console.log(this.name);
    // console.log(thing)
    // console.log(value);
    // if (this.name == 'AdSets') debugger;
    // if (thing == 'start_date' && this.name != 'campOptions') debugger;
    if (thing == 'unique_attribute') this.storedData['unique_object'] = value.slice(0, -3);
    if (this.goodFormField(thing)) this.setCrudFormData(thing, value);
    this.setStoredData(thing, value);
    if (value == null) return;
    this.setBelongsTo(thing, value);
    // this allows for callbacks defined in storeMetaData:
    this.fields && this.fields.find(f => f.name == thing) && typeof this.fields.find(f => f.name == thing).callback == 'function' && this.fields.find(f => f.name == thing).callback(this, thing, selected);
    this.setDisplayError(false);
    if (this.rootStore.react) this.rootStore.react.forceUpdate();
  }

  @action.bound
  storePivotValueData(tid, value, dependent = 'targeting') {
    const type = this.rootStore.channel.model.controller + '_' + dependent + 's_attributes';
    const index = this.storedData[type].findIndex(v => v[`${dependent}_id`] == tid);
    // console.log(this.name + ' tid: ' + tid + ' value: ' + value + ' type: ' + type + ' index: ' + index);
    if (!value._destroy) {
      if (index == -1) {
        this.storedData[type].push(value)
        this.bulkCrudStores.forEach(bcs => bcs.storedData[type].push(value))
      } else {
        this.storedData[type][index] = value
        this.bulkCrudStores.forEach(bcs => bcs.storedData[type][index] = value);
      }
    }
    if (this.rootStore.react) this.rootStore.react.forceUpdate();
  }

  deleteStoredDataProperty(thing, children = false) {
    delete this.storedData[thing]
    if (children) {
      this.bulkCrudStores.forEach(bcs => {
        delete bcs.storedData[thing]
      }, thing)
    }
  }

  getNamestring(crudStore, nsFields, adGroups) {
    const nsArr = adGroups
      .map(
        function (a) {
          const cs = this[0];
          const nsFields = this[1];
          cs.setStoredData('all');
          cs.getNsFieldValues('edit', nsFields, a);
          cs.storeInitialFieldsDatum(nsFields);
          cs.setStoredData('paid_search_campaign', a.paid_search_campaign); //shouldnt be necessary..but is
          const ns = _.map(
            _.filter(nsFields, (f) => f.ns_order),
            (f) => f.ns(cs),
          ).join('_');
          return {
            label: ns,
            value: a.id.toString(),
          };
        },
        [crudStore, nsFields],
      );
    return nsArr[0];
  }

  @action.bound
  pushStoreData(thing, item) {
    const items = _.union(this.storedData[thing], [item]);
    this.storeData(thing, items);
  }

  @action.bound
  pullStoreData(thing, item) {
    const items = _.without(this.storedData[thing], item);
    this.storeData(thing, items);
  }
  @action.bound
  setBelongsTo(thing, value) {
    // console.log(this.name);
    // if (thing == 'social_campaign_id') debugger;
    const field = this.rootStore.channel.model.columns.find((b) => b.form == thing);
    if (_.isEmpty(field) || _.isEmpty(field.belongsTo)) return;
    const bt = field.belongsTo;
    if (value.constructor.name == 'Array') {
      let numValue = value.map(val => +val);
      const newArray = this.rootStore.apiStore[bt[1]].map((b) => {
        if (value.includes(b.id) || numValue.includes(b.id)) return b;
      });
      this.setStoredData(bt[0], _.compact(newArray));
    } else {
      const newValue = this.rootStore.apiStore[bt[1]].find((b) => b.id == value);
      this.setStoredData(bt[0], newValue);
      if (!this.model.columns.find((f) => f.name == 'start_date')) return;
      if (!!newValue && field.subType == 'inherit' && ['new', 'bulk_create'].includes(this.initialAction)) {
        // note: forbidden use of jsp in code was removed from this area. 
        if (this.model.columns.find((f) => f.name == 'start_date').rules.includes('required')) {
          this.storeData('start_date', this.laterDate(newValue.start_date, new Date(new Date().setHours(0, 0, 0, 0))));
        } else {
          _.isEmpty(newValue.start_date)
            ? this.storeData('start_date', 0)
            : this.storeData('start_date', moment(newValue.start_date)._d);
        }
        if (this.model.columns.find((f) => f.name == 'end_date').rules.includes('required')) {
          this.storeData('end_date', this.laterDate(newValue.end_date, new Date(new Date().setHours(0, 0, 0, 0))));
        } else {
          _.isEmpty(newValue.end_date)
            ? this.storeData('end_date', 0)
            : this.storeData('end_date', moment(newValue.end_date)._d);
        }
      }
    }
  }

  @action.bound
  setStoredData(thing, value, index) {
    // console.log(this.name);
    // if (thing == 'social_campaign_id') debugger;
    // if (thing == 'end_date') debugger;
    if (thing == 'all') return (this.storedData = {});
    // console.log(this.name, thing, value)
    if (index !== undefined) {
      this.storedData[thing][index] = value;
    } else {
      this.storedData[thing] = value;
    }

    if (_.isEmpty(this.bulkCrudStores) || thing == this.storedData['unique_attribute'] || thing == this.storedData['unique_object']) return;
    // console.log(thing, jsp(value))
    const operationType = this.operationType;
    this.bulkCrudStores.forEach(function callback(cs, i) {
      if (cs.action == 'new') return;
      if (index !== undefined) {
        cs.storedData[thing][index] = value;
      } else {
        if (this[4].multipleCheck(thing)) {
          if (thing != cs.storedData['attribute_to_be_edited'] && this[4].firstPage) return;
          if (operationType == 'add') {
            const current = cs.storedData['attribute_to_be_edited_original_values'] == undefined ? [] : cs.storedData['attribute_to_be_edited_original_values'];
            cs.storeData(thing, [...new Set([...current, ...value])])
          } else if (operationType == 'remove') {
            cs.storeData(thing, _.difference(cs.storedData['attribute_to_be_edited_original_values'], value));
          } else cs.storeData(thing, value);
        } else cs.storeData(thing, value);
      }
    }, [thing, value, index, operationType, this]);

  }

  multipleCheck(thing) {
    const multipleColCheck1 = this.rootStore.channel.model.columns.find(c => c.form == thing);
    const multipleColCheck2 = this.rootStore.channel.model.columns.find(c => c.name == thing);
    let multiple = multipleColCheck1 != undefined && Array.isArray(multipleColCheck1['multiple']);
    multiple = multiple ? multiple : multipleColCheck2 != undefined && Array.isArray(multipleColCheck2['multiple']);
    return multiple;
  }

  @action.bound
  setCrudFormData(thing, value) {
    if (this.submitting) return;
    this.form.$(thing).set(value);
    if (_.isEmpty(this.bulkCrudStores) || thing == this.storedData['unique_attribute'] || thing == this.storedData['unique_object']) return;

    this.bulkCrudStores.forEach(function callback(cs, index) {
      if (cs.form != undefined) cs.form.$(thing).set(value);
    }, [thing, value]);

    return;
  }

  onlyIf = (thing) => {
    return thing == undefined ? [] : thing;
  };

  getBulkCreateDates = () => {
    const parentRows = this.rootStore.channel[this.model.parentModelCode]?.crudStore?.selectedRows || [];
    const start_field = this.model.columns.find(d => d.form == 'start_date');
    const end_field = this.model.columns.find(d => d.form == 'end_date');
    const send_field = this.model.columns.find(d => d.form == 'send_date');
    const fields = [];
    if (start_field) fields.push(['start_date', this.setInitialFieldValue('new', start_field, parentRows[0])])
    if (end_field) fields.push(['end_date', this.setInitialFieldValue('new', end_field, parentRows[0])])
    if (send_field) fields.push(['send_date', this.setInitialFieldValue('new', send_field, parentRows[0])])
    return fields;
  }

  continueBulkCreate = async () => {
    // can this use the stuff at the bottom? 
    this.setFirstPage(false);
    const finalAction = 'new';
    // can this use the setBulkCrudStores?
    this.setBulkCrudStores(this.storedData[this.storedData.unique_attribute]);
    this.bulkCrudStores.forEach(function callback(cs, index) {
      cs.setFirstPage(false);
      let initData = new Map([
        [this.storedData['unique_attribute'], this.storedData[this.storedData['unique_attribute']][index]],
        [this.storedData['unique_attribute'].slice(0, -3), this.storedData[this.storedData['unique_attribute'].slice(0, -3)][index]],
      ].concat(this.getBulkCreateDates()));
      cs.initForm('new', initData);
    }, this);
    this.setToolbarAction('bulk_create');
    this.setAction('new');
  }

  @action.bound
  initShow = async () => {
    const channel = this.channel;
    const model = this.model;
    let fields;
    fields = showFields(model.columns);
    fields = this.getNsFieldValues('edit', fields, this.selectedRow);
    this.storeInitialFieldsDatum(fields);
    this.nsFields = namestringFields(model.columns);
    this.getNsFieldValues('edit', this.nsFields, this.selectedRow);
    this.storeNsFieldsDatum(this.nsFields);
    this.fields = fields;
  };

  @action.bound
  initForm = async (action, initData = new Map()) => {
    let resp = null;
    const editOrCopy = ['edit', 'copy'];
    const copyOrNew = ['new', 'copy'];
    const channel = this.channel;
    const model = this.model;
    const hooks = this.hooks(this);
    const columns = formColumns(this.rootStore.channel.model.columns);
    const row = this.fake ? this.storedData : this.selectedRow;
    if (action == 'bulk_create') {
      this.rootStore.uiStore.createAnchorEl = null;
      await this.rootStore.apiStore.bulkCreatePromiseResults();
    }

    // console.log('initForm for ' + this.name);

    const values = this.getInitialFormValues(action, columns, row);
    const labels = formFields(this.rootStore, 'label');
    const rules = formFields(this.rootStore, 'rules');
    const types = formFields(this.rootStore, 'type');
    let fields = formFields(this.rootStore, 'form');
    let parent_column;
    let finalAction = action;
    this.initialAction = action;

    if (_.isEmpty(this.formData)) {
      parent_column = columns.find((f) => f.subType == 'inherit');
      if (parent_column) {
        this.original_parent_field = parent_column;
        // fields = fields.filter((f) => f.name != parent_column.form);
      }
      this.form = new MobxReactForm({ fields, values, rules, labels, types }, { plugins, hooks });
      this.storeInitialFieldsDatum(values);
      for (let [key, value] of initData) {
        this.storeData(key, value);
      }
      if (copyOrNew.includes(action) && model.send2domo) {
        await this.insertPredraft(this.form);
        finalAction = 'edit';
      }
      this.nsFields = namestringFields(model.columns);
      this.nsFields = this.getNsFieldValues(action, this.nsFields, row);
      // this.getInitialFormValues(action, this.nsFields, this.selectedRow);
      this.storeNsFieldsDatum(this.nsFields);
      if (action == 'copy') {
        return;
      }
    } else {
      this.form = this.formData;
    }
    this.setDatesToNull()
    this.fields = fields;
    this.setAction(finalAction);
    return;
  };

  getInitialFormValues(action, columns, row) {
    const values = {}
    // continue here!!!
    columns.forEach((column, index) => {
      const value = this.setInitialFieldValue(action, column, row);
      // fields[index] = field;
      values[column.name] = value;
    }, this);
    return values;
  };

  @action.bound
  setDatesToNull() {
    if (this.form.fields.has('end_date') && this.form.$('end_date').value == 0)
      this.form.$('end_date').set(null);
    if (
      this.form.fields.has('start_date') &&
      this.form.$('start_date').value == 0
    )
      this.form.$('start_date').set(null);

    if (
      this.form.fields.has('send_date') &&
      this.form.$('send_date').value == 0
    ) {
      this.form.$('send_date').set(null);
    }
  }

  @action.bound
  getNsFieldValues(action, fields, row) {
    fields.forEach((field, index) => {
      if (!this.storedData[field.name]) {
        field.value = this.setInitialFieldValue(action, field, row);
      } else {
        field.value = this.storedData[field.name];
      }
      fields[index] = field;
    }, this);
    return fields;
  };



  @action.bound
  setInitialFieldValue(action, field, row) {
    var moment = require('moment');
    const editOrCopy = ['edit', 'copy'];
    const copyOrNew = ['new', 'copy', 'bulk_create'];

    const pm = this.model.parentModel;
    const gpm = this.model.grandParentModel;
    const parentModel = this.rootStore.channel[this.model.parentModelCode];
    const parentModelUndefined = typeof parentModel == 'undefined';
    const requested = 6;
    const active = 3;
    // console.log('cs name: ', this.name)
    // console.log('--------------------------', field.utaType);
    // console.log(field.name);
    // console.log(this.action + ' -- vs -- ' + action);
    // do not commit with these uncommented:
    // console.log(jsp(field), jsp(row));
    // if (row && row[field.name]) console.log(jsp(row[field.name]));
    // if (field.name == 'start_date' && this.name != 'campOptions') debugger;

    switch (field.utaType) {
      // yeah, utaTypes are the 'magic' in the UCC. 
      // they let us provide custom rules for different kinds of data used in the UCC. 
      // in theory the rest of the UCC should be just javascript and ruby 
      // .. that you don't need to know anything ucc specific to understand. 
      case 'group':
        if (field.form == 'group_id')
          return this.rootStore.apiStore.currentUser.group.id;
        return this.rootStore.apiStore.currentUser.group;
      case 'array':
        return field.default ?
          typeof field.default == 'function' ?
            field.default(this.rootStore) :
            field.default :
          editOrCopy.includes(action) ?
            row[field.name] :
            [];

        return editOrCopy.includes(action) ? row[field.name] : [];
      case 'boolean':
        return editOrCopy.includes(action) ? row[field.name] : false;
      case 'brand':
        if ([field.name, field.form].includes('brand_id'))
          return this.rootStore.apiStore.currentBrand.id;
        return this.rootStore.apiStore.currentBrand;
      case 'channel':
        const keyFilter = ({ abbrev, codename, endpoint, icon, name }) => ({
          abbrev,
          codename,
          endpoint,
          icon,
          name,
        });
        return keyFilter(this.rootStore.channel); //dont store circular channel.
      case 'channel_id':
        return field.default;
      case 'contrived':
        return '';
      case 'date':
        if (row && row[field.name] == null) return 0;
        // console.log(`setInitialFieldValue for date: ${field.name}: ${field.subType} `);
        if (editOrCopy.includes(action)) {
          const d = moment(row[field.name]).format('MM/DD/YYYY').toString();
          this.storeData('initial_' + field.name, new Date(d));
          return new Date(d);
        } else if (field.value && field.value.constructor.name == 'Function') {
          return field.value();
        } else {
          const d = new Date(new Date().setHours(0, 0, 0, 0));
          switch (field.subType) {
            case 'current':
              return new Date(new Date().setHours(0, 0, 0, 0));
            case 'curherit':
              if (parentModelUndefined) return d;
              if (_.isEmpty(parentModel.crudStore.selectedRow)) return d;
              const parentDate = parentModel.crudStore.selectedRow[field.name];
              if (_.isEmpty(parentDate)) return d;
              return this.laterDate(d, parentDate);
            case 'blankherit':
              if (parentModelUndefined) return 0;
              if (_.isEmpty(parentModel.crudStore.selectedRow)) return 0;
              const parentDate2 = parentModel.crudStore.selectedRow[field.name];
              return _.isEmpty(parentDate2) ? 0 : moment(parentDate2)._d;
            default:
              return 0;
          }
        }
        return 0;
      case 'fk':
        const yesterday = new Date(new Date().setHours(-24, 0, 0, 0));
        const slice = field.name.slice(0, -3);
        if (editOrCopy.includes(action)) {
          return !!row[slice] ? row[slice].id : '';
        }
        if (field.subType == 'inherit') {
          if (parentModelUndefined) return '';
          if (_.isEmpty(parentModel.crudStore.selectedRow)) return '';
          if (_.isEmpty(this.model.columns.find(c => c.form == 'end_date'))) {
            return parentModel.crudStore.selectedRow.id;
          }
          if (_.isEmpty(parentModel.crudStore.selectedRow.start_date) || _.isEmpty(parentModel.crudStore.selectedRow.end_date)) {
            return parentModel.crudStore.selectedRow.id;
          }

          return (
            !_.isEmpty(parentModel.crudStore.selectedRow.end_date) &&
            this.laterDate(
              yesterday.toDateString(),
              parentModel.crudStore.selectedRow.end_date
            ).toDateString() != yesterday.toDateString()
          ) ?
            parentModel.crudStore.selectedRow.id :
            '';
        } else {
          return field.default ? typeof field.default == 'function' ? field.default(this.rootStore) : field.default : '';
        }
      case 'grandParent':
        return editOrCopy.includes(action) ? row[pm][gpm][field.name] : '';
      case 'id':
        return row && action != 'new' ? row.id : '';
      case 'name':
        // if (action == 'copy') return 'copy --- ' + row[field.name];
        if (action == 'edit') return row[field.name];
        return '';
      case 'namestring_id':
        if (action == 'new') {
          if (this.rootStore.apiStore.empty(this.storedData.namestring_id)) {
            alert('there may be a problem, the page will reload. try again in a sec');
            this.rootStore.apiStore.reloadPage();
          } else {
            return this?.storedData?.namestring_id;
          }
        } else if (action == 'bulk_create') {
          return '';
        }
        return row[field.name];
      case 'parent':
        return editOrCopy.includes(action) ? row[pm][field.name] : '';
      case 'status':
        if (this.model.codename.includes('Admin')) {
          return permRequest(this.rootStore) ? requested : copyOrNew.includes(action) ? 3 : row["status"].id;
        }
        return copyOrNew.includes(action) ? 1 : row["status"].id;
      case 'targeting':
        if (editOrCopy.includes(action)) {
          const hmtTable = `${this.rootStore.channel.model.controller} _targetings`;
          const targets =
            row[hmtTable] == undefined
              ? row[pm][pm + '_targetings']
              : row[hmtTable]; // either local or from parent supported
          // const targeting = row[pm]['social_ad_set_targetings']
          const targeting = targets.find((f) => 'targeting_ids_' + f.targeting_id.toString() == field.name);
          return targeting ? targeting.extra : '';
        } else return '';

      case 'targeting_array':
        if (copyOrNew.includes(action)) return [];
        const deets = row[field.name.slice(0, -11)].map(targ => Object.assign(targ, { _destroy: false, changed: false, on: true }));
        return deets;
        if (_.isEmpty(field.fields)) return [];
        return [{}].concat(field.fields)

        //inaccessible on purpose for now:
        return result;
        return row[field.name];
        return field.default ?
          typeof field.default == 'function' ?
            field.default(this.rootStore) :
            field.default :
          editOrCopy.includes(action) ?
            row[field.name] :
            [];

      case 'user':
        return this.rootStore.apiStore.currentUser;
      case 'string':
      case 'integer':
      case 'text':
        return editOrCopy.includes(action) ? row[field.name] : '';
      case undefined:
        return editOrCopy.includes(action) ? row[field.name] : '';
      case 'platforms':
        return editOrCopy.includes(action) ? row[field.name] : field.default ? typeof field.default == 'function' ? field.default(this.rootStore) : field.default : '';
      default:
        return editOrCopy.includes(action) ?
          row[field.name] :
          this.rootStore.apiStore[field.utaType].find(g => g.id == field.default)
    }
  }

  @action.bound
  storeInitialFieldsDatum = (fields) => {
    this.storingInitialFieldsDatum = true;
    for (const [key, value] of Object.entries(fields)) {
      this.storeData(key, value);
    }
    this.storingInitialFieldsDatum = false;
  };

  @action.bound
  storeNsFieldsDatum = (fields) => {
    this.storingInitialFieldsDatum = true;
    fields.forEach((field, index) => {
      field.value = this.storeInitialData(field);
    }, this);
    this.storingInitialFieldsDatum = false;
  };

  @action.bound
  storeInitialData = (field) => {
    switch (field.utaType) {
      case 'id':
        if (!this.rootStore.apiStore.empty(this.storedData['id'])) return;
      case 'group':
      case 'array':
      case 'boolean':
      case 'brand':
      case 'date':
      case 'integer':
      case 'name':
      case 'status':
      case 'string':
      default:
        return this.storeData(field.name, field.value);
    }
  };

  setFinalFormValues = (form) => {
    const contrived = this.rootStore.channel.model.columns.filter(f => _.isFunction(f.contrivance));
    contrived.forEach(b => form.$(b.form).value = b.contrivance(this))
    return form;
  }

  clearObjectiveModifier(cs, thing, selected) {
    this.storedData.objective_modifier = {};
    this.storeData('objective_modifier_id', '');
  }

  clearObjective(cs, thing, selected) {
    this.storedData.objective = {};
    this.storeData('objective_id', '');
  }

  clearTestGroup(cs, thing, selected) {
    this.storedData.test_group = {};
    this.storeData('test_group_id', '');
  }

  @action.bound
  formatDates(form) {
    if (form.fields.has('start_date') && !_.isEmpty(form.$('start_date').value))
      form.$('start_date').value = new Date(Date.parse(form.$('start_date').value)).toISOString().slice(0, 10);
    if (form.fields.has('end_date') && !_.isEmpty(form.$('end_date').value))
      form.$('end_date').value = new Date(Date.parse(form.$('end_date').value)).toISOString().slice(0, 10);
    if (form.fields.has('send_date') && !_.isEmpty(form.$('send_date').value))
      form.$('send_date').value = new Date(Date.parse(form.$('send_date').value)).toISOString().slice(0, 10);
    return form;
  }

  @action.bound
  formatPredraftDates(form) {
    if (form.fields.has('start_date') && form.$('start_date').value == 0)
      form.$('start_date').value = null;
    if (form.fields.has('end_date') && form.$('end_date').value == 0)
      form.$('end_date').value = null;
    if (form.fields.has('send_date') && form.$('send_date').value == 0)
      form.$('send_date').value = null;
    return form;
  }

  @action.bound
  async insertPredraft(form) {
    const url = '/' + this.rootStore.channel.model.endpoint + '.json';
    const controller = this.rootStore.channel.model.controller;

    form = this.formatPredraftDates(form);
    if (!!this.prefetchId) form.$('id').set('value', +this.prefetchId + 1);
    this.rootStore.apiStore.serverValidationErrors = [];
    const values = form.values();
    delete values['id'];
    return ax
      .post(url, { [controller]: values })
      .then((response) => {
        // console.log(`response: ${response.data.id}, namestring: ${response.data.namestring_id}, ${this.name} preeeeeeeeeeeeeeeeeeeedraft`);
        this.prefetchId = response.data.id;
        form.$('id').set('value', response.data.id);
        this.storeData('namestring_id', response.data.namestring_id);
        this.storeData('id', response.data.id);
      })
      .catch((error) => {
        console.log(`server request error: ${error} `);
        this.rootStore.apiStore.setServerRequestError(error);
        if (error.response) this.setServerValidationErrors(error.response.data);
      });
  }

  @action.bound
  setCurrentFilterField(field = null) {
    this.currentFilterField = field;
  }

  @action.bound
  setCurrentSortField(field = null) {
    this.currentSortField = field;
  }

  @action.bound
  onCreate(form) {
    form = this.formatDates(form);
    const url = '/' + this.rootStore.channel.model.endpoint + '.json';
    const controller = this.rootStore.channel.model.controller;
    this.rootStore.apiStore.serverValidationErrors = [];
    // console.log(`ur create: ${url}`)

    ax.post(url, { [controller]: form.values() })
      .then((response) => {
        this.serverValidationErrors = [];
        this.clearValidationError();
        this.setFormData();
        this.setStoredData('all');
        this.original_id_field = null;
        this.original_parent_field = null;
        this.fetchDisplayedRows({ brand_id: this.rootStore.apiStore.currentBrandId, });
        this.fetchTotalRecords({ brand_id: this.rootStore.apiStore.currentBrandId, });
        this.setAction('index');
      })
      .catch((error) => {
        this.setServerError(error);
        this.setServerValidationErrors(error.response.data);
      });
  }

  @action.bound
  validateForDomo() {

    if (!this.model.send2domo) return true;

    this.nsObj = this.getNsObj(this.formData);
    // this.nsObj = 'domo error'

    if (this.nsObj == 'domo error' || !_.isEmpty(this.nsObj['message'])) {
      const msg = 'Data meant to be sent to Domo was found missing, so this was not created. You may need to reload this page and try again, or if the problem persists, contact your Merge World  associate'
      const cs = _.endsWith(this.name, 'childStore') ? this.rootStore.channel.model.crudStore : this;
      cs.setServerValidationErrors([msg]);
      return false;
    }
    //possible
    this.validForDomo = true;
    return true;
  }

  @action.bound
  writeForDomo() {
    return this.rootStore.channel.model.send2domo;
    // &&
    // (_.isEmpty(this.formData) ?
    //   this.action == 'edit' :
    //   this.formData.$('status_id').value == '4')

  }

  @action.bound
  async onUpdate(form, mainCrud = '') {
    form = this.formatDates(form);
    form = this.formatPredraftDates(form);
    form = this.setFinalFormValues(form);
    this.setFormData(form);
    const id = this.form.fields.has('id')
      ? form.$('id').value
      : this.selectedRowId;

    if (!this.validateForDomo()) return;
    // const id = !!form.$('id').value ? form.$('id').value : this.selectedRowId;
    const url = '/' + `${this.rootStore.channel.model.endpoint}/${id}.json`;
    const controller = this.rootStore.channel.model.controller;
    this.rootStore.apiStore.serverValidationErrors = [];
    await ax.put(url, { [controller]: form.values() })
      .then((response) => {
        if (this.writeForDomo()) this.writeNamestring();
        this.setFirstPage(false);
        // this.clearAfterUpdate(this);
        this.clearAfterUpdate(!!!mainCrud ? this : mainCrud);

      })
      .catch((error) => {
        console.log('onUpdate hit an error');
        console.log(error);
        this.setServerError(error);
        if (error.response) { !!!mainCrud ? this.setServerValidationErrors(error.response.data) : mainCrud.setServerValidationErrors(error.response.data); }
        // if (error.response) this.setServerValidationErrors(error.response.data);
      });
  }

  @action.bound
  clearAfterUpdate(cs) {
    cs.serverValidationErrors = [];
    cs.submitting = false;
    cs.clearValidationError();
    // this.clearFilters();
    cs.setStoredData('all');
    cs.bulkCrudStores = [];
    cs.setFormData();
    cs.setShowId();
    cs.setValueOperation('');
    cs.bulkCrudStores = [];
    cs.radioDisabled['addRemove'] = false;

    if (!cs.rootStore.apiStore.empty(cs.rootStore.channel.model.updateCallback))
      cs.rootStore.channel.model.updateCallback(cs.rootStore);
    cs.original_id_field = null;
    cs.original_parent_field = null;
    cs.getFilterOptionFields();
    cs.fetchDisplayedRows({ brand_id: cs.rootStore.apiStore.currentBrandId });
    cs.fetchTotalRecords({ brand_id: cs.rootStore.apiStore.currentBrandId });
    cs.displayedRows.map((row) => {
      row['attribute_to_be_edited'] = '';
    });
    cs.setAction('index');
    cs.setToolbarAction('');
    cs.previously_stored_dependent_data = [];
    cs.previous_dependent_form_values = [];
    cs.rootStore.apiStore.adsetTabDisabled = false;
    if (cs.channel.adminChannel) {
      cs.rootStore.apiStore.brandsChannelsMap = [];
      cs.rootStore.apiStore.brandChannels = [];
    }
  }

  asyncForEach = async (array, callback) => {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  };

  onArchive = async () => {
    const url = '/' + this.model.endpoint + '/archives.json';
    await ax
      .post(url, { ids: this.selectedRowIds })
      .then((response) => {
        this.clearSelection();
        // this.model.requiredData(this.apiStore);

        this.fetchDisplayedRows({ brand_id: this.rootStore.apiStore.currentBrandId, });
        this.fetchTotalRecords({ brand_id: this.rootStore.apiStore.currentBrandId, });
      })
      .catch((error) => {
        this.setServerError(error);
        if (error.response) this.setServerValidationErrors(error.response.data);
      });
  }

  onUnarchive = async () => {
    const url = '/' + this.model.endpoint + '/unarchives.json';
    await ax
      .post(url, { ids: this.selectedRowIds })
      .then((response) => {
        this.clearSelection();
        this.fetchDisplayedRows({ brand_id: this.rootStore.apiStore.currentBrandId, });
        this.fetchTotalRecords({ brand_id: this.rootStore.apiStore.currentBrandId, });
      })
      .catch((error) => {
        this.setServerError(error);
        if (error.response) this.setServerValidationErrors(error.response.data);
      });
  }


  onDelete = async (form) => {
    await this.asyncForEach(this.selectedRowIds, async (row) => {
      const url = `/${this.rootStore.channel.model.endpoint}/${row}.json`;
      await ax
        .delete(url)
        .then((response) => {
          this.serverValidationErrors = [];
          this.getFilterOptionFields();
        })
        .catch((error) => {
          this.setServerError(error);
        });
    });
    // this.selectedRowIds = [];
    this.clearSelection()
    this.resetTabs();
    this.fetchDisplayedRows({ brand_id: this.rootStore.apiStore.currentBrandId });
    this.fetchTotalRecords({ brand_id: this.rootStore.apiStore.currentBrandId, });
    this.rootStore.channel.model.requiredData(this.rootStore.apiStore);
    this.setAction('index');
  };

  @action.bound
  onCancel() {
    const model = this.rootStore.channel.models.find(m => m.name == this.model.name);
    this.setFormData();
    this.original_id_field = null;
    this.original_parent_field = null;
    this.form = null;
    this.fields = null;
    this.setAction('index');
    this.validatesRequiredFieldsForCopyEdit(false);
    this.storedData['attribute_to_be_edited'] = null;
    this.setValueOperation('');
    this.radioDisabled = {};
    this.multiselectDisabled = {};
    this.bulkCrudStores = [];
    this.clearValidationError();
    this.serverValidationErrors = [];
    this.setToolbarAction('');
    this.previously_stored_dependent_data = [];
    this.previous_dependent_form_values = [];
    if (this.rootStore.channel.adminChannel) {
      this.rootStore.uiStore.router.goTo(
        routes.adminChannelTabs,
        {
          channel: this.rootStore.channel.endpoint,
          model: model.route,
        },
        this.rootStore.channel[model.codename].crudStore,
      );
    } else {
      this.rootStore.uiStore.router.goTo(
        routes.channelTabs,
        {
          channel: this.rootStore.channel.endpoint,
          model: model.route,
          brand_path: this.brand.path
        },
        this.rootStore.channel[model.codename].crudStore,
      );
    }
  }

  @action.bound
  writeNamestring() {
    const url = '/namestrings.json';
    const controller = 'namestring';
    // console.log(`ur ns: ${url}`)
    // console.log(this.nsObj);
    ax.post(url, { [controller]: this.nsObj })
      .then((response) => {
        this.serverValidationErrors = [];
        this.clearValidationError();
      })
      .catch((error) => {
        this.setServerError(error);
        this.setServerValidationErrors(error?.response?.data);
      });
  }

  @action.bound
  selectRow(row) {
    this.selectedRowIds[row.id] = true;
  }

  @action.bound
  resetTabs() {
    // Todo move this code to model:  UTA-1031 
    switch (this.channel.name) {
      case 'Paid Social':
        if (this.codename == 'SocCamp') {
          this.rootStore.paidSocial.SocAdSet.crudStore.clearSelection();
          this.rootStore.paidSocial.SocAd.crudStore.clearSelection();
        } else if (this.codename == 'SocAdSet') {
          this.rootStore.paidSocial.SocAd.crudStore.clearSelection();
        }
        break;
      case 'Paid Search':
        if (this.codename == 'PaidSearchCamp') {
          this.rootStore.paidSearch.PaidSearchAdGroup.crudStore.clearSelection();
          this.rootStore.paidSearch.PaidSearchAdName.crudStore.clearSelection();
        } else if (this.codename == 'PaidSearchAdGroup') {
          this.rootStore.paidSearch.PaidSearchAdName.crudStore.clearSelection();
        }
        break;
      default:
    }

    // code to reinstantiate channels was here but thought not needed. see git.
  }

  @action.bound

  toggleRowSelect(row) {
    if (this.storedData['isChild']) {
      this.storeData('isChild', false)
      this.setShowId();
      this.channel.models.forEach(m => {
        if (m.crudStore) {
          m.crudStore.selectedRowIds = m.crudStore.selectedRows = [];
          m.crudStore.filterData = {};
        }
      });
      // this.selectedRows = this.selectedRowIds = [];
      const model = this.rootStore.channel.models.find(m => m.name == this.model.name);
      this.rootStore.channel.models.find(m => m.name == this.model.name).setChildModel(model.children[0]);
      this.rootStore.channel.models.forEach(m => {
        m.crudStore.displayedRows = [];
        m.crudStore.selectedRowIds = [];
        m.crudStore.selectedRows = [];
      });
      if (this.channel.adminChannel) {

        this.rootStore.uiStore.router.goTo(
          routes.adminChannelModelShowTabs,
          {
            channel: this.channel.endpoint,
            model: model.endpoint,
            id: row.value[0].id
          },
          this,
        );
      }
      return;
    }

    let newSelected = [];
    newSelected = _.compact(row.value);
    this.resetTabs();
    this.selectedRows = newSelected;
    this.selectedRowIds = this.selectedRows.map((id) => id.id);
  }

  @action.bound
  getDisplayedRow(id) {
    return this.displayedRows.filter((r) => r.id == id)[0];
  }

  @computed get brand() {
    return this.rootStore.apiStore.currentBrand;
  }

  @computed get title() {
    return this.model.name;
  }

  @computed get selectedRow() {
    if (this.selectedRowIds.length !== 1) {
      return null;
    }
    const rowId = this.selectedRowIds[0];
    const result = _.find(this.displayedRows, (obj) => obj.id === rowId);
    return result;
  }

  @computed get selectedRowId() {
    return this.selectedRow ? this.selectedRow.id : null;
  }

  @action.bound
  setChildModel(childModel) {
    this.childModel = childModel;
  }
  @action.bound
  confirmAction(action, message, buttonLabel) {
    this.modalAcknowledgeMessage = message;
    this.modalAcknowledgeLabel = buttonLabel;
    this.modalAcknowledgeAction = () => {
      this.modalAcknowledgeOpen = false;
      return action();
    };
    this.modalAcknowledgeOpen = true;
    this.setNumberOfCopies(1);
  }

  @action.bound
  resetTabIndex(index) {
    this.model.tabIndex = index;
  }
  @action.bound
  setCampaignTypes(event) {
    this.storeData('campaign_type_id', event);
    const objective_id = this.storedData['objective_id'];
    const chLookFkeys = this.rootStore.apiStore.campaignTypeObjectives.filter(look => look.type_of == "CampaignType" && look.f_key == objective_id).map(look => look.value);
    const isCampaignPresent = chLookFkeys.includes(this.storedData['campaign_type_id']);
    if (!isCampaignPresent && !this.initialAction == 'bulk_create') {
      this.storeData('objective_id', '');
    }
  }

  @action.bound
  cancelAction() {
    this.modalAcknowledgeOpen = false;
    this.modalAcknowledgeMessage = '';
    this.modelAcknowledgeLabel = '';
    this.modalAcknowledgeAction = null;
    this.bulkCrudStores = [];
    this.clearValidationError();
    this.validatesRequiredFieldsForCopyEdit(false);
    const attribute_to_be_edited = this.storedData['attribute_to_be_edited'];
    this.storedData[attribute_to_be_edited] = undefined;
    this.storedData['attribute_to_be_edited'] = null;
    this.storedData['start_date'] = undefined;
    this.storedData['end_date'] = undefined;
    this.storedData['send_date'] = undefined;
    this.storedData['keep_original_dates'] = undefined;
    this.radioDisabled = {};
    this.setValueOperation('');
    this.multiselectDisabled = {};
    this.startDateValid = true;
    this.endDateValid = true;
    this.sendDateValid = true;
    this.bulkAttributeSelected = false;
    this.setDisplayError(false);
  }

  @action.bound
  selectAll() {
    if (this.selectedRowIds.length === this.displayedRows.length) {
      this.clearSelection();
    } else {
      this.selectedRowIds = this.displayedRows.map((row) => row.id);
    }
  }

  @action.bound
  clearSelection() {
    this.selectedRowIds = [];
    this.selectedRows = [];
  }

  @action.bound
  testFacebook(brand_id) {
    // todo: show messages inline instead of in alert.
    // if (this.channel.name != 'Paid Social' && this.model.name != 'Campaigns') return true;
    const url = '/' + this.model.endpoint + '/fbtest.json?brand_id=' + brand_id;

    ax.get(url)
      .then((response) => {
        if (response?.data?.error) {
          this.facebookTokenOk = false;
          alert('Error: ' + response.data.error?.message);
        } else {
          alert('Looks good');
          this.facebookTokenOk = true;
        }
      }).catch((error) => {
        this.facebookTokenOk = false;
      })
  }

  @action.bound
  fetchDisplayedRows(
    filter = {},
    page = this.currentPage,
    per = this.numberOfRowsPerPage,
    column = this.sortField,
    direction = this.sortOrder,
  ) {
    // console.log('fetchDisplayedRows', page);
    const pageFilter = {
      page: page,
      per: per,
      sort: column,
      direction: direction
    };
    this.setFetchingDisplayedRows(true);
    const allFilter = _.merge(
      filter,
      this.model.modelFilter(this.rootStore),
      pageFilter,
      this.filterData,
    );

    const url = '/' + this.model.endpoint + '.json';

    ax.get(url, {
      params: allFilter,
    })
      .then((response) => {
        // console.log('response to fetchDisplayedRows');
        // console.log(response.data);
        this.setDisplayedRows(response.data);
        this.setFetchingDisplayedRows(false);
        if (this.rootStore.apiStore.notEmpty(this.showId)) {
          const row = this.displayedRows.find(r => this.rootStore.apiStore.notEmpty(r.id) && r.id.toString() == this.showId);
          const toggleRow = { value: [row] };
          if (!this.selectedRows.map(b => b.id).includes(row.id) && !this.storedData['isChild']) {
            // this might be better with this.toggleRowSelect(row);
            this.selectedRows.push(row);
            this.selectedRowIds.push(row.id);
          }
        }


        this.selectedRows.forEach((sr, index) => {
          if (!_.isEmpty(this.displayedRows.filter(dr => dr.id == sr.id))) {
            this.selectedRows[index] = this.displayedRows.find(dr => dr.id == sr.id)
          }
        }, this);
      })
      .catch((error) => {
        captureException(error)
        this.serverError = error;
        this.setFetchingDisplayedRows(false);
        this.refetchDisplayedRows = allFilter;
      });
  }

  @action.bound
  setFetchingDisplayedRows(value) {
    // this.clearSelection();
    this.fetchingDisplayedRows = value;
  }

  @action.bound
  setDisplayedRows(displayedRows = null) {
    //console.log('setting displayedRows');
    //console.log(displayedRows);
    this.displayedRows = displayedRows;
    this.refetchDisplayedRows = false;
  }

  @action.bound
  setDisplayedRow(displayedRow = null) {
    this.displayedRow = displayedRow;
    this.refetchDisplayedRow = false;
  }

  @action.bound
  setFetchingOne(fetchingOne) {
    this.fetchingOne = fetchingOne;
  }

  @action.bound
  fetchOne() {
    this.setFetchingOne(true);
    const brand_param = this?.brand?.id > 0 ? `brand_id=${this.brand.id}` : '';
    const url = '/' + this.model.endpoint + '/' + this.showId + '.json?' + brand_param;
    // console.log(`ur fo: ${url} `)

    ax.get(url, {})
      .then((response) => {
        this.setDisplayedRows([response.data]);
        this.selectedRows = [response.data];
        this.selectedRowIds = [response.data.id];
        this.setFetchingOne(false);
      })
      .catch((error) => {
        captureException(error)
        this.serverError = error;
        this.setFetchingDisplayedRow(false);
        this.refetchDisplayedRows = true;
      });
  }

  @action.bound
  setFirst(first) {
    this.first = first;
  }

  @action.bound
  setShowId(showId = null) {
    this.showId = showId
  }

  setFilter(newFilter) {
    this.filterData[newFilter] = this.storedData['filtered_' + newFilter];
    this.setCurrentFilterField(newFilter);
  }

  clearFilter(newFilter) {
    delete this.filterData[newFilter];
    this.setCurrentFilterField();
  }

  clearFilters() {
    let filtered_keys = (obj, filter) => {
      let key, keys = []
      for (key in obj)
        if (obj.hasOwnProperty(key) && filter.test(key))
          keys.push(key)
      return keys
    }

    filtered_keys(this.storedData, /filtered_/).forEach(filter => delete this.storedData[filter]);
    this.filterData = {};
    // this.forceUpdate()
  }

  @action.bound
  onClearFilters() {
    this.clearFilters();
    this.setDisplayClearFilter();
    this.fetchDisplayedRows();
    this.fetchTotalRecords();
  }

  @action.bound
  setFilterOptions(type, list, all, filterOptionType, filterName = 'name') {
    let all_ids;
    /*console.log('crudStore setFilterOptions');
    console.log(type);
    console.log(JSON.parse(JSON.stringify(list)));
    console.log(JSON.parse(JSON.stringify(all)));
    console.log(filterOptionType);*/
    if (_.isEmpty(list)) return;
    if (_.isEmpty(all)) return;
    if (filterOptionType == 'hardcoded') return list;
    if (filterOptionType == 'hmt') {
      const tmp = all[type]
      all_ids = [].concat(...tmp)
    } else {
      if (!all[type]) return;
      all_ids = all[type];
    }
    //console.log(all_ids);
    // console.log(`type: ${ type }, filterOptionType: ${ filterOptionType }, name: ${ name } `)
    const filtered = list.filter((l) => l?.id ? all_ids.includes(l.id) : all_ids.includes(l));
    //console.log(filtered);
    if (typeof list[0] == "object" && !!list[0]) {
      return filtered.map((f) => ({
        value: f.id,
        label: (typeof filterName == 'function') ? filterName(f.id, f.name) : f[filterName]
      }));
    } else {
      return filtered.filter(f => !!f)
    }
  }

  @action.bound
  setHardFilterOptions(type, list, all) {
    if (_.isEmpty(list)) return;
    if (_.isEmpty(all)) return;
    const all_ids = _.uniq(all.map((a) => a[type]));
    const filtered = list.filter((l) => all_ids.includes(l.id));
    // return filtered.map(f => ({ value: f.id, label: f.name }));
    return list;
  }

  @action.bound
  fetchTotalRecords(filter = {}) {
    this.setFetchingTotalRecords(true);
    const allFilter = _.merge(
      filter,
      this.model.modelFilter(this.rootStore),
      this.filterData,
    );
    const url = '/' + this.model.endpoint + '/total.json';
    // console.log(`ur tot: ${ url } `)

    ax.get(url, {
      params: allFilter,
    })
      .then((response) => {
        this.setTotalRecords(response.data);
        this.setFetchingTotalRecords(false);
      })
      .catch((error) => {
        captureException(error);
        this.serverError = error;
        this.setFetchingTotalRecords(false);
        this.refetchTotalRecords = allFilter;
      });
  }

  @action.bound
  setFetchingTotalRecords(value) {
    this.fetchingTotalRecords = value;
  }

  @action.bound
  setTotalRecords(totalRecords = null) {
    this.totalRecords = totalRecords;
    this.refetchTotalRecords = false;
  }

  @action.bound
  setFormData(formData = null) {
    this.formData = formData;
  }

  @action.bound
  setAction(newAction) {
    this.action = newAction;
  }

  @action.bound
  clear() {
    this.rows = [];
    this.columns = [];
  }

  @action.bound
  clearValidationError() {
    this.validationErrors = [];
  }

  @action.bound
  validatesRequiredFieldsForCopyEdit(value) {
    if (this.rootStore.channel.adminChannel) {
      this.requiredFieldsForCopyEditInvalid = false;
      return;
    }
    this.requiredFieldsForCopyEditInvalid = value;
  }

  @action.bound
  setServerValidationErrors(messages) {
    const mm = [];
    if (Array.isArray(messages)) {
      if (messages.includes('The ID field is required.')) {
        messages.unshift('An error has occured, please refresh your page and try again');
      }
      messages.forEach(m => {
        if (Array.isArray(m)) {
          m.forEach(t => {
            if (!_.isEmpty(t.extra)) mm.push(t.extra)
          })
        } else if (typeof m == 'string' && m.indexOf('integer') == -1) mm.push(m);
      });
    }
    this.validationErrors = mm;
  }

  @action.bound
  setServerError(error = null) {
    this.serverError = error;
    if (error) {
      this.rootStore.apiStore.handleServerError(error);
    }
  }

  @action.bound
  clearServerValidationErrors() {
    this.serverError = null;
    this.rootStore.apiStore.serverValidationErrors = [];
  }

  because = (na) => {
    return true;
  };

  hooks = (crudStore) => {
    return {
      onSuccess(form) {
        // console.log('onSuccess hook');
        // console.log('action', crudStore.action, 'initialAction', crudStore.initialAction);
        if (crudStore.action === 'new' || crudStore.action === 'copy') {
          // console.log('action is new or copy');
          if (crudStore.initialAction === 'bulk_create') {
            crudStore.multipleFormSubmit();
          } else {
            // console.log('initial action is not bulkCreate');
            // console.log(form);
            crudStore.onCreate(form);
          }
        } else {
          crudStore.onUpdate(form);
        }
      },
      onError(form) {
        console.log('onError hook');
        console.log(form.$('id').value);
        console.log(form.errors());
        crudStore.setServerValidationErrors(
          Object.values(form.errors()).filter(Boolean),
        );
      },
    };
  };

  validationErrorsPrint = () => {
    const crudStore = this.rootStore.channel.model.crudStore;
    if (!!crudStore.validationErrors) {
      return (
        <div id="validation-errors">
          <List>
            {crudStore.validationErrors.map((er) => (
              <ListItem button key={er}>
                <ListItemText className="container" style={{ minHeight: '0', minWidth: '510px' }} primary={er} />
              </ListItem>
            ))}
          </List>
        </div>
      );
    } else return '';
  };

  makePrimeCampaignOptionsArray = (campaigns) => {
    // this is the crazy bit that puts the namestring in the pulldown menus.
    if (this.campaignOptions && campaigns?.length == this?.prevCampLength)
      return this.campaignOptions;
    const model = this.rootStore.paidSocial.SocCamp;
    const crudStore = model.addCrudStore(
      this.rootStore,
      this.rootStore.paidSocial,
      this.rootStore.paidSocial.SocCamp,
      'campOptions',
    );
    // this.rootStore.channel.SocCamp.campOptionsCrudStore;
    crudStore.nsFields = namestringFields(model.columns);
    const nsArr = campaigns?.map(
      function (c) {
        const cs = this[0];
        cs.setStoredData('all');
        cs.nsFields = cs.getNsFieldValues('edit', cs.nsFields, c)
        cs.storeNsFieldsDatum(cs.nsFields);
        const ns = _.map(_.filter(cs.nsFields, (f) => f.ns_order), f => f.ns(cs)).join('_');
        return {
          label: (c.status.name == 'Draft' || c.status.name == 'Copy') ? '(' + c.status.name + ')'.concat('-').concat(ns) : ns,
          value: c.id.toString(),
        };
      },
      [crudStore],
    );
    this.prevCampLength = campaigns?.length;
    if (!_.isEmpty(nsArr)) return (this.campaignOptions = nsArr);
  };

  makePrimeAdsetOptions = (adsets) => {
    // this is the other crazy bit that puts namestrings in the adset menus.
    if (!_.isEmpty(this.adsetOptions) && adsets.length == this.prevAdsetsLength)
      return this.adsetOptions;
    const model = this.rootStore.paidSocial.SocAdSet;
    const crudStore = model.addCrudStore(
      this.rootStore,
      this.rootStore.paidSocial,
      this.rootStore.paidSocial.SocAdSet,
      'adsetOptions',
    );
    crudStore.nsFields = namestringFields(model.columns);
    const nsArr = adsets
      .filter(
        (a) =>
          a.social_campaign.brand.id == this.rootStore.apiStore.currentBrandId,
      )
      .map(
        function (a) {
          const cs = this[0];
          cs.setStoredData('all');
          cs.nsFields = cs.getNsFieldValues('edit', cs.nsFields, a)
          cs.storeNsFieldsDatum(cs.nsFields);
          cs.setStoredData('social_campaign', a.social_campaign); //shouldnt be necessary..but is
          const ns = _.map(_.filter(cs.nsFields, (f) => f.ns_order), f => f.ns(cs)).join('_');
          return {
            label: (a.status.name == 'Draft' || a.status.name == 'Copy') ? '(' + a.status.name + ')'.concat('-').concat(ns) : ns,
            value: a.id.toString(),
          };
        },
        [crudStore],
      );
    this.prevAdsetsLength = adsets.length;
    if (!_.isEmpty(nsArr)) return (this.adsetOptions = nsArr);
  };


  makePrimePaidSearchCampaignOptions = (campaigns) => {
    // this is the crazy bit that puts the namestring in the pulldown menus.
    if (this.psCampaignOptions && campaigns.length == this.prevCampLength)
      return this.psCampaignOptions;
    const model = this.rootStore.paidSearch.PaidSearchCamp;
    const cs = model.addCrudStore(
      this.rootStore,
      this.rootStore.paidSearch,
      this.rootStore.paidSearch.PaidSearchCamp,
      'psCampOptions',
    );
    // this.rootStore.channel.SocCamp.campOptionsCrudStore;
    cs.nsFields = namestringFields(model.columns);
    const nsArr = campaigns.map(
      function (c) {
        const cs = this[0];
        cs.setStoredData('all');

        cs.nsFields = cs.getNsFieldValues('edit', cs.nsFields, c)
        cs.storeNsFieldsDatum(cs.nsFields);
        const ns = _.map(_.filter(cs.nsFields, (f) => f.ns_order), f => f.ns(cs)).join('_');

        // cs.getNsFieldValues('edit', nsFields, c);
        // cs.storeInitialFieldsDatum(nsFields);
        // const ns = _.map(_.filter(nsFields, (f) => f.ns_order), (f) => f.ns(cs)).join('_');
        // return (<MenuItem key={c.id} value={c.id}>({c.id}) - {ns} </MenuItem>);
        return {

          label: (c.status.name == 'Draft' || c.status.name == 'Copy') ? '(' + c.status.name + ')'.concat('-').concat(ns) : ns,
          value: c.id.toString(),
        };
      },
      [cs],
    );
    this.prevCampLength = campaigns.length;
    if (!_.isEmpty(nsArr)) return (this.psCampaignOptions = nsArr);
  };

  makePrimeAdGroupOptions = (adGroups) => {
    // this is the other crazy bit that puts namestrings in the adset menus.
    if (this.adGroupOptions && adGroups.length == this.prevAdGroupsLength)
      return this.adGroupOptions;
    const model = this.rootStore.paidSearch.PaidSearchAdGroup;
    const crudStore = model.addCrudStore(
      this.rootStore,
      this.rootStore.paidSearch,
      this.rootStore.paidSearch.PaidSearchAdGroup,
      'adGroupOptions',
    );
    const nsFields = namestringFields(model.columns);
    const nsArr = adGroups
      .filter(
        (a) =>
          a.paid_search_campaign.brand.id == this.rootStore.apiStore.currentBrandId,
      )
      .map(
        function (a) {
          const cs = this[0];
          let nsFields = this[1];
          cs.setStoredData('all');
          cs.nsFields = namestringFields(model.columns);
          cs.nsFields = cs.getNsFieldValues('edit', cs.nsFields, a);
          cs.storeNsFieldsDatum(cs.nsFields);
          cs.setStoredData('paid_search_campaign', a.paid_search_campaign); //shouldnt be necessary..but is
          const ns = _.map(
            _.filter(nsFields, (f) => f.ns_order),
            (f) => f.ns(cs),
          ).join('_');
          return {
            label: (a.status.name == 'Draft' || a.status.name == 'Copy') ? '(' + a.status.name + ')'.concat('-').concat(ns) : ns,
            value: a.id.toString(),
          };
        },
        [crudStore, nsFields],
      );
    this.prevAdGroupsLength = adGroups.length;
    if (!_.isEmpty(nsArr)) return (this.adGroupOptions = nsArr);
  };







  @action.bound
  pivotControlChanged(control, dependent, value = false, init = false) {
    //console logs provided below can be pretty helpful. 
    const orig = this.rootStore.apiStore[_.camelCase(control) + 's'].find(pre => pre.id == this.storedData[`${control}_id`]);
    const incoming = this.rootStore.apiStore[_.camelCase(control) + 's'].find(incoming => incoming.id == value?.value);
    // console.log('changing ' + control + ' from ' + orig?.name + '(' + orig?.id + ') to ' + incoming?.name + '(' + incoming?.id + ')');

    if (value) this.storeData(`${control}_id`, value);
    if (!init) this.unsetPivotForm(dependent);


    switch (control) {
      case 'tactic':
        this.rootStore.apiStore.setTargetingFields(this.storedData.tactic_id);
        break;
      case 'objective_modifier':
        this.rootStore.apiStore.setExtraFieldFields(this.storedData.objective_modifier_id);
        break;
      case 'paid_search_campaign':
        this.rootStore.apiStore.setExtraFieldFields(this.storedData.paid_search_campaign == undefined ? '' : this.storedData.paid_search_campaign.objective_modifier_id);
        break;
    }
    if (!init) this.resetPivotForm(control, dependent);
    if (this.rootStore.react) this.rootStore.react.forceUpdate();
  }

  unsetPivotForm(dependent) {
    const type = this.rootStore.channel.model.controller + '_' + dependent + 's_attributes';
    const storedData = this.rootStore.channel.model.crudStore.storedData[type]
    const formValues = this.rootStore.channel.model.crudStore.form.$(type).values();
    const form = this.rootStore.channel.model.crudStore.form;
    switch (dependent) {
      case 'targeting':
        this.previously_stored_dependent_data = storedData.reduce((res, item) => res.filter(i => i.targeting_id !== item.targeting_id).concat(item), this.previously_stored_dependent_data);
        this.previous_dependent_form_values = formValues.reduce((res, item) => res.filter(i => i.targeting_id !== item.targeting_id).concat(item), this.previous_dependent_form_values);
        break;
      case 'extra_field':
        this.previously_stored_dependent_data = storedData.reduce((res, item) => res.filter(i => i.extra_field_id !== item.extra_field_id).concat(item), this.previously_stored_dependent_data);
        this.previous_dependent_form_values = formValues.reduce((res, item) => res.filter(i => i.extra_field_id !== item.extra_field_id).concat(item), this.previous_dependent_form_values);
        break;
    }

    formValues.forEach(function (currentValue, index) {
      // this.rootStore.channel.model.crudStore.form.$(type).$(index).clear();
      this.rootStore.channel.model.crudStore.form.$(type).del(index);
    }, this);
    this.rootStore.channel.model.crudStore.storedData[type] = [];
    // console.log('psdd map: ' + this.previously_stored_dependent_data.map(psdd => [psdd.id, psdd.extra_field_id, psdd.extra, psdd.on, psdd._destroy, '||']).toString());
    // console.log('psfv map: ' + this.previous_dependent_form_values.map(psdd => [psdd.id, psdd.extra_field_id, psdd.extra, psdd.on, psdd._destroy, '||']).toString());
    // console.log('those should have whatever was there before and the form values after unset should be empty: ' + form.$(type).values())

    // console.log(this.rootStore.channel.model.crudStore.name)
  }

  resetPivotForm(control, dependent) {
    // console.log('previous form and stored init: ', jsp(this.previous_dependent_form_values), jsp(this.previously_stored_dependent_data))
    this.currentFields = this.rootStore.apiStore[_.camelCase(dependent + 'Fields')];
    const attributes = this.rootStore.apiStore[_.camelCase(dependent + 's')];
    // console.log(`There are ${ attributes.length } total ${ dependent } s: ${ this.joinNameAndIdWithAnd(attributes) } `)
    // console.log(`We will now show ${ this.currentFields.length } of them: ${ this.joinNameAndIdWithAnd(this.currentFields) } `)
    const type = this.rootStore.channel.model.controller + '_' + dependent + 's_attributes';
    attributes.forEach(function (a, index) {
      const type = this.rootStore.channel.model.controller + '_' + dependent + 's_attributes';
      const storedData = this.rootStore.channel.model.crudStore.storedData[type]
      const formValues = this.rootStore.channel.model.crudStore.form.$(type).values();
      const form = this.rootStore.channel.model.crudStore.form;
      // console.log('current attribute: ' + a.name + '(' + a.id + ')');
      if (this.currentFields.map(cf => cf.id).includes(a.id)) {
        // the current attribute is part of the new form.

        // storeData it if previous.
        const psdd = this.previously_stored_dependent_data.find(psdd => psdd[dependent + '_id'] == a.id);
        if (psdd) {
          storedData.push(psdd);
        }

        // set value to the previous form value or a generic new one. 
        const value = _.isEmpty(this.previous_dependent_form_values.find(pdfv => pdfv[dependent + '_id'] == a.id)) ?
          // a generic new one. 
          { changed: false, extra: null, [`${this.model.controller}_id`]: this.storedData['id'], on: false, [dependent + '_id']: a.id, _destroy: true } :
          // the previous
          this.previous_dependent_form_values.find(pdfv => pdfv[dependent + '_id'] == a.id);

        // add it to the form. 
        form.$(type).add()
        const newIndex = form.$(type).size - 1;
        value._destroy = _.isEmpty(value.extra);
        form.$(type).$(newIndex).set(value)
        form.$(type).$(newIndex).$('extra').set('label', `${a.name} extra`);
        this.setRequired(a, newIndex);

      } else {
        //its not in the current controller's dependents.. so store it there 
        // console.log('current attribute ' + a.name + '(' + a.id + ') is not in that controls dependents so we store it');

        const pdfv = this.previous_dependent_form_values.find(pdfv => (pdfv[dependent + '_id'] == a.id && !pdfv._destroy))
        const psdd = this.previously_stored_dependent_data.find(psdd => (psdd[dependent + '_id'] == a.id && !psdd._destroy));

        if (pdfv) {
          // console.log('storing away init: ' + jsp(pdfv))
          if (pdfv.id) {
            // but we do need to mark it as destroyed?
            pdfv._destroy = true;
            form.$(type).add()
            const newIndex = form.$(type).size - 1;
            form.$(type).$(newIndex).set(pdfv);
          }
          // but it was in the form before. 
          // console.log('finally: ', jsp(pdfv), jsp(psdd))
        }
      }
      // console.log('finally prevs: ', jsp(this.previous_dependent_form_values), jsp(this.previously_stored_dependent_data))
    }, this);
    // console.log('final stored data ', jsp(this.storedData[type]));
    // console.log('final form ', this.form.$(type).values());
    return;


    //old code. 
    currentFields.forEach(function (t, index) {
      const type = this.rootStore.channel.model.controller + '_' + dependent + 's_attributes';
      const storedData = this.rootStore.channel.model.crudStore.storedData[type]
      const formValues = this.rootStore.channel.model.crudStore.form.$(type).values();
      const form = this.rootStore.channel.model.crudStore.form;
      if (this.previously_stored_dependent_data.map(psdd => psdd[dependent + '_id']).includes(t.id)) {
        const psdd = this.previously_stored_dependent_data.find(psdd => psdd[dependent + '_id'] == t.id);
        storedData.push(psdd);
        const pdfv = this.previous_dependent_form_values.find(pdfv => pdfv[dependent + '_id'] == t.id);
        form.$(type).add()
        const newIndex = form.$(type).size - 1;
        form.$(type).$(newIndex).set(pdfv)
        form.$(type).$(newIndex).$('extra').set('label', `${t.name} extra`);
        this.setRequired(t, newIndex)
      } else {
        const psdd = this.previously_stored_dependent_data.find(psdd => psdd[dependent + '_id'] == t.id);
        storedData.push(psdd);
        const pdfv = this.previous_dependent_form_values.find(pdfv => pdfv[dependent + '_id'] == t.id);
        form.$(type).add()
        const newIndex = form.$(type).size - 1;
        form.$(type).$(newIndex).set(pdfv)
        form.$(type).$(newIndex).$('extra').set('label', `${t.name} extra`);
        this.setRequired(t, newIndex)
      }
    }, this);
  }

  joinNameAndIdWithAnd = (array) => {
    // console.log(jsp(array));
    switch (true) {
      case (array.length > 1):
        return `${_.sortBy(array, 'id').map(e => `${e.name} (${e.id})`).slice(0, -1).join(', ')} and ${_.sortBy(array, 'id').map(e => `${e.name} (${e.id})`).slice(-1)}`;
      case (array.length == 1):
        return `${array[0].name} (${array[0].id})`;
      default:
        return '';
    }
  }

  setRequired = (targeting, index, unset = false) => {

    const textOrNumber = ['text', 'number'];
    const rule = unset ? '' : 'required';
    const form = this.rootStore.channel.model.crudStore.form;
    const type = this.rootStore.channel.model.controller + '_targetings_attributes';
    if (
      textOrNumber.includes(targeting.extra_field_type) &&
      targeting.extra_required &&
      form.$(type).$(index).$('on').value

    ) {
      form.$(type).$(index).$('extra').set('rules', rule);
    }
  }

  @action.bound
  formComponentDidMount = async () => {
    this.loading = true;
    // return if a server error happened during initForm.
    if (this.rootStore.apiStore.serverRequestError) {
      return;
    }
    await this.model.requiredData(this.rootStore.apiStore);
    if (this.original_parent_field && this.firstPage) {
      const parent_id = this.setInitialFieldValue(
        this.initialAction,
        this.original_parent_field,
        this.selectedRow,
      );
      this.storeData(this.original_parent_field.form, parent_id);
      this.form.add(this.original_parent_field);
      this.storeData(this.original_parent_field.form, parent_id);
      // this.setBelongsTo(this.original_parent_field.form, parent_id);
    }
    // this.form.add('id');
    if (this.prefetchId) {
      this.form?.$('id').set(this.prefetchId)
    };
    if (this.prefetchId) this.storeData('id', this.prefetchId);
    this.loading = false;
  };

  @action.bound
  setCampaigns(campaigns) {
    this.campaigns = campaigns;
  }

  @action.bound
  getNsObj() {
    var nsObj = {};
    try {
      nsObj = domoFields(this);
    } catch (err) {
      nsObj = err;
    }
    return nsObj;
  }

  getCopytoClipboard = (type) => {
    return (
      <Tooltip data-qa={`${type}-copy-to-clipboard`} title="Copy" placement="top-start" >
        <IconButton aria-label="copy-to-clipboard" onClick={() => this.copyText(type)} >
          <ContentCopyIcon />
        </IconButton>
      </Tooltip>
    );
  }

  copyText = (id) => {
    var copyText = document.getElementById(id["id"]);
    window.navigator.clipboard.writeText(copyText.innerText);
    this.resetState(id["id"]);
    setTimeout(this.forceUpdateHandler, 5000);
  }

  @action.bound
  forceUpdateHandler() {
    this.copyState = {
      setOpen: false,
      setOpen2: false,
      setOpen3: false,
    };
    // this.forceUpdate();
  }


  laterDate(d1, d2) {
    var moment = require('moment');
    if (_.isEmpty(d1)) return d2;
    // if (_.isEmpty(d2)) return d1; //questionable logic.
    const md1 =
      Object.getPrototypeOf(d1).constructor.name == 'String'
        ? moment(d1)._d
        : d1;
    const md2 =
      Object.getPrototypeOf(d2).constructor.name == 'String'
        ? moment(d2)._d
        : d2;
    return md1 > md2 ? md1 : md2;
  }

  rules = () => {

  }

  setBulkCrudStores = (rows) => {
    const bulkCrudStores = rows.map(function callback(row, index) {
      const bcs = this.rootStore.channel.model.addCrudStore(
        this.rootStore,
        this.rootStore.channel,
        this.rootStore.channel.model,
        `${this.rootStore.channel.model.codename}_id_${row.id || row}_${this.random(5)}_childStore`,
      );
      bcs.setDisplayedRows([row]);
      bcs.selectedRows.push(row);
      bcs.selectedRowIds = [row.id]
      return bcs;
    }, this);
    this.bulkCrudStores = this.bulkCrudStores.concat(bulkCrudStores);
  }

  initBulkCrudStoreForms = (action) => {
    // const create = typeof rows[0] === 'string' ? true : false;
    this.bulkCrudStores.forEach((cs, index) => {
      // const rowsAtIndex = create ? {} : rows[index]
      cs.initForm(action)
    }, action);
  }


  getBulkCrudStoreByRowId(rowId) {
    const newOnes = this.bulkCrudStores.find(m => m.name.match(new RegExp('_id_' + rowId + '_')));
    if (!_.isEmpty(newOnes)) return newOnes;
    const copiedOnes = this.bulkCrudStores.find(bcs => bcs.storedData['id'] == rowId);
    if (!_.isEmpty(copiedOnes)) return copiedOnes;
  }

  random(length = 8) {
    // Declare all characters
    let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    // Pick characers randomly
    let str = '';
    for (let i = 0; i < length; i++) {
      str += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return str;
  };

}

export default CrudAdminStore;