import _ from 'lodash';
import ax from 'packs/axios';
import Channel from './Channel';
import * as Sentry from '@sentry/browser';
import { captureException } from '../lib/sentry';
import { kebabCaseToCamel } from './Api.js';

import { observable, action, computed } from 'mobx';

class ApiStore {
  constructor(rootStore, myApi) {
    this.rootStore = rootStore;
  }

  /* Authentication */
  @observable currentUser = null;
  @computed get currentGroup() {
    return this.currentUser && this.currentUser.group;
  }

  @observable authenticating = false;
  @observable signinError = null;
  @observable serverValidationErrors = [];
  @observable serverRequestError = null;
  @observable validationError = null;
  @observable refreshingUser = false;
  @observable alreadyChangedPassword = false;
  @observable alreadyCreatedAccount = false;
  @observable alreadySavedPassword = false;
  @observable alreadySentForgotPasswordEmail = false;
  @observable prefetchId = null;
  @observable only_name = { onlyName: true }
  @observable copy_status = { status: 'copy' }
  @observable brandLabel = 'Brand';
  @observable sortedExtraFieldFields = [];
  @computed get authenticated() {
    return this.currentUser != null;
  }

  clear() {
    this.authenticating = false;
    this.refreshingUser = false;
    this.fetchingAdTypes = false;
    this.fetchingBrands = false;
    this.fetchingCampaignTypes = false;

    this.signinError = null;
    this.currentUser = null;
    this.adTypes = [];
    this.campaignTypes = [];
    this.devices = [];
    this.status = [];
  }

  @action.bound
  onCancel() {
    this.unsetSelecteds();
    this.setAction('index');
  }

  @action.bound
  refreshCurrentUser() {
    this.signinError = null;
    this.refreshingUser = true;
    ;
    ax.get('/users/current.json', {})
      .then((response) => {
        if (response.data.email) {
          this.setCurrentUser(response.data);
        }
      })
      .catch((error) => {
        this.clear();
        this.signinError = error;
      });
  }

  @observable fetchingNewUser = false;

  @action.bound
  fetchNewUser(form) {
    this.signinError = null;
    this.fetchingNewUser = true;
    ax.get('/users/new-user.json?reset_password_token=' + form.$('reset_password_token').value)
      .then((response) => {
        if (response.data) {
          this.setNewUser(response.data);
          form.$('name').value = response.data.name;
        }
        this.fetchingNewUser = false;
      })
      .catch((error) => {
        this.clear();
        this.signinError = error;
      });
  }

  @action.bound
  signin(form) {
    this.authenticating = true;
    const email = form.$('email').value;
    const password = form.$('password').value;
    ax.post('/users/sign_in.json', {
      user: { email, password },
    })
      .then((response) => {
        this.authenticating = false;
        this.setCurrentUser(response.data);
      })
      .catch((error) => {
        this.authenticating = false;
        this.signinError = error;
      });
  }

  @action.bound
  getBrandName(brand_id) {
    const url = `/brands/${brand_id}.json`;

    ax.get(url)
      .then((response) => {
        this.brandLabel = response.data.name;
      })
      .catch((error) => {
        console.log(error)
      });
  }

  @action.bound
  signout() {
    this.signinError = null;
    this.setCurrentUser();
    ax.delete('/users/sign_out', {})
      .then(() => {
        this.rootStore.clear();
      })
      .catch((error) => {
        console.log(`User signout error: ${error}`);
        this.clear();
        this.signinError = error;
      });
  }

  @action.bound
  changePassword(form) {
    // const oldPass = form.$('oldPass').value;
    const password = form.$('newPass').value;
    const reset_password_token = form.$('reset_password_token').value;
    this.serverValidationErrors = [];
    let vals = {}
    if (form.fields.has('name')) {
      const name = form.$('name').value;
      vals = { user: { password, reset_password_token, name } }
    } else {
      vals = { user: { password, reset_password_token } }
    }

    ax.patch('/users/change_password.json',
      vals
    )
      .then((response) => {
        this.serverValidationErrors = [];
        this.alreadyChangedPassword = true;
        this.alreadyCreatedAccount = true;
      })
      .catch((error) => {
        console.log(`password change error: ${error}`);
        this.handleServerError(error);
      });
  }

  @action.bound
  savePassword(form) {
    const oldPass = form.$('oldPass').value;
    const password = form.$('newPass').value;
    ax.patch('/users/update_password.json', {
      user: { oldPass, password },
    })
      .then((response) => {
        this.serverValidationErrors = [];
        this.alreadySavedPassword = true;
      })
      .catch((error) => {
        console.log(`password save error: ${error}`);
        this.handleServerError(error);
      });
  }

  @observable datasetsProvisioning = false;

  @action.bound
  async provisionDatasets(domoConnectionId) {
    this.datasetsProvisioning = true;
    const url = `/domo-connections/${domoConnectionId}/provision-datasets`

    ax.post(url)
      .then((response) => {
        this.datasetsProvisioning = false;
      })
      .catch((error) => {
        this.handleServerError(error);
        this.datasetsProvisioning = false;
      })
  }

  @computed get sortedChannels() {
    return _.sortBy(this.channels, ['name']);
  }


  @computed get sortedBrands() {
    return this.sortBrands(this.brands);
  }

  sortBrands(brands) {
    return brands.sort((a, b) => {
      //Brand a and b are both favorites  or both not favorites
      if (
        this.rootStore.isFavoriteBrand(a.id) ===
        this.rootStore.isFavoriteBrand(b.id)
      ) {
        var nameA = a.name.toUpperCase(); // ignore upper and lowercase
        var nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        // names must be equal
        return 0;
      }
      if (this.rootStore.isFavoriteBrand(a.id)) {
        return -1;
      } else {
        return 1;
      }
    });
  }

  @action.bound
  sendForgotPasswordEmail(form) {
    const email = form.$('email').value;
    ax.post('/users/forgot_password.json', {
      user: { email },
    })
      .then((response) => {
        this.serverValidationErrors = [];
        this.alreadySentForgotPasswordEmail = true;
      })
      .catch((error) => {
        this.handleServerError(error);
      });
  }

  @action.bound
  setCurrentUser(user) {
    if (!user) {
      this.currentUser = null
      Sentry.setUser(null);
      this.refreshingUser = false;
      return;
    }
    this.currentUser = user;
    this.currentEmail = user.email;
    this.brands = [];
    this.rootStore.newCurrentUser(user);
    Sentry.setUser({ "email": this.currentEmail });
    this.refreshingUser = false;
  }

  @observable newUser = false;
  @action.bound
  setNewUser(user) {
    this.newUser = user;
    this.fetchingNewUser = false;
  }
  /* Brands */
  @observable brands = [];
  @observable currentBrand = null;
  @observable currentBrandId = null;
  @observable fetchingBrands = false;

  @action.bound
  clearBrands() {
    this.brands = [];
  }

  @action.bound
  fetchBrands(user) {
    if (_.isEmpty(user) || _.isEmpty(user.group)) return;
    // console.log('apistore fetching brands for user');
    if (!this.fetchingBrands) {
      this.fetchingBrands = true;
      ax.get('/brands.json', { params: { user_id: user.id } })
        .then((response) => {
          // console.log(response.data);
          this.serverValidationErrors = [];
          this.brands = response.data;
          if (this.currentBrandId) {
            this.currentBrand = this.brands.find(
              (brand) => `${brand.id}` === `${this.currentBrandId}`,
            );
          }
          this.fetchingBrands = false;
        })
        .catch((error) => {
          this.handleServerError(error);
          this.fetchingBrands = false;
        });
    }
  }

  @action.bound
  getBrandFromPath(path) {
    return this.brands.find(b => b.path == path);
  }

  @action.bound
  brandChanging(newBrand) {
    if (_.isEmpty(newBrand)) return false;
    return _.isEmpty(this.currentBrand) || newBrand.name != this.currentBrand.name ? true : false
  }


  @action.bound
  changeBrands(brand) {
    if (_.isEmpty(brand)) return;
    this.rootStore.paidSocial = new Channel(
      this.rootStore,
      this.rootStore.storeMetadata,
      'paidSocial',
    );
    this.rootStore.display = new Channel(
      this.rootStore,
      this.rootStore.storeMetadata,
      'display',
    );
    this.rootStore.email = new Channel(
      this.rootStore,
      this.rootStore.storeMetadata,
      'email',
    );
    this.rootStore.paidSearch = new Channel(
      this.rootStore,
      this.rootStore.storeMetadata,
      'paidSearch',
    );

    this.rootStore.programmatic = new Channel(
      this.rootStore,
      this.rootStore.storeMetadata,
      'programmatic',
    );

    // this.campaigns = [];
    // this.adSets = [];
    // this.socialadSets = []; // ads? not set here.
    // this.displayPlacements = [];
    // this.displayPackages = [];
    // this.displayAds = []; //
    // this.displayCreatives = [];
    this.currentBrandId = brand.id;
    this.currentBrand = brand;
  }

  @observable fetchingChannels = false;

  @action.bound
  fetchChannels(brandId) {
    if (_.isNull(brandId)) return;
    if (!this.fetchingChannels) {
      this.fetchingChannels = true;
      ax.get('/channels.json', { params: { brand_id: brandId } })
        .then((response) => {
          this.serverValidationErrors = [];
          this.channels = response.data;
          this.fetchingChannels = false;
        })
        .catch((error) => {
          this.handleServerError(error);
          this.fetchingChannels = false;
        });
    }
  }

  @observable fetchingBrandUserChannels = false;

  @action.bound
  fetchBrandUserChannels(brandId, userId) {
    // this gets channels for a user for a specific brand
    // if (!_.isEmpty(this.brandChannels)) return; see UCC-1701
    if (_.isNull(brandId) || _.isNull(userId)) return;
    if (!this.fetchingBrandUserChannels) {
      this.fetchingBrandUserChannels = true;
      ax.get('/brand-channels.json', { params: { brand_id: brandId, user_id: userId } })
        .then((response) => {
          this.serverValidationErrors = [];
          this.brandChannels = response.data;
          this.fetchingBrandUserChannels = false;
        })
        .catch((error) => {
          this.handleServerError(error);
          this.fetchingBrandUserChannels = false;
        });
    }
  }

  @observable fetchingBrandsChannels = false;

  @action.bound
  fetchBrandsChannels() {
    // this gets all brands and channels for a user.
    // if (!_.isEmpty(this.brandsChannelsMap)) return; see UCC-1701
    if (!this.fetchingBrandsChannels) {
      this.fetchingBrandsChannels = true;
      ax.get('/brand-channels.json', { params: { current_user: true } })
        .then((response) => {
          this.serverValidationErrors = [];
          this.brandsChannels = response.data;
          const bcBrandIds = [...new Set(_.sortBy(this.brandsChannels, function (o) { return o.brand.name; }).map(item => item.brand_id))];
          this.brandsChannelsMap = bcBrandIds.map(bcbid => ({ brand: this.brandsChannels.find(bcs => bcs.brand_id == bcbid).brand, channels: this.brandsChannels.filter(bcs => bcs.brand_id == bcbid).map(bcs => bcs.channel) }));
          this.fetchingBrandsChannels = false;
        })
        .catch((error) => {
          this.handleServerError(error);
          this.fetchingBrandsChannels = false;
        });
    }
  }


  @action.bound
  handleServerError(error) {
    if (!error || !error.request) {
      return;
    }

    switch (error.request.status) {
      case 400:
      case 401:
      case 409:
      case 422:
        this.serverValidationErrors = error.response.data;
        break;

      case 0:
      case 500:
      case 503:
      default:
        this.setServerRequestError(error);
        break;
    }
  }

  @action.bound
  setServerRequestError(error = null) {
    if (error && error.request) {
      console.log(`serverRequestError: URL: ${error?.request?.url} Status: ${error?.request?.status}`)
      this.serverRequestError = error?.request?.status;
    } else {
      this.serverRequestError = { "message": "Request to server failed. No error reason available." }
    }
    captureException(error)
  }


  @action.bound
  channelPerm(channel) {
    if (channel && channel.adminChannel) {
      return true; // permission_levels takes care of it now.
    }
    return this.brands.some(
      (c) => c.id.toString() === this.currentBrandId.toString(),
    );
  }

  @action.bound
  exportNamestrings(brand, channel, model, brand_id, set) {
    const filename = 'BrandAbbrev_Channel_Model_CurrentTime';
    ax.get('/namestrings.csv', {
      params: { channel: channel, model: model, brand: brand, set: set, brand_id: brand_id },
    })
      .then((response) => {
        const { channel, model, brand } = response.config.params;
        const date = new Date()
          .toLocaleString()
          .replace(/\//g, '')
          .replace(',', '-')
          .replace(/ /g, '')
          .replace(/:/g, '');
        this.exportToCsv(
          `${brand}_${channel}_${model}_${date}.csv`,
          response.data.split('\n'),
        );
      })
      .catch((error) => {
        this.setServerRequestError(error);
      });
  }

  @action.bound
  exportAdminCsv(path, selected) {
    // console.log('export admin csv for ' + path);

    ax.get(`/${path}.csv`)
      .then((response) => {
        let array = response.data.split('\n');
        if (!!selected) {
          array = array.filter((line, index) => index === 0 || selected.includes(+line.split(',')[0]));
        }
        const date = new Date()
          .toLocaleString()
          .replace(/\//g, '')
          .replace(',', '-')
          .replace(/ /g, '')
          .replace(/:/g, '');
        this.exportToCsv(
          `admin_${path}_${date}.csv`,
          array,
        );
      })
      .catch((error) => {
        this.setServerRequestError(error);
      });
  }

  displayChildExtraField(crudStore, parent_id, child_id) {
    if (crudStore.storedData[parent_id] == '') return false;
    return true;
  }

  paidSearchExtraField(crudStore, field) {
    return true;
  }

  exportToCsv(filename, rows) {
    var blob = new Blob([rows.join('\n')], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, filename);
    } else {
      var link = document.createElement('a');
      if (link.download !== undefined) {
        // feature detection
        // Browsers that support HTML5 download attribute
        var url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  isInt(value) {
    return !isNaN(value) &&
      parseInt(Number(value)) == value &&
      !isNaN(parseInt(value, 10));
  }

  notEmpty(data) {
    return !this.empty(data);
  }

  empty(data) {
    if (typeof (data) == 'number') {
      return data === 0 ? true : false;
    } else if (typeof (data) == 'boolean') {
      return data === true ? false : true;
    }
    if (typeof (data) === 'undefined' || data === null) {
      return true;
    }
    if (typeof (data.length) != 'undefined') {
      return data.length == 0;
    }
    var count = 0;
    for (var i in data) {
      if (data.hasOwnProperty(i)) {
        count++;
      }
    }
    return count == 0;
  }

  @observable version = null;
  setVersion(version) {
    if (version == 'leave_it') return;
    this.version = version;
  }

  checkVersion(items) {
    if (items.length < 1) return;
    this.version == null && items.length > 0 ? this.setVersion(items[0].version) : 'leave_it';
    let currentVersion = items[0].version;
    if (currentVersion != this.version) this.updateVersion();
  }

  updateVersion() {
    console.log('Updating to UCC software version ' + this.version + '. Your page will reload now');
    window.location.reload(false);
    return false;
  }

  reloadPage() {
    window.location.reload(false);
    return false;
  }

  @observable abTypes = [];
  @observable abs = [];
  @observable adLocations = [];
  @observable testGroups = [];
  @observable testTypes = [];
  @observable areas = [];
  @observable messageTypes = [];
  @observable ids = [];
  @observable socialCampaignsTotal = [];
  @observable adSetsTotal = [];
  @observable socialCampaigns = [];
  @observable socialCampaignsFilterOptions = [];
  @observable facebookConnectionsFilterOptions = [];
  @observable paidSearchCampaignsTotal = [];
  @observable displayPlacements = [];
  @observable displayAds = [];
  @observable displayAdsFilterOptions = [];
  @observable displayPlacementsFilterOptions = [];
  @observable programmaticPlacementsFilterOptions = [];
  @observable displayCreatives = [];
  @observable displayCreativesFilterOptions = [];
  @observable displayPackages = [];
  @observable displayPackagesFilterOptions = [];
  @observable emailCampaigns = [];
  @observable emailCampaignsFilterOptions = [];
  @observable extraFieldModels = [];
  @observable extraFields = [];
  @observable marketingCampaigns = [];
  @observable paidSearchCampaigns = [];
  @observable paidSearchCampaignsFilterOptions = [];
  @observable paidSearchAdGroupsTotal = [];
  @observable paidSearchAdGroupsFilterOptions = [];
  @observable paidSearchAdNames = [];
  @observable paidSearchAdNamesFilterOptions = [];
  @observable programmaticCampaignsFilterOptions = [];
  @observable adModifierObjectiveModifiers = [];
  @observable extraFieldObjectiveModifiers = [];
  @observable businessUnits = [];
  @observable campaignTypes = [];
  @observable optimizationGoals = [];
  @observable statuses = [];
  @observable creativeTypes = [];
  @observable adTypes = [];
  @observable audiences = [];
  @observable emailServicePlatforms = [];
  @observable emailTypes = [];
  @observable platforms = [];
  @observable publishers = [];
  @observable secondaryTactics = [];
  @observable segments = [];
  @observable sizes = [];
  @observable tactics = [];
  @observable devices = [];
  @observable geos = [];
  @observable groups = [];
  @observable brands = [];
  @action.bound
  setBrands(brands) {
    this.brands = brands.filter(b => (b.status == undefined || b.status.name.toLowerCase() == 'active'))
    this.brands = _.sortBy(this.brands, ['name']);
  }

  @observable channelDomoDatasets = [];
  @observable domoConnections = [];
  @action.bound
  setDomoConnections(domoConnections) {
    this.domoConnections = _.sortBy(domoConnections, ['id'])
  }

  @observable facebookConnections = [];
  @action.bound
  setFacebookConnections(facebookConnections) {
    this.facebookConnections = _.sortBy(facebookConnections, ['id'])
  }

  @observable facebookObjects = [];
  @action.bound
  setFacebookObjects(facebookObjects) {
    this.facebookObjects = _.sortBy(facebookObjects, ['id'])
  }

  @observable namestrings = [];
  @observable channels = [];
  @observable brandChannels = [];
  @observable brandsChannels = [];
  @observable brandsChannelsMap = [];
  @observable displayTypes = [];
  @observable genders = [];
  @observable goals = [];
  @observable languages = [];
  @observable models = [];
  @observable objectives = [];
  @observable objectiveModifiers = [];
  @observable campaignTypeChannels = [];
  @observable channelGenders = [];
  @observable channelObjectives = [];
  @observable displayTypeChannels = [];
  @observable campaignTypeObjectives = [];
  @observable objectiveModifierObjectives = [];
  @observable channelPlatforms = [];
  @observable permissionLevels = [];
  @observable channelTactics = [];
  @observable testGroupTestTypes = [];
  @observable selectedCreativeName = '';
  @observable selectedBrandUrl = '';
  @observable sendTypes = [];
  @observable waves = [];
  @observable adTypeChannels = [];
  @observable campaignSetViaPulldown = false;
  @observable adSetsetViaPulldown = false;
  @observable adSets = [];
  @observable adSetsFilterOptions = [];
  @observable adsFilterOptions = [];
  @observable adModifiers = [];
  @observable users = [];
  @observable usersFilterOptions = [];
  @observable groupsFilterOptions = [];
  @observable brandsFilterOptions = [];
  @observable channelsFilterOptions = [];
  @observable ads = [];
  @observable adModifiersFilterOptions = []
  @observable adTypesFilterOptions = []
  @observable campaignTypesFilterOptions = []
  @observable gendersFilterOptions = []
  @observable objectivesFilterOptions = []
  @observable platformsFilterOptions = []
  @observable tacticsFilterOptions = []
  @observable targetingsFilterOptions = []
  @observable testGroupsFilterOptions = []
  @observable objectiveModifiersFilterOptions = []
  @observable brandsFilterOptions = []
  @observable channelDomoDatasetsFilterOptions = []
  @observable namestringsFilterOptions = []
  @observable domoConnectionsFilterOptions = []
  @observable displayTypesFilterOptions = [];
  @observable channelTargetings = [];
  @observable tacticTargetings = [];
  @observable targetings = [];
  @observable extraFields = [];
  @observable totalCount = [];
  @observable createdTodayCount = [];
  @observable adsetTabDisabled = false;
  @observable adTabDisabled = true;
  @observable adGroupTabDisabled = false;
  @observable adNameTabDisabled = true;

  @observable extraFieldFields = [];
  @action.bound
  setExtraFieldFields(objective_modifier) {
    const objectiveModifiers = this.extraFieldObjectiveModifiers.map((look) => {
      if (look.value == objective_modifier) return look.f_key;
    });

    this.extraFieldFields = _.compact(
      this.extraFields.map(function (t) {
        if (objectiveModifiers.includes(t.id)) return t;
      }, objectiveModifiers),
    );
    this.sortedExtraFieldFields = this.extraFieldFields.sort(function (a, b) { return a.position - b.position });
    return this.sortedExtraFieldFields;
  }

  @observable targetingFields = [];
  @action.bound
  setTargetingFields(tactic) {
    const tactics = this.tacticTargetings.map((look) => {
      if (look.value == tactic) return look.f_key;
    });
    const targetingFieldIds = _.intersection(
      _.compact(tactics),
      this.channelTargetings.map((look) => look.f_key),
    );
    this.targetingFields = _.compact(
      this.targetings.map(function (t) {
        if (targetingFieldIds.includes(t.id)) return t;
      }, targetingFieldIds),
    );
    // console.log('tactic was ' + tactic);
    return this.targetingFields;
  }

  @action.bound
  setNsTargetingFields(tactic) {
    const tactics = this.tacticTargetings.map((look) => {
      if (look.value == tactic) return look.f_key;
    });
    const targetingFieldIds = _.intersection(
      _.compact(tactics),
      this.channelTargetings.map((look) => look.f_key),
    );
    const targetingFields = _.compact(
      this.targetings.map(function (t) {
        if (targetingFieldIds.includes(t.id)) return t;
      }, targetingFieldIds),
    );
    // yeah mostly that this doesnt set the instance var 
    // and returns them is the difference between 
    // this method and the one above it.
    return targetingFields;
  }

  @action.bound
  setGenericModelPromiseData(data, column) {
    if (column === "geos" || column === "languages" || column === "targetings") {
      this[kebabCaseToCamel(column)] = _.sortBy(data, ['position', 'name']);
    } else if (column.includes('filter-options') || column.includes('total')) {
      this[kebabCaseToCamel(column)] = data;
    } else if (column.includes('statuses')) {
      this.checkVersion(data);
      this[kebabCaseToCamel(column)] = data;
    } else if (column.includes('namestrings')) {
      this.totalCount = data[0];
      this.createdTodayCount = data[1];
      this[kebabCaseToCamel(column)] = data;
    } else if (column.includes('domo')) {
      this[kebabCaseToCamel(column)] = _.sortBy(data, ['id']);
    } else {
      this[kebabCaseToCamel(column)] = _.sortBy(data, ['name']);
    }
  }

  async promiseResults(promises) {
    this.serverRequestError = null;
    return await Promise.all(promises).catch((error) => {
      this.handleServerError(error);
    });
  }

  @action.bound
  async bulkCreatePromiseResults() {
    let promises = [];
    ['brands', 'groups'].map(e => {
      promises.push(ax.get(`/${e}.json`))
    });
    await this.promiseResults(promises).then(results => {
      results.map(r => {
        this.setGenericModelPromiseData(r.data, r.config.url.replace("/", "").replace(".json", ""))
      })
    })
  }

  @action.bound
  async genericModelPromiseResults(variant) {
    const { channel: { model } } = this.rootStore;
    const channel = this.rootStore.channel;
    const brand_param = { brand_id: this.currentBrand?.id };
    const adset_param = _.isEmpty(this.rootStore.paidSocial?.SocCamp?.crudStore?.selectedRow) ? '' : { social_campaign_id: [this.rootStore.paidSocial.SocCamp.crudStore.selectedRow.id], };
    const final_param = { ...brand_param, ...adset_param };
    let promises = [];
    if (model.genericModelPromiseEndpoints && model.genericModelPromiseEndpoints.length > 0) {
      model.genericModelPromiseEndpoints.map(e => {
        let filter = {};
        if (e.includes('channel') && !this.rootStore.channel.adminChannel) filter = { codename: channel.codename };
        if (e.includes('facebook-connections') && !this.rootStore.channel.adminChannel) filter = { test: 'true' };
        if (e.includes('users') && this.rootStore.channel.adminChannel) filter = this.only_name;
        if (e.includes('marketing-campaigns')) filter = { active: true, ...brand_param };
        if (['ad-sets', 'social-campaigns', 'paid-search-ad-groups', 'paid-search-ad-names', 'paid-search-campaigns'].some(test => e.includes(test))) filter = final_param;
        promises.push(ax.get(`/${e}.json`, { params: filter }))
      });
      const uniquePromises = model.modelPromiseEndpoints ? model.modelPromiseEndpoints(this.rootStore, this.currentBrand?.id, adset_param) : [];
      for (let item of uniquePromises) {
        promises.push(item)
      }
      await this.promiseResults(promises).then(results => {
        if (results == undefined) return;

        results.map(r => {
          this.setGenericModelPromiseData(r.data, r.config.url.replace("/", "").replace(".json", ""))
        })
      })
    }
  }

  @action.bound
  async genericTabPromiseResults() {
    // console.log('genericTabPromiseResults');
    const { channel: { model } } = this.rootStore;
    // console.log(model);
    const brand_param = { brand_id: this.currentBrand?.id };
    const promises = model?.tabPromiseEndpoints(this.rootStore, brand_param);
    if (!!promises && promises.length > 0) {
      await this.promiseResults(promises).then(results => {
        results.map(r => {
          this.setGenericModelPromiseData(r.data, r.config.url.replace("/", "").replace(".json", ""))
        });
        // console.log('generic tab promise results');
        this.adsetTabDisabled = this.empty(this.socialCampaignsTotal);
        // console.log('ads tab');
        // console.log(this.adSetsTotal);
        // console.log(this.adSetsTotal < 1);
        this.adTabDisabled = this.adSetsTotal < 1 ? true : false;
        this.adGroupTabDisabled = this.empty(this.paidSearchCampaignsTotal);
        this.adNameTabDisabled = this.paidSearchAdGroupsTotal < 1 ? true : false;
      });
    }
  }
}

export default ApiStore;