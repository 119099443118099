import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import React from 'react';
import MultiSelect from "@khanacademy/react-multi-select"; // https://github.com/Khan/react-multi-select
import Typography from '@material-ui/core/Typography';
import { observer, inject } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
  },
  input: {
    borderRadius: 0,
    width: 435,
    height: 40,
    width: '100%',
  },
  error: {
    color: 'red',
    margin: 0
  },
  formControl: {
    width: '100%',
    paddingTop: theme.spacing(1),
    marginTop: 20,
    positionTop: 20,
    fontSize: 18,
    fontWeight: 300,
    fontStretch: 'normal',
  },
  inputLabel: {
    padding: theme.spacing(0),
    // paddingBottom: theme.spacing(1),
    width: 435
  },
  fieldsetPadding: {
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    marginBottom: theme.spacing(1),
    width: 450,
  }
});

@inject('rootStore')
@observer
class UtaMultiSelect extends React.Component {
  constructor(props) {
    super(props);

  }

  componentDidMount = async () => { }

  render() {
    const {
      rootStore,
      classes,
      core,
      fieldName,
      form,
      label,
      options,
      selected,
      onSelectedChanged,
      onClose,
    } = this.props;
    const { apiStore, uiStore } = rootStore;

    return (
      <fieldset id={`fieldset-${fieldName}`} className={classes.fieldsetPadding}>
        <Typography
          className={classes.inputLabel}
          htmlFor={form.$(fieldName).id}
        >
          {label}
        </Typography>
        <FormControl id={`formControl-${core}`} className={classes.formControl, classes.input}>
          <MultiSelect
            id={`multiSelect-${core}`}
            overrideStrings={{ selectSomeItems: `Select ${label}` }}
            options={options}
            selected={selected}
            hasSelectAll={false}
            onSelectedChanged={onSelectedChanged}
            name={fieldName}
          />
          <div className={classes.error}>{form.$(fieldName).error}</div>
        </FormControl>
      </fieldset>
    )
  }
}


UtaMultiSelect.wrappedComponent.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(UtaMultiSelect);
